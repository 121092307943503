import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";

import Overlay from "../../styleguides/Styleguide-Overlay";
import SVGIcon from "../../styleguides/Styleguide-SVGIcon";
import ModalPortal from "../../utils/ModalPortal";

function LoadingScreen({ showLoadingScreen, title }) {
  return showLoadingScreen ? (
    <React.Fragment>
      {showLoadingScreen && (
        <ModalPortal>
          <Overlay
            id="loadingScreen"
            isOpen={showLoadingScreen}
            withoutPageContainer={true}
          >
            <div style={{ textAlign: "center", fontSize: "48px" }}>
              <SVGIcon icon="schedule" />
              {title && <h2 className="m-overlay-content__title">{title}</h2>}
            </div>
          </Overlay>
        </ModalPortal>
      )}
    </React.Fragment>
  ) : null;
}

LoadingScreen.propTypes = {
  showLoadingScreen: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default observer(LoadingScreen);
