import { getParent, types } from "mobx-state-tree";

import * as numberHelper from "../helpers/numberHelper";
import * as c from "../lib/Constants";

export const LoanStore = types
  .model("LoanStore", {
    loanAmountValue: c.loan_default_values.default_loan_value,
    loanTerm: c.loan_default_values.term,
    insuranceTypeId: types.maybeNull(types.number),
    insuranceValue: types.maybeNull(types.number),
    totalCosts: types.maybeNull(types.number),
  })
  .views((self) => ({
    /** @returns {number} */
    get loanTermIdx() {
      return c.terms.findIndex((t) => t === self.loanTerm);
    },

    /** @returns {number} Max monthly rate. */
    get monthlyRate() {
      return this.monthlyMaxRate(self.loanTerm);
    },

    /**
     * @param {number} term
     * @returns {number} Max monthly rate.
     */
    // TODO think about making term optional
    monthlyMaxRate(term) {
      return this.calculateFinancingRate(term, "MAX");
    },

    /**
     * @param {number} term
     * @returns {string} Max monthly rate rounded to 5 cent.
     */
    // TODO think about making term optional
    monthlyMaxRateRoundTo5Cent(term) {
      const rate = this.monthlyMaxRate(term);
      return numberHelper.roundTo5Cent(rate);
    },

    /** @returns {number} */
    totalMaxCreditCosts() {
      return this.calculateCreditCosts(self.loanTerm, "MAX");
    },

    calculateFinancingRate(term, type, amount = self.loanAmountValue) {
      // calculate the rate from the loan amount

      if (!term) {
        term = self.loanTerm;
      }

      if (!amount) {
        amount = self.loanAmountValue;
      }

      let percentage;

      if (c.loan_default_values) {
        if (type === "MIN" && c.loan_default_values.min_interest_rate) {
          percentage = c.loan_default_values.min_interest_rate;
        } else if (c.loan_default_values.max_interest_rate) {
          percentage = c.loan_default_values.max_interest_rate;
        }
      }

      if (!term || isNaN(term) || term <= 0 || !percentage || !amount) {
        return false;
      }

      let P = Math.pow(percentage / 100 + 1, 1 / 12);
      P = P - 1;
      P = P * 1200;
      const N = term;
      const J = P / (12 * 100);
      const T = 1 + J;
      const V = 1 / T;
      const X = (V * (1 - Math.pow(V, N))) / (1 - V);
      const F = 100 / X;

      return (F * amount) / 100;
    },

    calculateLoanAmount(term, rate, type) {
      // convert the rate to the loan amount
      // calculated loan amount must not be higher than max_loan_amount

      if (!term) {
        term = self.loanTerm;
      }

      let percentage;

      if (type === "MIN" && c.loan_default_values.min_interest_rate) {
        percentage = c.loan_default_values.min_interest_rate;
      } else if (c.loan_default_values.max_interest_rate) {
        percentage = c.loan_default_values.max_interest_rate;
      }

      const monthly = rate;
      const N = term;

      if (!term || isNaN(term) || term <= 0 || !percentage || !monthly) {
        return false;
      }

      const r1 = Math.pow(percentage / 100 + 1, 1 / 12) - 1;
      let C =
        monthly *
        (((1 / (1 + r1)) * (1 - Math.pow(1 / (1 + r1), N))) /
          (1 - 1 / (1 + r1)));

      if (C > c.loan_default_values.max_loan_value) {
        C = c.loan_default_values.max_loan_value;
      }

      return C;
    },
    calculateCreditCosts(term, type) {
      if (!term) {
        term = self.loanTerm;
      }

      const rate = self.calculateFinancingRate(term, type);

      if (rate)
        return (
          getParent(self).view.rouding5cents(rate) * term - self.loanAmountValue
        );

      return false;
    },
  }))
  .actions((self) => ({
    setLoanAmount(amount) {
      const value = numberHelper.toNumber(amount);
      if (
        !isNaN(value) &&
        value >= c.loan_default_values.min_loan_value &&
        value <= c.loan_default_values.max_loan_value
      ) {
        return (self.loanAmountValue = value);
      } else {
        throw new Error(`Invalid amount ${amount}`);
      }
    },

    setLoanTerm(term) {
      const value = numberHelper.toNumber(term);
      if (c.terms.includes(value)) {
        return (self.loanTerm = value);
      } else {
        throw new Error(`Invalid term ${term}`);
      }
    },

    setLoanTermByIdx(termIdx) {
      if (termIdx >= 0 && termIdx < c.terms.length) {
        return (self.loanTerm = c.terms[termIdx]);
      } else {
        throw new Error(`Invalid termIdx ${termIdx}`);
      }
    },

    /**
     * @param {number} typeId
     * @param {number} value
     * @returns Null
     */
    setInsuranceData(typeId, value) {
      self.insuranceTypeId = typeId;
      self.insuranceValue = value;
      return null;
    },
    resetInsurance() {
      self.setInsuranceData(null, null);
    },
  }));
