import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import Layout from "../../styleguides/Styleguide-Layout";

const DocumentUploadStandAloneConfirmation = ({ t }) => {
  const content = "step-13:components:DocumentUploadStandAlone.";
  const _content = "step-13:components:DocumentUpload:";

  const layout_props = {
    pageTitle: "step-13:pageTitle",
    headlineKey: content + "headline", // passing only the key, it's translated within Layout Trans component
    inverted_background: false,
  };

  return (
    <Layout data={layout_props}>
      <p className="a-text a-text--dark-blue-large">
        <span
          dangerouslySetInnerHTML={{
            __html: t(content + "successfully_submitted"),
          }}
        />
      </p>
      <p></p>

      <p className="a-text a-text--dark-blue-large">
        <span
          dangerouslySetInnerHTML={{ __html: t(_content + "bottom_section") }}
        />
      </p>
    </Layout>
  );
};

DocumentUploadStandAloneConfirmation.propTypes = {
  t: PropTypes.func,
};

export default withTranslation("step-13")(DocumentUploadStandAloneConfirmation);
