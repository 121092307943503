import axios from "axios";

import { trackApiError } from "../helpers/trackingHelper";
import { email_validation_url } from "../lib/Constants";

// TODO migrate to fetch api
export function email_validation_token(token) {
  return new Promise((resolve, reject) => {
    try {
      // TODO do we really need try catch here?
      axios
        .put(`${email_validation_url}${token}`)
        .then((data) => {
          // email can be confirmed multiple times with same result
          resolve(data.status === 202 || data.status === 204);
        })
        .catch((e) => {
          trackApiError(
            "email_verification",
            "put",
            e.response && e.response.status
          );
          reject(e);
        });
    } catch (e) {
      trackApiError(
        "email_verification",
        "put",
        e.response && e.response.status
      );
      reject(e);
    }
  });
}
