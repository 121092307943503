import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";

import EmailConfirmationPage from "./components/ploan/email-confirmation/EmailConfirmationPage";
import LoanAmount from "./components/ploan/step-1/index";
import UserBasicDemographics from "./components/ploan/step-3/UserBasicDemographics";
import CustomerAccommodation from "./components/ploan/step-4/CustomerAccommodation";
import CustomerIncome from "./components/ploan/step-5/CustomerIncome";
import CustomerExpenses from "./components/ploan/step-6/CustomerExpenses";
import BudgetCalculation from "./components/ploan/step-7/BudgetCalculation";
import CustomerInsurance from "./components/ploan/step-9/CustomerInsurance";
import CustomerContact from "./components/ploan/step-10/CustomerContact";
import PersonalData from "./components/ploan/step-11/PersonalData";
import ProfessionalData from "./components/ploan/step-12/ProfessionalData";
import DocumentUpload from "./components/ploan/step-13/DocumentUpload";
import DocumentUploadStandAlone from "./components/ploan/step-13/DocumentUploadStandAlone";
import DocumentUploadStandAloneConfirmation from "./components/ploan/step-13/DocumentUploadStandAloneConfirmation";
import ConfirmationPage from "./components/ploan/step-14/ConfirmationPage";
import { trackPageView } from "./helpers/trackingHelper";
import * as c from "./lib/Constants";

/**
 * @param {string} stepId
 * @returns {string}
 */
const getI18nKeyTitle = (stepId) => {
  // ☠☠☠ highly error prone, but we have to go with it for now
  // TODO set individual title for standalone document upload
  // and its confirmation page
  const ns = [
    c.ROUTENAME_STEP_13_2,
    c.ROUTENAME_DOC_U_S,
    c.ROUTENAME_DOC_U_S_CONFIRMATION,
  ].includes(stepId)
    ? "step-13"
    : stepId;

  return `${ns}:pageTitle`;
};

function RouteHandler({ loanStore, userStore, viewStore, x }) {
  const [trackedPath, setTrackedPath] = useState(null);
  const [loadedNs, setLoadedNs] = useState(0);

  const { origin, pathname } = window.location;
  const stepId = viewStore.getStepId() || c.ROUTENAME_STEP_1;
  const linkHref = `${origin}${viewStore.getLocalizedPath(stepId)}`;

  // needed as trigger "trackPageView"; this listener is called
  // everytime i18next fetched one or more namespace files
  useEffect(() => {
    i18next.on("loaded", () => {
      setLoadedNs((l) => l + 1);
    });
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  // compares the pathname the last "trackPageView" event was fired
  // for with the current path; if they differ and the namespace with
  // the translation for current steps/page title was already loaded
  // by i18next page view is tracked; run everytime pathname changed
  // or a namespace was loaded by i18next
  useEffect(() => {
    const i18nKeyTitle = getI18nKeyTitle(stepId);
    if (trackedPath !== pathname && i18next.exists(i18nKeyTitle)) {
      const linkHrefEn = `${origin}${viewStore.getLocalizedPath(stepId, "en")}`;
      const title = i18next.t(i18nKeyTitle);
      trackPageView(
        linkHref,
        linkHrefEn,
        pathname,
        title,
        i18next.resolvedLanguage,
        stepId,
        loanStore.loanAmountValue,
        loanStore.loanTerm,
        userStore.gender,
        userStore.nationality,
        userStore.permitId,
        userStore.address_zip,
        userStore.incomeSourceId,
        userStore.livingStatus,
        userStore.income,
        loanStore.insuranceTypeId,
        userStore.expenses_pays_otherDebt
      );
      setTrackedPath(pathname);
    }
  }, [loadedNs, pathname]);
  /* eslint-enable */

  return (
    <Fragment>
      <Helmet link={[{ rel: "canonical", href: linkHref }]} />
      {(() => {
        switch (stepId) {
          case c.ROUTENAME_STEP_1:
            return <LoanAmount />;
          case c.ROUTENAME_STEP_3:
            return <UserBasicDemographics />;
          case c.ROUTENAME_STEP_4:
            return <CustomerAccommodation />;
          case c.ROUTENAME_STEP_5:
            return <CustomerIncome />;
          case c.ROUTENAME_STEP_6:
            return <CustomerExpenses />;
          case c.ROUTENAME_STEP_7:
            return <BudgetCalculation />;
          case c.ROUTENAME_STEP_9:
            return <CustomerInsurance />;
          case c.ROUTENAME_STEP_10:
            return <CustomerContact />;
          case c.ROUTENAME_STEP_11:
            return <PersonalData />;
          case c.ROUTENAME_STEP_12:
            return <ProfessionalData />;
          case c.ROUTENAME_STEP_13:
            return <DocumentUpload part={1} key={1} />;
          case c.ROUTENAME_STEP_13_2:
            return <DocumentUpload part={2} key={2} />;
          case c.ROUTENAME_STEP_14:
            return <ConfirmationPage />;
          case c.ROUTENAME_EMAIL_CONFIRMATION:
            return <EmailConfirmationPage />;
          case c.ROUTENAME_DOC_U_S:
            return <DocumentUploadStandAlone />;
          case c.ROUTENAME_DOC_U_S_CONFIRMATION:
            return <DocumentUploadStandAloneConfirmation />;
          default:
            return <LoanAmount />;
        }
      })()}
    </Fragment>
  );
}

RouteHandler.propTypes = {
  i18n: PropTypes.object,
  route: PropTypes.object,
  t: PropTypes.func,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    loanStore: stores.loan,
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(RouteHandler))
);
