import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { trackError } from "../../../helpers/trackingHelper";
import RadioButton from "./RadioButton";

const RadioButtonGroup = ({
  doNotTrack,
  fields,
  invalid,
  onChange,
  selectedValue,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (!doNotTrack && invalid) {
      trackError(fields.radioGroupName, invalid);
    }
  }, [doNotTrack, invalid, fields.radioGroupName]);

  const handleChange = (event) => {
    setSelectedItem(event.currentTarget.id);
    if (onChange) onChange(event);
  };

  return (
    <div className="m-radio-button-list">
      {fields.list.length > 0 && (
        <div className="m-radio-button-list__items">
          {fields.list.map((item, i) => {
            const radioId = `${fields.radioGroupName}_${i}`;
            const selected =
              selectedItem === radioId ||
              (selectedValue != null && // not null nor undefined
                item.value.toString() === selectedValue.toString());

            const radioBtnProps = {
              handleChange,
              invalid,
              radioId,
              radioName: fields.radioGroupName,
              selected,
              value: item.value,
            };

            return (
              <RadioButton key={radioId} {...radioBtnProps}>
                <label htmlFor={radioId}>{item.label}</label>
              </RadioButton>
            );
          })}
        </div>
      )}
    </div>
  );
};

RadioButtonGroup.propTypes = {
  doNotTrack: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
  selectedValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default inject(({ stores }) => ({
  userStore: stores.user,
  viewStore: stores.view,
}))(observer(RadioButtonGroup));
