import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import FormControl from "../../../styleguides/Styleguide-FormControl";

class QuestionInsuranceCosts extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);

    this.validate = false;
    if (props.userStore.expenses_costs_provided_health_insurance == null)
      props.userStore.setExpenses_costs_provided_health_insurance(
        props.userStore.budget_calculation_values
          .applicant_expenses_calc_insurance
      );
  }

  handleChange(value) {
    const { userStore, viewStore } = this.props;
    value = viewStore.validate_number(value);
    if (value !== "err")
      userStore.setExpenses_costs_provided_health_insurance(value);

    this.validate = true;
  }

  handleOnBlur() {}

  render() {
    const { t, viewStore, userStore } = this.props;

    // Validate this optional field when global form should validate
    let inputState, inputHint;

    if (viewStore.shouldCustomerExpensesFormValidate || this.validate) {
      let value = userStore.expenses_costs_provided_health_insurance;

      if (value === null) {
        inputState = "error";
        inputHint = t(
          "step-7:components:QuestionInsuranceCosts:plausibility_error"
        );
      } else if (value < 100) {
        inputState = "warn";
        inputHint = t(
          "step-7:components:QuestionInsuranceCosts:plausibility_error"
        );
      }

      if (
        inputState !== "error" &&
        viewStore.shouldCustomerExpensesFormValidate
      ) {
        viewStore.validateCustomerExpensesForm();
      }
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": inputState === "error",
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <span className={titleClasses}>
              {t("step-7:components:QuestionInsuranceCosts:question")}
            </span>
          </div>
          <div className="m-info-block__pattern">
            <FormControl
              type="tel"
              label={t("global:labels.CHF_month")}
              id={"healthinsurance"}
              onChange={this.handleChange}
              onBlur={this.handleOnBlur}
              inputState={inputState}
              hint={inputHint}
              value={viewStore.prettifyNumber(
                userStore.expenses_costs_provided_health_insurance
              )}
              isLast={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

QuestionInsuranceCosts.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionInsuranceCosts))
);
