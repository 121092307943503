import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import SVGIcon from "../../../styleguides/Styleguide-SVGIcon/index";

const content = "step-13:components.DocumentUpload";

const UploadFields = ({ t }) => (
  <React.Fragment>
    {/* MOBILE */}
    <div className="m-upload-content">
      <label className="m-upload-content__label" htmlFor="FOO">
        <SVGIcon icon="camera" />
        <span className="m-upload-content__label--center">
          {t(content + ".hint_select_mobile_document")}
        </span>
        <span className="m-upload-content__label--center">
          {t(content + ".file_limit")}
        </span>
        <span className="m-upload-content__label--center">
          {t(content + ".file_types")}
        </span>
      </label>
    </div>

    {/* DESKTOP */}
    <div tabIndex="0" className="m-upload-content m-upload-content--desktop">
      <label className="m-upload-content__label" htmlFor="BAR">
        <SVGIcon icon="upload" />
        <span className="m-upload-content__label--center">
          {t(content + ".hint_select_mobile_document")}
        </span>
        <span className="m-upload-content__label--center">
          {t(content + ".file_limit")}
        </span>
        <span className="m-upload-content__label--center">
          {t(content + ".file_types")}
        </span>
      </label>
    </div>
  </React.Fragment>
);

UploadFields.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation("step-13")(UploadFields);
