import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { withTranslation, Trans } from "react-i18next";

import { trackConfirmation } from "../../../helpers/trackingHelper";
import Layout from "../../styleguides/Styleguide-Layout";

const ConfirmationPage = ({ t }) => {
  useEffect(() => {
    trackConfirmation(true);
  }, []);

  const layout_props = {
    pageTitle: "step-14:pageTitle", // passing only the key, it's translated within Layout component
    headlineKey: "step-14:headline", // passing only the key, it's translated within Layout Trans component
    inverted_background: true,
    progressBar: true,
  };

  return (
    <Layout data={layout_props}>
      <h2 className="a-text">
        {t("step-14:components.ConfirmationPage.proceed_question")}
      </h2>
      <p className="a-text a-text--dark-blue-large">
        {t("step-14:components.ConfirmationPage.proceed_answer")}
      </p>
      <h2 className="a-text">
        {t("step-14:components.ConfirmationPage.further_information_question")}
      </h2>
      <p className="a-text a-text--dark-blue-large">
        <Trans
          i18nKey={
            "step-14:components.ConfirmationPage.further_information_answer"
          }
        >
          # <a href="tel:+4144 439 54 06">#</a> #
        </Trans>
      </p>
      <div className="l-buttons">
        <a
          className="a-nexus a-nexus--primary"
          href={t("global:cembra_home_url")}
        >
          <span className="a-nexus__text">
            {t("step-14:components.ConfirmationPage.home_link_label")}
          </span>
        </a>
      </div>
    </Layout>
  );
};

ConfirmationPage.propTypes = {
  t: PropTypes.func,
};

export default withTranslation("step-14")(ConfirmationPage);
