import i18next from "i18next";
import { getParent, types } from "mobx-state-tree";
import moment from "moment";
import { isMobileOnly, isTablet } from "react-device-detect";

import { getKremoCalculation } from "../api_clients/customer_credit_check_service";
import { sendIndusData } from "../api_clients/indus_data_service";
import { isEmail } from "../helpers/validationHelper";
import { loan_default_values, AppVersion } from "../lib/Constants";
import * as c from "../lib/Constants";

const BirthdateValues = types.model({
  day: types.maybeNull(types.number),
  month: types.maybeNull(types.number),
  year: types.maybeNull(types.number),
});

const BudgetCalculationValues = types
  .model({
    applicant_calc_disposal_income: types.maybeNull(types.number),
    applicant_expenses_calc_basic: types.maybeNull(types.number),
    applicant_expenses_calc_children_cost: types.maybeNull(types.number),
    applicant_expenses_calc_insurance: types.maybeNull(types.number),
    applicant_expenses_calc_tax: types.maybeNull(types.number),
  })
  .actions((self) => ({
    setBudgetCalculationValues(data) {
      self.applicant_calc_disposal_income = Number(
        data.applicant_calc_disposal_income.toFixed(0)
      );
      self.applicant_expenses_calc_basic = Number(
        data.applicant_expenses_calc_basic.toFixed(0)
      );
      self.applicant_expenses_calc_children_cost = Number(
        data.applicant_expenses_calc_children_cost.toFixed(0)
      );
      self.applicant_expenses_calc_insurance = Number(
        data.applicant_expenses_calc_insurance.toFixed(0)
      );
      self.applicant_expenses_calc_tax = Number(
        data.applicant_expenses_calc_tax.toFixed(0)
      );
    },
    reset_BudgetCalculationValues() {
      self.applicant_calc_disposal_income = 0;
      self.applicant_expenses_calc_basic = 0;
      self.applicant_expenses_calc_children_cost = 0;
      self.applicant_expenses_calc_insurance = 0;
      self.applicant_expenses_calc_tax = 0;
    },
  }));

export const UserStore = types
  .model({
    gender: types.maybeNull(types.string),
    genderPartner: types.maybeNull(types.string),
    maritalStatus: types.maybeNull(types.number),
    birthdayValues: types.optional(BirthdateValues, {}),
    birthdayValuesPartner: types.optional(BirthdateValues, {}),
    hasChildrenUnder25: types.maybeNull(types.boolean),
    childrensBirthYears: types.optional(types.array(types.number), []),
    showPartnerInfo: types.maybeNull(types.boolean),
    nationality: types.maybeNull(types.string),
    nationalityPartner: types.maybeNull(types.string),
    permitId: types.maybeNull(types.string),
    permitIdPartner: types.maybeNull(types.string),
    residenceSince: types.maybeNull(types.string),
    permitSince: types.maybeNull(types.string),
    permitSincePartner: types.maybeNull(types.string),
    livingStatus: types.maybeNull(types.number),
    address_zip: types.maybeNull(types.string),
    address_zip_former: types.maybeNull(types.string),
    address_zip_additional: types.maybeNull(types.string),
    address_city: types.maybeNull(types.string),
    address_city_former: types.maybeNull(types.string),
    address_zip_employer: types.maybeNull(types.string),
    address_zip_employer_partner: types.maybeNull(types.string),
    address_city_employer: types.maybeNull(types.string),
    address_city_employer_partner: types.maybeNull(types.string),
    address_canton: types.maybeNull(types.string),
    address_canton_employer: types.maybeNull(types.string),
    address_canton_employer_partner: types.maybeNull(types.string),
    address_street: types.maybeNull(types.string),
    address_street_former: types.maybeNull(types.string),
    address_house_number: types.maybeNull(types.string),
    address_house_number_former: types.maybeNull(types.string),
    budget_calculation_values: types.optional(BudgetCalculationValues, {}),

    incomeSourceId: types.maybeNull(types.number),
    incomeSourceIdPartner: types.maybeNull(types.number),
    income: types.maybeNull(types.number),
    incomePartner: types.maybeNull(types.number),
    incomeFrequency: types.maybeNull(types.number),
    incomeFrequencyPartner: types.maybeNull(types.number),

    hasExtraIncome: types.maybeNull(types.boolean),
    hasExtraIncomePartner: types.maybeNull(types.boolean),

    extraIncome: types.array(types.maybeNull(types.number)),
    extraIncomePartner: types.array(types.maybeNull(types.number)),
    extraIncomeFrequency: types.array(types.maybeNull(types.number)),
    extraIncomeFrequencyPartner: types.array(types.maybeNull(types.number)),
    extraIncomeSource: types.array(types.maybeNull(types.number)),
    extraIncomeSourcePartner: types.array(types.maybeNull(types.number)),

    expenses_costs_housing: types.maybeNull(types.number),
    expenses_costs_alimony: types.maybeNull(types.number),
    expenses_costs_alimonyPartner: types.maybeNull(types.number),
    expenses_costs_work: types.maybeNull(types.number),
    expenses_costs_childcare: types.maybeNull(types.number),
    expenses_costs_otherDebts: types.maybeNull(types.number),
    expenses_wantCompensate_otherDebts: types.maybeNull(types.boolean),
    expenses_costs_additional: types.maybeNull(types.number),
    expenses_costs_additionalPartner: types.maybeNull(types.number),
    expenses_pays_food: types.maybeNull(types.boolean),
    expenses_costs_food: types.maybeNull(types.number),
    expenses_pays_alimony: types.maybeNull(types.boolean),
    expenses_pays_alimonyPartner: types.maybeNull(types.boolean),
    expenses_pays_childcare: types.maybeNull(types.boolean),
    expenses_pays_otherDebt: types.maybeNull(types.boolean),
    expenses_costs_provided_health_insurance: types.maybeNull(types.number),

    firstName: types.maybeNull(types.string),
    firstNamePartner: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    lastNamePartner: types.maybeNull(types.string),
    currentAddressDuration: types.maybeNull(types.string),
    employerName: types.maybeNull(types.string),
    employerNamePartner: types.maybeNull(types.string),
    startDateCurrentEmployer: types.maybeNull(types.string),
    startDateCurrentEmployerPartner: types.maybeNull(types.string),
    customerPhoneAreaCode: types.maybeNull(types.string),
    customerPhone: types.maybeNull(types.string),
    customerEmail: types.maybeNull(types.string),
    customerAcceptedTerms: false,
    customerAcceptedPartnerDisclaimer: false,
    customerContactLanguage: types.maybeNull(types.string),
    customerAcceptedZekTermsAndConditions: false,
    applicantIbanNumber: types.maybeNull(types.string),
    step9skipped: types.maybeNull(types.boolean),
    app_ab_testing_version: types.maybeNull(types.string),
    applicant_budget_confirmation: false,
  })
  .views((self) => ({
    /**
     * @param {number} idx 0-based index.
     * @param {boolean} forPartner
     */
    getExtraIncomeAmount(idx, forPartner = false) {
      const prop = `extraIncome${forPartner ? "Partner" : ""}`;
      return idx < self[prop].length ? self[prop][idx] : null;
    },

    /**
     * @param {number} idx 0-based index.
     * @param {boolean} forPartner
     */
    getExtraIncomeFrequency(idx, forPartner = false) {
      const prop = `extraIncomeFrequency${forPartner ? "Partner" : ""}`;
      return idx < self[prop].length ? self[prop][idx] : null;
    },

    /**
     * @param {number} idx 0-based index.
     * @param {boolean} forPartner
     */
    getExtraIncomeSource(idx, forPartner = false) {
      const prop = `extraIncomeSource${forPartner ? "Partner" : ""}`;
      return idx < self[prop].length ? self[prop][idx] : null;
    },

    /**
     * @param {boolean} forPartner
     * @returns {number}
     */
    getNumberOfExtraIncomes(forPartner = false) {
      const prop = `extraIncomeSource${forPartner ? "Partner" : ""}`;
      return self[prop].length;
    },

    getBirthdate(isPartner = false) {
      const type = isPartner ? "birthdayValuesPartner" : "birthdayValues";
      if (self[type] && self[type].year && self[type].month && self[type].day) {
        const birthDate = moment(
          `${self[type].year}-${self[type].month}-${self[type].day}`,
          "YYYY-MM-DD"
        );
        if (birthDate.isValid()) {
          return birthDate.format("YYYY-MM-DD");
        }
        return null;
      }
      return null;
    },

    getAge() {
      if (self.getBirthdate())
        return moment().diff(self.getBirthdate(), "years");

      return null;
    },
    hasPartner() {
      return self.maritalStatus === 1;
    },
    getWorkDurationAtCurrentEmployer(isPartner = false) {
      const startDate = isPartner
        ? self.startDateCurrentEmployerPartner
        : self.startDateCurrentEmployer;

      if (!startDate) return null;

      const workingDuration = moment.duration(moment() - moment(startDate));

      if (workingDuration.isValid()) {
        return workingDuration.asMonths();
      }

      return null;
    },
    isEditingPartnerInfo() {
      return self.showPartnerInfo ? self.showPartnerInfo : false;
    },
    getYearMonthOfDate(date) {
      if (!date) return null;

      return moment(date).format("YYYY-MM");
    },
    getYearMonthDayOfDate(date) {
      if (!date) return null;

      return moment(date).format("YYYY-MM-DD");
    },
    collectKremoApiData(isKremo) {
      const loanStore = getParent(self).loan;

      const partnerData = !self.isEditingPartnerInfo()
        ? {}
        : {
            // general
            partner_title_id: self.genderPartner,
            partner_date_birth_yyyy_mm_dd: self.getBirthdate(true),
            partner_nationality_id: self.nationalityPartner,
            partner_permit_type: self.permitIdPartner,

            // income
            partner_income: self.collectIncome(isKremo, true),
            partner_income_extra: self.collectExtraIncome(true),

            // expenses
            partner_expenses_alimony: self.expenses_costs_alimonyPartner,
            partner_expenses_other: self.expenses_costs_additionalPartner,
          };

      return {
        app_product: "loan",

        app_calculation_type_id: "c", // it's always 'c', stands for 'credit'
        app_with_partner: self.showPartnerInfo || false,

        app_loan_amount: loanStore.loanAmountValue,
        app_loan_term: loanStore.loanTerm,
        app_loan_interest_rate: loan_default_values.max_interest_rate,

        // general applicant information
        applicant_title_id: self.gender,
        applicant_civil_status_id: self.maritalStatus,
        applicant_accommodation_type_id: self.livingStatus,
        applicant_date_birth_yyyy_mm_dd: self.getBirthdate(),

        applicant_children_yyyy: self.childrensBirthYears,

        applicant_address_zip: self.address_zip,
        applicant_address_canton_id: self.address_canton
          ? self.address_canton
          : "NN",

        // employer
        applicant_permit_type: self.permitId,
        applicant_nationality_id: self.nationality,

        applicant_income: self.collectIncome(isKremo, false),
        applicant_income_extra: self.collectExtraIncome(false),

        // main applicant expenses
        applicant_expenses_alimony: self.expenses_costs_alimony,
        applicant_expenses_accommodation: self.expenses_costs_housing,
        applicant_expenses_work: self.expenses_costs_work,
        applicant_expenses_other: self.expenses_costs_additional,
        applicant_expenses_children: self.expenses_costs_childcare,
        applicant_expenses_food: self.expenses_costs_food,
        ...partnerData,
      };
    },
    collectIndusData() {
      const loanStore = getParent(self).loan;

      const partnerData = !self.isEditingPartnerInfo()
        ? {}
        : {
            partner_name_first: self.firstNamePartner,
            partner_name_last: self.lastNamePartner,
            partner_employer_since: self.getYearMonthOfDate(
              self.startDateCurrentEmployerPartner
            ),
            partner_employer_city: self.address_city_employer_partner,
            partner_employer_name: self.employerNamePartner,
            partner_employer_zip: self.address_zip_employer_partner,
            partner_employer_canton_id: self.address_canton_employer_partner,
            partner_permit_since_yyyy_mm_dd: self.getYearMonthDayOfDate(
              self.permitSincePartner
            ),
          };

      const baseData = {
        // indus insurance info
        applicant_insurance_type: loanStore.insuranceTypeId,
        app_device_origin: isMobileOnly ? "M" : isTablet ? "T" : "U",
        app_fe_origin:
          process.env.NODE_ENV === "development"
            ? "R_I1_vDEV"
            : `R_I1_v${AppVersion}`,

        applicant_language_contract_id:
          self.customerContactLanguage || i18next.resolvedLanguage,
        applicant_language_application_id: i18next.resolvedLanguage,
        applicant_name_first: self.firstName,
        applicant_name_last: self.lastName,
        applicant_iban: self.applicantIbanNumber,

        applicant_residing_since_yyyy_mm: self.getYearMonthOfDate(
          self.residenceSince
        ),
        applicant_permit_since_yyyy_mm_dd: self.getYearMonthDayOfDate(
          self.permitSince
        ),

        applicant_email: self.customerEmail,
        applicant_accept_terms: self.customerAcceptedTerms,
        applicant_phone_mobile: `${
          self.customerPhoneAreaCode
        }${self.customerPhone.replace(/ /g, "")}`,

        applicant_employer_name: self.employerName,
        applicant_employer_zip: self.address_zip_employer,
        applicant_employer_canton_id: self.address_canton_employer
          ? self.address_canton_employer
          : "NN",
        applicant_employer_city: self.address_city_employer,
        applicant_employer_since: self.getYearMonthOfDate(
          self.startDateCurrentEmployer
        ),

        applicant_budget_confirmation: self.applicant_budget_confirmation,
        // terms
        applicant_zek_accept: self.customerAcceptedZekTermsAndConditions,

        // address
        applicant_address_city: self.address_city,
        applicant_address_street: self.address_street,
        applicant_address_house_nr: self.address_house_number,
        applicant_address_zip_prev: self.address_zip_former,
        applicant_address_city_prev: self.address_city_former,
        applicant_address_street_prev: self.address_street_former,
        applicant_address_house_nr_prev: self.address_house_number_former,

        applicant_debt_others_month: self.expenses_costs_otherDebts,
        applicant_loan_compensation: self.expenses_wantCompensate_otherDebts,
        applicant_provided_health_insurance:
          self.expenses_costs_provided_health_insurance,

        // indus expenses info
        ...self.budget_calculation_values,
        app_ab_testing_version: self.app_ab_testing_version,
        ...partnerData,
      };

      return Object.assign(baseData, self.collectKremoApiData(false));
    },

    /**
     * @param {boolean} isKremo
     * @param {boolean} [forPartner]
     * @returns {Object}
     */
    collectIncome(isKremo, forPartner = false) {
      const suffix = forPartner ? "Partner" : "";

      let incomeSource = self[`incomeSourceId${suffix}`];
      if (forPartner && incomeSource === 100 && isKremo) {
        incomeSource = 0;
      }

      return {
        income_amount: self[`income${suffix}`],
        income_factor: self[`incomeFrequency${suffix}`],
        income_source: incomeSource,
      };
    },

    /**
     * @param {boolean} [forPartner]
     * @returns {void}
     */
    collectExtraIncome(forPartner = false) {
      const suffix = forPartner ? "Partner" : "";
      return self[`extraIncomeSource${suffix}`].map((value, idx) => ({
        income_amount: self.getExtraIncomeAmount(idx, forPartner),
        income_factor: self.getExtraIncomeFrequency(idx, forPartner),
        income_source: value,
      }));
    },

    getConsumerWorthinessState() {
      return new Promise((resolve, reject) => {
        getKremoCalculation(self.collectKremoApiData(true))
          .then((response) => {
            self.budget_calculation_values.setBudgetCalculationValues(response);
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    sendApplication() {
      return new Promise((resolve, reject) => {
        sendIndusData(self.collectIndusData())
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getFullNameLength(isPartner = false) {
      const typeFirstName = isPartner ? "firstNamePartner" : "firstName";
      const typeLastName = isPartner ? "lastNamePartner" : "lastName";

      if (self[typeFirstName] && self[typeLastName]) {
        // no whitespace
        const lg = `${self[typeFirstName]}${self[typeLastName]}`.length;
        return lg;
      }
      return 0;
    },
    isDateInFuture(date) {
      let today = new Date();
      today = moment(today, "YYYY-MM-DD");
      const selectedDate = moment(date, "YYYY-MM-DD");

      if (selectedDate > today) {
        return true;
      } else {
        return false;
      }
    },
  }))
  .actions((self) => ({
    setBirthdateValue(values, isPartner = false) {
      const type_user = isPartner ? "birthdayValuesPartner" : "birthdayValues";
      // TODO default value different to reset value?
      if (!values) {
        return (self[type_user] = null);
      } else {
        return (self[type_user] = values);
      }
    },
    setChildrensBirthYears(birthYears) {
      self.expenses_costs_childcare = null;

      if (birthYears && birthYears.length) {
        const items = birthYears.filter(
          (item) => typeof item === "number" && String(item).length === 4
        );
        if (items && items.length) {
          self.childrensBirthYears = items;
          return true;
        }
        return false;
      }
      return false;
    },
    setHasChildrenUnder25(bool) {
      if (!bool) {
        self.expenses_pays_childcare = null;
        self.expenses_costs_childcare = null;
        self.childrensBirthYears = [];
      }
      return (self.hasChildrenUnder25 = bool);
    },
    setExpenses_costs_housing(value) {
      return (self.expenses_costs_housing = value);
    },
    setExpenses_costs_alimony(value, isPartner) {
      const type = isPartner
        ? "expenses_costs_alimonyPartner"
        : "expenses_costs_alimony";
      return (self[type] = value);
    },
    setExpenses_costs_work(value) {
      self.expenses_costs_work = value;
      return null;
    },
    setExpenses_costs_childcare(value) {
      return (self.expenses_costs_childcare = value);
    },
    setExpenses_costs_otherDebts(value) {
      return (self.expenses_costs_otherDebts = value);
    },
    setExpenses_wantCompensate_otherDebts(value) {
      return (self.expenses_wantCompensate_otherDebts = value);
    },
    setExpenses_costs_additional(value, isPartner) {
      const type = isPartner
        ? "expenses_costs_additionalPartner"
        : "expenses_costs_additional";
      return (self[type] = value);
    },
    setExpenses_pays_alimony(bool, isPartner) {
      const type = isPartner
        ? "expenses_pays_alimonyPartner"
        : "expenses_pays_alimony";
      return (self[type] = bool);
    },
    setExpenses_pays_childcare(bool) {
      return (self.expenses_pays_childcare = bool);
    },
    setExpenses_pays_food(bool) {
      return (self.expenses_pays_food = bool);
    },
    setExpenses_pays_otherDebt(bool) {
      return (self.expenses_pays_otherDebt = bool);
    },
    setExpenses_costs_food(value) {
      self.expenses_costs_food = value;
    },
    setExpenses_costs_provided_health_insurance(value) {
      return (self.expenses_costs_provided_health_insurance = value);
    },
    setGender(value, isPartner) {
      const type = isPartner ? "genderPartner" : "gender";
      return (self[type] = value);
    },
    setMaritalStatus(value) {
      self.maritalStatus = value;
      self.setShowPartnerInfo(null);

      if (!self.hasPartner()) {
        self.resetPartner();
      }

      return null;
    },
    resetPartner() {
      self.genderPartner = null;
      self.permitIdPartner = null;
      self.birthdayValuesPartner = {};
      self.firstNamePartner = null;
      self.lastNamePartner = null;
      self.nationalityPartner = null;
      self.incomeSourceIdPartner = null;
      self.incomePartner = null;
      self.incomeFrequencyPartner = null;
      self.removeAllExtraIncomes(true);
      self.expenses_costs_alimonyPartner = null;
      self.expenses_costs_additionalPartner = null;
      self.startDateCurrentEmployerPartner = null;
      self.address_city_employer_partner = null;
      self.employerNamePartner = null;
      self.address_zip_employer_partner = null;
      self.address_canton_employer_partner = null;
      self.permitSincePartner = null;
    },
    setShowPartnerInfo(status) {
      return (self.showPartnerInfo = status);
    },
    setNationality(nationality, isPartner = false) {
      const type = isPartner ? "nationalityPartner" : "nationality";
      self[type] = nationality;
    },
    setPermitId(id, isPartner = false) {
      const type = isPartner ? "permitIdPartner" : "permitId";
      return (self[type] = id);
    },
    isDateBeforeBirthday(date) {
      if (self["birthdayValues"].year && self["birthdayValues"].month) {
        const selectedDate = moment(date, "YYYY-MM-DD");
        const birthMonthYear = moment(
          `${self["birthdayValues"].year}-${self["birthdayValues"].month}-01`,
          "YYYY-MM-DD"
        );

        if (selectedDate.isValid() && selectedDate >= birthMonthYear) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    setPermitSince(date, isPartner = false) {
      const type = isPartner ? "permitSincePartner" : "permitSince";
      const userBirthdayType = isPartner
        ? "birthdayValuesPartner"
        : "birthdayValues";

      if (
        !self[userBirthdayType] ||
        !self[userBirthdayType].year ||
        !self[userBirthdayType].month
      ) {
        return false;
      }

      const selectedDate = moment(date, "YYYY-MM-DD");
      let today = new Date();
      today = moment(today, "YYYY-MM-DD");
      const birthMonthYear = moment(
        `${self[userBirthdayType].year}-${self[userBirthdayType].month}-01`,
        "YYYY-MM-DD"
      );

      if (
        selectedDate.isValid() &&
        selectedDate >= birthMonthYear &&
        selectedDate <= today
      ) {
        return (self[type] = date);
      }

      self[type] = null;
      return false;
    },
    clearPermitSince(isPartner = false) {
      const type = isPartner ? "permitSincePartner" : "permitSince";
      self[type] = null;
    },
    setLivingStatus(status) {
      return (self.livingStatus = status);
    },
    setAddress({ zip, city, zipAdditional, canton }, type) {
      if (type === "address") {
        self.address_zip = zip;
        self.address_zip_additional = zipAdditional;
        self.address_city = city;
        self.address_canton = canton;
        if (city && !canton) window.alert("canton null !!!! Report this");
      } else if (type === "address_former") {
        self.address_zip_former = zip;
        self.address_city_former = city;
      } else if (type === "employer") {
        self.address_zip_employer = zip;
        self.address_city_employer = city;
        self.address_canton_employer = canton;
      } else if (type === "employer_partner") {
        self.address_zip_employer_partner = zip;
        self.address_city_employer_partner = city;
        self.address_canton_employer_partner = canton;
      }

      return true;
    },
    setBudgetCalculationValues(budgetCalculationValues) {
      self.budget_calculation_values = budgetCalculationValues;
    },
    setIncomeSourceId(status, isPartner = false) {
      const type = isPartner ? "incomeSourceIdPartner" : "incomeSourceId";
      self[type] = status;

      if (status === 0 || status === 100) {
        self.setHasExtraIncome(null, isPartner);
      }

      if (isPartner && !self.doesPartnerWorks()) {
        self.address_zip_employer_partner = null;
        self.address_city_employer_partner = null;
        self.address_canton_employer_partner = null;
        self.incomePartner = null;
        self.incomeFrequencyPartner = null;
        self.employerNamePartner = null;
        self.startDateCurrentEmployerPartner = null;
        self.removeAllExtraIncomes(isPartner);
      }
      const loanStore = getParent(self).loan;
      loanStore.resetInsurance();

      return null;
    },

    /**
     * @param {number} value
     * @param {boolean} [forPartner]
     * @returns {void}
     */
    setIncome(value, forPartner = false) {
      const type = `income${forPartner ? "Partner" : ""}`;
      self[type] = value;
    },

    /**
     * @param {number} value
     * @param {boolean} [forPartner]
     * @returns {void}
     */
    setIncomeFrequency(value, forPartner = false) {
      const type = `incomeFrequency${forPartner ? "Partner" : ""}`;
      self[type] = value;
    },

    /**
     * @param {boolean} value
     * @param {boolean} [forPartner]
     * @returns {void}
     */
    setHasExtraIncome(value, forPartner = false) {
      const type = `hasExtraIncome${forPartner ? "Partner" : ""}`;
      self[type] = value;

      if (!value) self.removeAllExtraIncomes(forPartner);
    },

    /**
     * @param {number} idx 0-based index.
     * @param {number} value
     * @param {boolean} [forPartner]
     * @returns {void}
     */
    setExtraIncomeAmount(idx, value, forPartner = false) {
      const type = `extraIncome${forPartner ? "Partner" : ""}`;
      while (self[type].length < idx + 1) {
        self[type][self[type].length] = null;
      }
      self[type][idx] = value;
    },

    /**
     * @param {number} idx 0-based index.
     * @param {number} value
     * @param {boolean} [forPartner]
     * @returns {void}
     */
    setExtraIncomeFrequency(idx, value, forPartner = false) {
      const type = `extraIncomeFrequency${forPartner ? "Partner" : ""}`;
      while (self[type].length < idx + 1) {
        self[type][self[type].length] = null;
      }
      self[type][idx] = value;
    },

    /**
     * @param {number} idx 0-based index.
     * @param {number} value
     * @param {boolean} [forPartner]
     * @returns {void}
     */
    setExtraIncomeSource(idx, value, forPartner = false) {
      const type = `extraIncomeSource${forPartner ? "Partner" : ""}`;
      while (self[type].length < idx + 1) {
        self[type][self[type].length] = null;
      }
      self[type][idx] = value;
    },

    /**
     * Removes the extra incomes with index >= idx.
     *
     * @param {number} idx 0-based index.
     * @param {boolean} [forPartner]
     * @returns {void}
     */
    removeExtraIncome(idx, forPartner = false) {
      ["extraIncome", "extraIncomeFrequency", "extraIncomeSource"]
        .map((t) => {
          return forPartner ? `${t}Partner` : t;
        })
        .forEach((t) => {
          self[t].splice(idx);
        });
    },

    /**
     * @param {boolean} [forPartner]
     * @returns {void}
     */
    removeAllExtraIncomes(forPartner = false) {
      self.removeExtraIncome(0, forPartner);
    },

    setCustomerAcceptedPartnerDisclaimer(value) {
      return (self.customerAcceptedPartnerDisclaimer = value);
    },
    setFirstName(value, isPartner = false) {
      const type = isPartner ? "firstNamePartner" : "firstName";
      self[type] = value;
      self.getFullNameLength(isPartner);
      return true;
    },
    setLastName(value, isPartner = false) {
      const type = isPartner ? "lastNamePartner" : "lastName";
      self[type] = value;
      self.getFullNameLength(isPartner);
      return true;
    },
    setApplicantsAddressStreet(street, isFormerAddress = false) {
      const type = isFormerAddress ? "address_street_former" : "address_street";
      return (self[type] = street);
    },
    setApplicantsAddressHouseNumber(value, isFormerAddress = false) {
      const type = isFormerAddress
        ? "address_house_number_former"
        : "address_house_number";
      return (self[type] = value);
    },
    setResidenceSince(date) {
      return (self.residenceSince = date);
    },
    setEmployerName(value, isPartner = false) {
      let storeProperty = "employerName";

      if (isPartner) {
        storeProperty = "employerNamePartner";
      }

      return (self[storeProperty] = value);
    },
    setStartDateEmployer(date, isPartner = false) {
      let storeProperty = "startDateCurrentEmployer";

      if (isPartner) {
        storeProperty = "startDateCurrentEmployerPartner";
      }

      const selectedDate = moment(date, "YYYY-MM-DD");

      if (selectedDate.isValid()) {
        self[storeProperty] = date;
        return true;
      } else {
        return false;
      }
    },
    setcustomerPhoneAreaCode(value) {
      return (self.customerPhoneAreaCode = value);
    },
    setCustomerPhone(value) {
      return (self.customerPhone = value);
    },
    setCustomerEmail(value) {
      if (isEmail(value)) {
        return (self.customerEmail = value);
      } else {
        return (self.customerEmail = "");
      }
    },
    setCustomerAcceptedTerms(value) {
      return (self.customerAcceptedTerms = value);
    },
    setCustomerContactLanguage(value) {
      return (self.customerContactLanguage = value);
    },
    setAcceptanceAccountZekTermsAndConditions(value) {
      return (self.customerAcceptedZekTermsAndConditions = value);
    },
    setApplicantIbanNumber(value) {
      if (value) {
        const ibanWithoutWhiteSpaces = value.replace(/\s/g, "");
        return (self.applicantIbanNumber = ibanWithoutWhiteSpaces);
      } else {
        return (self.applicantIbanNumber = null);
      }
    },
    setStep9skipped(value) {
      return (self.step9skipped = value);
    },
    setOptimizeAbTestId(cookies = {}) {
      const abTestId = cookies[c.optimize.cookieName];
      if (abTestId) self.app_ab_testing_version = abTestId;
    },
    setApplicant_budget_confirmation(value) {
      return (self.applicant_budget_confirmation = value);
    },
    doesPartnerWorks() {
      // !([0, 100].indexOf(userStore.incomeSourceIdPartner) !== -1)
      if (
        self.showPartnerInfo &&
        self.incomeSourceIdPartner &&
        self.incomeSourceIdPartner !== 100 &&
        self.incomeSourceIdPartner !== 0
      )
        return true;
      else return false;
    },
    reset_kremo() {
      self.budget_calculation_values.reset_BudgetCalculationValues(); // need reset or the loading screen don't activate, or will load old values!
    },
  }));
