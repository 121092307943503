import PropTypes from "prop-types";
import React from "react";

import TableBody from "./TableBody";
import TableData from "./TableData";
import TableHead from "./TableHead";
import TableHeadItem from "./TableHeadItem";
import TableRow from "./TableRow";

const Table = ({ children }) => (
  <div className="m-table">
    <table cellSpacing="0">{children}</table>
  </div>
);

Table.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Table;

export { Table, TableHead, TableHeadItem, TableBody, TableRow, TableData };
