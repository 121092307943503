import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { validateToken } from "../../../api_clients/document_upload_service";
import {
  TYPE_ID_CARD,
  TYPE_PAY_SLIP,
  trackUpload,
} from "../../../helpers/trackingHelper";
import {
  ROUTENAME_STEP_13,
  ROUTENAME_STEP_13_2,
  ROUTENAME_STEP_14,
} from "../../../lib/Constants";
import FileStore from "../../../stores/FileStore";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWrapper,
} from "../../styleguides/Styleguide-Button";
import FormValidationHint from "../../styleguides/Styleguide-FormValidationHint/index";
import Layout from "../../styleguides/Styleguide-Layout";
import SVGIcon from "../../styleguides/Styleguide-SVGIcon";
import LoadingScreen from "../../utils/LoadingScreen/index";
import DocumentUploadNotValid from "./DocumentUpload_not_valid";
import QuestionUploadItem from "./QuestionUploadItem";
import DocumentUploadWrapper from "./QuestionUploadItem/DocumentUploadWrapper";

function DocumentUpload(props) {
  const content = "step-13:components.DocumentUpload";
  const token = props.viewStore.getSearchParamValue("token");

  const [state, setState] = useState({
    apiVersion: "/v2",
    block: false,
    block2: false,
    initializing: true,
    tokenError: !token,
    uploading: false,
    uploadError: null,
    userContext: {},
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (token) {
      validateToken(token)
        .then((res) => {
          setState({
            ...state,
            apiVersion: res.api_version,
            initializing: false,
            tokenError: !res.variant.length,
            userContext: uncloak_documents(res.variant),
          });
        })
        .catch(() => {
          setState({
            ...state,
            initializing: false,
            tokenError: true,
          });
        });
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    i18next.loadNamespaces("step-14");
  }, []);

  const uncloak_documents = (keys) => {
    const mapping = {
      isSwiss: true,
      showPartner: false,
      isPartnerSwiss: false,
      partnerWorks: false,
    };

    if (keys)
      keys.forEach((element) => {
        switch (element) {
          case "A_CH":
            mapping.isSwiss = true;
            break;
          case "A_CH_N":
            mapping.isSwiss = false;
            break;
          case "P_CH":
            mapping.showPartner = true;
            mapping.isPartnerSwiss = true;
            break;
          case "P_CH_N":
            mapping.showPartner = true;
            mapping.isPartnerSwiss = false;
            break;
          case "P_W":
            mapping.partnerWorks = true;
            break;
          default:
            break;
        }
      });

    return mapping;
  };

  const handleOnclick = (event) => {
    // do not leave page, when files to upload are available or upload is running
    event.preventDefault();
    const { t, history, viewStore } = props;

    if (!state.block && FileStore.numberOfFiles === 0) {
      setState({
        ...state,
        block: true,
        uploading: false,
        uploadError: t(content + ".error_no_valid"),
      });
    } else {
      setState({
        ...state,
        uploading: true,
        uploadError: null,
      });
      FileStore.uploadValidNonRejectedFiles(token, state.apiVersion)
        .catch((e) => {
          if (state.block2) return;
          throw e;
        })
        .then(() => {
          const path = viewStore.getLocalizedPath(
            props.part === 2 ? ROUTENAME_STEP_14 : ROUTENAME_STEP_13_2
          );
          history.push(props.part === 2 ? path : `${path}?token=${token}`);
        })
        .catch(() => {
          setState({
            ...state,
            block2: true,
            uploading: false,
            uploadError: t(content + ".error_no_valid"),
          });
        });
    }
  };

  const { part, t, viewStore } = props;
  const { userContext } = state;
  let view;
  // show instructions to send documents by post in case
  // token is invalid or expired
  if (state.tokenError) {
    view = (
      <div className="l-step-group">
        <div className="l-step-item">
          <DocumentUploadNotValid />
        </div>
      </div>
    );
  } else if (!state.initializing) {
    view = (
      <div className="l-step-group">
        <div className="l-step-item">
          <label className="a-text a-text--dark-blue-large">
            {t(
              content + (!part || part === 1 ? ".disclaimer" : ".disclaimer2")
            )}
          </label>
        </div>

        {/* applicant passport */}
        {(!part || part === 1) && (
          <DocumentUploadWrapper
            headlineField={
              "1. " +
              (userContext.isSwiss
                ? t(content + ".uploadGroupHeadlinePrimary")
                : t(content + ".uploadGroupHeadlinePrimaryNoneSwiss"))
            }
            labelField={t(content + ".uploadGroupLabelPrimary")}
          >
            <QuestionUploadItem
              id={0}
              title={t(content + ".title_id_front")}
              onFileAccepted={() => trackUpload(TYPE_ID_CARD, "front")}
            />
            <QuestionUploadItem
              id={1}
              title={t(content + ".title_id_back")}
              onFileAccepted={() => trackUpload(TYPE_ID_CARD, "back")}
            />
          </DocumentUploadWrapper>
        )}

        {/* partner passport */}
        {(!part || part === 1) && userContext.showPartner && (
          <React.Fragment>
            <DocumentUploadWrapper
              headlineField={
                "2. " +
                (userContext.isPartnerSwiss
                  ? t(content + ".uploadGroupHeadlinePrimaryPartner")
                  : t(content + ".uploadGroupHeadlinePrimaryPartnerNoneSwiss"))
              }
              labelField={t(content + ".uploadGroupLabelPrimary")}
            >
              <QuestionUploadItem
                id={2}
                title={t(content + ".title_id_front")}
              />
              <QuestionUploadItem
                id={3}
                title={t(content + ".title_id_back")}
              />
            </DocumentUploadWrapper>
          </React.Fragment>
        )}

        {/* part 2 - 2nd upload page */}
        {/* applicant pay slips */}
        {part === 2 && (
          <DocumentUploadWrapper
            headlineField={
              `${2 + (userContext.showPartner ? 1 : 0)}. ` +
              t(content + ".uploadGroupHeadlineSecondary")
            }
          >
            <QuestionUploadItem
              id={4}
              title={t(content + ".title_1_payroll")}
              onFileAccepted={() => trackUpload(TYPE_PAY_SLIP, 1)}
            />
            <QuestionUploadItem
              id={5}
              title={t(content + ".title_2_payroll")}
              onFileAccepted={() => trackUpload(TYPE_PAY_SLIP, 2)}
            />
            <QuestionUploadItem
              id={6}
              title={t(content + ".title_3_payroll")}
              onFileAccepted={() => trackUpload(TYPE_PAY_SLIP, 3)}
            />
          </DocumentUploadWrapper>
        )}

        {/* partner pay slips */}
        {part === 2 && userContext.partnerWorks && (
          <DocumentUploadWrapper
            headlineField={
              "4. " + t(content + ".uploadGroupHeadlineSecondaryPartner")
            }
          >
            <QuestionUploadItem
              id={7}
              title={t(content + ".title_1_payroll")}
            />
            <QuestionUploadItem
              id={8}
              title={t(content + ".title_2_payroll")}
            />
            <QuestionUploadItem
              id={9}
              title={t(content + ".title_3_payroll")}
            />
          </DocumentUploadWrapper>
        )}

        {state.uploadError && (
          <FormValidationHint>{state.uploadError}</FormValidationHint>
        )}

        <ButtonWrapper>
          <ButtonPrimary
            onClick={handleOnclick}
            state={state.uploading ? "disabled" : null}
            to="" // not used
          >
            <span className="a-nexus__text">
              {t(
                !part || part === 1
                  ? "global:labels.next_button"
                  : "step-13:next_button"
              )}
            </span>
          </ButtonPrimary>
          {part === 2 && (
            <ButtonSecondary
              to={
                viewStore.getLocalizedPath(ROUTENAME_STEP_13) +
                (window.location.search || "")
              }
            >
              <span className="a-nexus__icon-left">
                <SVGIcon icon="back-arrow" />
              </span>
              <span className="a-nexus__text">
                {t("global:labels.back_button")}
              </span>
            </ButtonSecondary>
          )}
        </ButtonWrapper>
      </div>
    );
  }

  const layout_props = {
    headlineKey: "step-13:headline",
    inverted_background: state.tokenError ? false : true,
    pageTitle: "step-13:pageTitle",
    progressBar: true,
  };

  return (
    <Layout data={layout_props}>
      <LoadingScreen
        showLoadingScreen={state.uploading}
        title={t("global:loading_screen_message")}
      />
      {view}
    </Layout>
  );
}

DocumentUpload.propTypes = {
  history: PropTypes.object,
  part: PropTypes.number,
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation("step-13")(
  withRouter(
    inject(({ stores }) => ({
      viewStore: stores.view,
      userStore: stores.user,
    }))(observer(DocumentUpload))
  )
);
