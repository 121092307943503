import cn from "classnames";
import { inject, observer } from "mobx-react";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint/index";
import DropDowns from "../../../styleguides/Styleguide-TimeDropdowns/index";

class QuestionEmployerWorkDuration extends React.Component {
  constructor(props) {
    super(props);

    this.isPartner = props.isPartner ? true : false;
    this.content = props.isPartner
      ? "step-12:components.QuestionEmployerWorkDuration.partner"
      : "step-12:components.QuestionEmployerWorkDuration";
    this.content_both = "step-12:components.QuestionEmployerWorkDuration";
    this.type_user = this.isPartner
      ? "startDateCurrentEmployerPartner"
      : "startDateCurrentEmployer";

    this.state = {
      inputState: "",
    };
  }

  handleOnChangeDate = (value) => {
    const { userStore } = this.props;

    let state = {};
    if (userStore.isDateInFuture(value)) {
      state.inputState = "future";
    } else {
      state.inputState = "";
      if (!userStore.setStartDateEmployer(value, this.isPartner)) {
        state.inputState = "error";
      }
    }
    this.setState(state);
  };

  /*
		According specification the user should only be able to choose
		between Date-of-birth - 16 years and now.

		UI rule: only date in the past possible and date cannot be older than DOB plus 16 years
	*/
  getMinDate = () => {
    const { userStore } = this.props;
    const applicantBirthDate = userStore.getBirthdate(this.isPartner);
    const minDate = moment(applicantBirthDate, "YYYY-MM-DD")
      .add(16, "years")
      .toString();
    return minDate;
  };

  render() {
    const { t, viewStore, userStore } = this.props;

    let invalid =
      (viewStore.should_step_12_validate && !userStore[this.type_user]) ||
      this.state.inputState;

    if (viewStore.should_step_12_validate && !invalid) {
      viewStore.validateProfessionalDataForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid,
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <label className={titleClasses}>
              {t(this.content + ".question")}
            </label>
          </div>
        </div>
        <div className="m-info-block__pattern">
          <DropDowns
            onDateChange={this.handleOnChangeDate}
            minDate={this.getMinDate()}
            defaultDate={userStore[this.type_user]}
            invalid={invalid}
          />
          {invalid && (
            <FormValidationHint>
              {this.state.inputState === "future"
                ? t(this.content_both + ".error_future")
                : t(this.content + ".error")}
            </FormValidationHint>
          )}
        </div>
      </div>
    );
  }
}

QuestionEmployerWorkDuration.propTypes = {
  t: PropTypes.func.isRequired,
  userStore: PropTypes.object.isRequired,
  viewStore: PropTypes.object.isRequired,
};

export default withTranslation("step-12")(
  inject(({ stores }) => ({
    userStore: stores.user,
    ViewStore: stores.view,
  }))(observer(QuestionEmployerWorkDuration))
);
