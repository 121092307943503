import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { isLatin1 } from "../../../../helpers/validationHelper";
import FormControl from "../../../styleguides/Styleguide-FormControl/index";

class InputNumber extends React.Component {
  constructor(props) {
    super(props);

    this.isFormer = props.isFormer ? true : false;
    this.content = this.isFormer
      ? "step-11:components.QuestionApplicantsFullAddress.former"
      : "step-11:components.QuestionApplicantsFullAddress";
    this.content_both = "step-11:components.QuestionApplicantsFullAddress";

    this.type = this.isFormer
      ? "address_house_number_former"
      : "address_house_number";

    this.state = {
      number: props.userStore[this.type] || "",
      inputState: "",
      inputHint: "",
    };
  }

  handleChange = (number) => {
    // Check if input are spaces only. If so, reset value
    if (!number.match(/\S/g)) {
      number = "";
    }

    if (!isLatin1(number)) {
      return null;
    }

    if (number.length <= 4) {
      this.setState({ number });
    }
  };

  hasNumber(houseNumber) {
    return /\d/.test(houseNumber);
  }

  handleBlur = () => {
    const { number } = this.state;
    const { t } = this.props;
    const currentState = {
      inputState: "",
      inputHint: "",
    };

    // reset errors /warnings
    this.setState(currentState);

    if (!number || !number.length || !this.hasNumber(number)) {
      this.props.userStore.setApplicantsAddressHouseNumber(null, this.isFormer);
    }

    if (!this.hasNumber(number)) {
      currentState.inputState = "error";
      currentState.inputHint = t(
        this.content_both + ".hint_number_content_digit"
      );
    } else if (number.length > 4) {
      currentState.inputState = "error";
      currentState.inputHint = t(this.content_both + ".hint_number_content");
    }

    this.setState(currentState);

    // set value in store, when valid
    if (currentState.inputState !== "error") {
      this.props.userStore.setApplicantsAddressHouseNumber(
        number,
        this.isFormer
      );
    }
  };

  render() {
    const { viewStore, userStore, t } = this.props;

    let { inputState, inputHint } = this.state;
    const { number } = this.state;
    if (
      viewStore.shouldPersonalDataFormValidate &&
      !userStore[this.type] &&
      inputState !== "error"
    ) {
      inputState = "error";
      inputHint = t(`${this.content_both}.hint_number`);
    }

    if (viewStore.shouldPersonalDataFormValidate && inputState !== "error") {
      viewStore.validatePersonalDataForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": inputState === "error",
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <label className={titleClasses}>
              {t(this.content + ".question_number")}
            </label>
          </div>
          <div className="m-info-block__pattern">
            <FormControl
              componentClass="input"
              label={t(this.content_both + ".placeholder_number")}
              id={this.isFormer ? "numberFormer" : "house-number"}
              autoComplete={this.isFormer ? "numberFormer" : "house-number"}
              onChange={this.handleChange}
              value={number}
              inputState={inputState}
              hint={inputHint}
              onBlur={this.handleBlur}
              isLast={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

InputNumber.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(InputNumber))
);
