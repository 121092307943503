import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

class FormControlCheckBox extends React.Component {
  handleOnChange = (event) => {
    this.props.onChange(event);
  };

  render() {
    const { defaultChecked, noflex, disabled, invalid } = this.props;

    const checkBoxClassNames = cn({
      "a-checkbox": true,
      "a-checkbox--noflex": noflex,
      "state-a-checkbox--disabled": disabled,
    });

    return (
      <div className={checkBoxClassNames}>
        <input
          type="checkbox"
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          defaultChecked={defaultChecked}
          onChange={(e) => this.handleOnChange(e)}
        />

        {!invalid && (
          <label htmlFor={this.props.id}>{this.props.labelContent}</label>
        )}

        {invalid && (
          <label htmlFor={this.props.id} className="label-error">
            {this.props.labelContent}
          </label>
        )}

        {invalid && this.props.hint && (
          <p className="error-inline">{this.props.hint}</p>
        )}
      </div>
    );
  }
}

FormControlCheckBox.defaultProps = {
  defaultChecked: false,
  noflex: false,
  disable: false,
};

FormControlCheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  name: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  noflex: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default FormControlCheckBox;
