import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import {
  KEY_HOUSEWIFE,
  KEY_NOT_WORKING,
  getEmploymentStatus,
} from "../../../../../data/employmentStatusData";
import FormValidationHint from "../../../../styleguides/Styleguide-FormValidationHint/index";
import RadioButtonGroup from "../../../../styleguides/Styleguide-RadioButtonGroup/index";
import Tooltip from "../../../../styleguides/Styleguide-Tooltip/index";

class QuestionJobSituation extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.type_user = props.isPartner
      ? "incomeSourceIdPartner"
      : "incomeSourceId";
    this.content = props.isPartner
      ? "step-5:components.QuestionJobSituation.partner"
      : "step-5:components.QuestionJobSituation";
    this.content_both = "step-5:components.QuestionJobSituation";
  }

  handleChange(event) {
    const { userStore, isPartner } = this.props;
    const value = Number(event.currentTarget.value);
    userStore.setIncomeSourceId(value, isPartner);
  }

  render() {
    const { userStore, viewStore, t, isPartner } = this.props;
    // show status "not working" and "housewife" only for partner
    const _employmentStatus = getEmploymentStatus(t).filter(
      (e) => isPartner || ![KEY_HOUSEWIFE, KEY_NOT_WORKING].includes(e.key)
    );

    const _fields = {
      radioGroupName: `occupational_status${isPartner ? "_partner" : ""}`,
      list: _employmentStatus,
    };

    const invalid =
      viewStore.shouldCustomerIncomeFormValidate &&
      userStore[this.type_user] === null;

    if (viewStore.shouldCustomerIncomeFormValidate && !invalid) {
      viewStore.validateCustomerIncomeForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid,
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <label className={titleClasses}>
              {t(this.content + ".question")}
            </label>
            <Tooltip tooltip={t(this.content + ".tooltip")} />
          </div>
          <div className="m-info-block__pattern">
            <RadioButtonGroup
              doNotTrack={isPartner}
              fields={_fields}
              onChange={this.handleChange}
              selectedValue={userStore[this.type_user]}
              invalid={invalid}
            />
            {invalid && (
              <FormValidationHint>
                {t(this.content + ".hint")}
              </FormValidationHint>
            )}
          </div>
        </div>
      </div>
    );
  }
}

QuestionJobSituation.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation("step-5")(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionJobSituation))
);
