import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

const DocumentUploadNotValid = ({ t }) => {
  const content = "step-13:components:DocumentUpload:";

  return (
    <React.Fragment>
      <h2 className="a-text">
        <span
          dangerouslySetInnerHTML={{ __html: t(content + "not_valid_header") }}
        />
      </h2>
      <p></p>
      <p className="a-text a-text--dark-blue-large">
        <span
          dangerouslySetInnerHTML={{ __html: t(content + "not_valid_message") }}
        />
      </p>
      <p></p>

      <p className="a-text a-text--dark-blue-large">
        <span
          dangerouslySetInnerHTML={{ __html: t(content + "bottom_section") }}
        />
      </p>
    </React.Fragment>
  );
};

DocumentUploadNotValid.propTypes = {
  t: PropTypes.func,
};

export default withTranslation("step-13")(DocumentUploadNotValid);
