import cn from "classnames";
import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { extraIncomeEmploymentStatusValues } from "../../../../../lib/Functions";
import FormControl from "../../../../styleguides/Styleguide-FormControl";
import FormValidationHint from "../../../../styleguides/Styleguide-FormValidationHint/index";
import QuestionExtraIncome from "../QuestionExtraIncome";
import QuestionExtraIncomeFrequency from "../QuestionExtraIncomeFrequency";

class QuestionExtraIncomeSource extends React.Component {
  constructor(props) {
    super(props);
    const { isPartner, t } = props;

    this.type_user = isPartner
      ? "extraIncomeSourcePartner"
      : "extraIncomeSource";
    this.content = "step-5:components.QuestionExtraIncome";
    this.options = isPartner
      ? extraIncomeEmploymentStatusValues(t).filter((item) => item.value !== 6)
      : extraIncomeEmploymentStatusValues(t);

    this.state = {
      curLang: i18next.resolvedLanguage,
    };
  }

  // TODO deprecated
  // TODO use i18nKey in Functions
  componentWillReceiveProps() {
    let oldLang = this.state.curLang;

    if (oldLang !== i18next.resolvedLanguage) {
      this.setState({ curLang: i18next.resolvedLanguage });

      this.options = extraIncomeEmploymentStatusValues(this.props.t).filter(
        (s) => !this.props.isPartner || s.value !== 6
      );
    }
  }

  handleChange(value) {
    const { id_key, isPartner, userStore } = this.props;
    userStore.setExtraIncomeSource(id_key, value, isPartner);

    if (value !== 1) {
      userStore.setExtraIncomeFrequency(id_key, 12, isPartner);
    } else {
      userStore.setExtraIncomeFrequency(id_key, null, isPartner);
    }
    this.forceUpdate();
  }

  render() {
    const { t, id_key, isPartner, viewStore, userStore } = this.props;
    const invalid =
      !isPartner &&
      viewStore.shouldCustomerIncomeFormValidate &&
      userStore.hasExtraIncome &&
      userStore.extraIncomeSource.length === 0;
    const invalidPartner =
      isPartner &&
      viewStore.shouldCustomerIncomeFormValidate &&
      userStore.hasExtraIncomePartner &&
      userStore.extraIncomeSourcePartner.length === 0;
    const selected_value = this.options.find(
      (o) =>
        o.value === userStore.getExtraIncomeSource(id_key, this.props.isPartner)
    );

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": isPartner ? invalidPartner : invalid,
    });

    return (
      <React.Fragment>
        {id_key === 0 ? (
          <div className="l-step-group">
            <div className="l-step-item">
              <div ref={this.blockRef} className="m-info-block">
                <div className="m-info-block__head">
                  <span className={titleClasses}>
                    {isPartner
                      ? t(
                          "step-5:components.QuestionExtraIncome.Partner.question"
                        )
                      : t("step-5:components.QuestionExtraIncome.question")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="l-step-item-extra l-step-item">
          <div ref={this.blockRef} className="m-info-block">
            <div className="m-info-block__pattern">
              <FormControl
                componentClass="select"
                radioGroupName={this.type_user}
                options={this.options}
                value={selected_value}
                onChange={(data) => this.handleChange(data.value)}
                placeholder={t(
                  "step-5:components:QuestionExtraIncome.placeholder_incomeSource"
                )}
                wrapperClassNames="a-dropdown--extraIncomeSource"
                parentBlockRef={this.blockRef}
                invalid={isPartner ? invalidPartner : invalid}
                readOnly={this.props.isInModal ? "readonly" : false}
              />
              {(invalid || invalidPartner) && (
                <FormValidationHint>
                  {t("step-5:components.QuestionExtraIncome.hintSource")}
                </FormValidationHint>
              )}
            </div>
          </div>

          {userStore.getExtraIncomeSource(id_key, this.props.isPartner) && (
            <QuestionExtraIncome {...{ isPartner }} id_key={id_key} />
          )}
          {userStore.getExtraIncomeSource(id_key, this.props.isPartner) ===
            1 && (
            <QuestionExtraIncomeFrequency {...{ isPartner }} id_key={id_key} />
          )}
        </div>
      </React.Fragment>
    );
  }
}

QuestionExtraIncomeSource.propTypes = {
  id_key: PropTypes.number.isRequired,
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionExtraIncomeSource))
);
