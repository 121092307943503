import React from "react";

import FormControl from "../../../styleguides/Styleguide-FormControl/index";

const BirthYearDropDown = (props) => {
  const { selected, invalid, placeholder } = props;
  // Year dropdown
  const yearOptions = [];
  const currentYear = new Date().getFullYear();
  const minYear = currentYear - 25;
  const maxYear = currentYear;
  let yearDefaultValue;
  for (let i = maxYear; i >= minYear; i--) {
    const obj = { value: i, label: i };
    yearOptions.push(obj);
    if (i === selected) {
      yearDefaultValue = obj;
    }
  }

  return (
    <div style={props.style}>
      <FormControl
        componentClass="select"
        type={"tel"}
        options={yearOptions}
        value={yearDefaultValue}
        invalid={invalid}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

export default BirthYearDropDown;
