import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import QuestionJobSituation from "./QuestionJobSituation";
import QuestionNetIncome from "./QuestionNetIncome";
import QuestionNetIncomeFrequency from "./QuestionNetIncomeFrequency";

class NetIncome extends React.Component {
  constructor(props) {
    super(props);

    this.type_user = props.isPartner ? "incomePartner" : "income";
  }

  render() {
    const { userStore, isPartner } = this.props;

    return (
      <React.Fragment>
        <QuestionJobSituation {...{ isPartner }} />
        {(!isPartner ||
          (!!userStore.incomeSourceIdPartner &&
            userStore.doesPartnerWorks())) && (
          <React.Fragment key="uniqueKey">
            <QuestionNetIncome {...{ isPartner }} />
            <QuestionNetIncomeFrequency {...{ isPartner }} />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

NetIncome.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation("step-5")(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(NetIncome))
);
