import { Provider } from "mobx-react";
import React, { Component, Suspense } from "react";
import { Route } from "react-router-dom";

import RouteHandler from "./RouteHandler";
import ScrollToTop from "./ScrollToTop";
import { getSessionData } from "./api_clients/session_service";
import "./assets/css/cembra.css";
import "./assets/css/ui.css";
import { RootStore } from "./stores/RootStore";

export const routeRenderFunction = (props) => <RouteHandler route={props} />;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stores: null,
    };
  }

  componentDidMount() {
    // load user session from BE
    getSessionData()
      // if session has valid store data, merge it
      .then((sessionData) => {
        const storesUpdated = Object.assign({}, sessionData);
        this.initApp(RootStore.create(storesUpdated));
      })
      .catch(() => {
        this.initApp(RootStore.create());
      });

    function isTextInput(node) {
      const nodeName = (node && node.nodeName) || "";
      return ["INPUT", "TEXTAREA", "DIV"].includes(nodeName.toUpperCase());
    }

    document.addEventListener(
      "touchstart",
      { passive: false },
      function (e) {
        if (
          document.getElementsByClassName("react-select__option").length <= 0
        ) {
          if (
            !isTextInput(e.target) &&
            isTextInput(document.activeElement) &&
            !e.target.classList.contains("react-select__option") &&
            !e.target.classList.contains("react-select__input")
          ) {
            document.activeElement.blur();
          }
        } else {
          e.preventDefault();
        }
      },
      false
    );
  }

  initApp = (stores) => {
    this.setState({ stores });
  };

  render() {
    const { stores } = this.state;

    // Do not render, until app checked for existing session data
    if (stores) {
      return (
        <React.Fragment>
          <Suspense fallback={"Loading..."}>
            <ScrollToTop>
              <div id="app-root">
                <Provider stores={stores}>
                  <Route render={routeRenderFunction} />
                </Provider>
              </div>
            </ScrollToTop>
            <div id="modal-root" />
          </Suspense>
        </React.Fragment>
      );
    }
    return <div />;
  }
}

export default App;
