import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withCookies } from "react-cookie";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { trackConfirmation } from "../../../helpers/trackingHelper";
import { ROUTENAME_STEP_11, ROUTENAME_STEP_13 } from "../../../lib/Constants";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWrapper,
} from "../../styleguides/Styleguide-Button";
import FormValidationHint from "../../styleguides/Styleguide-FormValidationHint/index";
import Layout from "../../styleguides/Styleguide-Layout";
import SVGIcon from "../../styleguides/Styleguide-SVGIcon/index";
import FailedActionModal from "../../utils/FailedActionModal";
import LoadingScreen from "../../utils/LoadingScreen";
import AccountZekTermsAndConditions from "./AccountZekTermsAndConditions";
import QuestionAccountNumber from "./QuestionAccountNumber";
import QuestionEmployerInformation from "./QuestionEmployerInformation";

class ProfessionalData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activateLoadingScreen: false,
      applicationFailed: false,
    };
  }

  componentDidMount() {
    const { cookies, history, viewStore, userStore } = this.props;

    if (viewStore.get_step_validated() === 12) {
      history.goForward();
    } else if (!(viewStore.get_step_validated() === 11)) {
      history.push(viewStore.getLocalizedPath(ROUTENAME_STEP_11));
    }
    i18next.loadNamespaces("step-13");

    userStore.setOptimizeAbTestId(cookies.getAll());
  }

  handleFailedActionModalClose = () => {
    this.setState({ applicationFailed: false });
  };

  handleOnClickNextButton(event) {
    event.preventDefault();
    const { viewStore, userStore, history } = this.props;

    if (viewStore.validateProfessionalDataForm()) {
      // Loading screen after 3 seconds after click on "next" to make the submission "visible" to the applicant
      this.setState({ activateLoadingScreen: true });

      userStore
        .sendApplication()
        .then((token) => {
          trackConfirmation();
          viewStore.pushGTMConfirmation(token["web_app_id"]);
          history.push(this.getNextUriWithToken(token["file_upload_token"]));
        })
        .catch((e) => {
          this.setState({
            activateLoadingScreen: false,
            applicationFailed: true,
          });
        });
    } else viewStore.set_should_step_12_validate(true);
  }

  getNextUriWithToken(fileUploadToken) {
    const { viewStore } = this.props;

    let uri = viewStore.getLocalizedPath(ROUTENAME_STEP_13);
    if (fileUploadToken) uri += `?token=${fileUploadToken}`;
    return uri;
  }

  render() {
    const { t, viewStore, userStore } = this.props;
    const layout_props = {
      pageTitle: "step-12:pageTitle",
      headlineKey: "step-12:headline", // passing only the key, it's translated within Layout Trans component
      inverted_background: true,
      progressBar: true,
    };

    return (
      <Layout data={layout_props}>
        <div className="l-step-group">
          <LoadingScreen
            showLoadingScreen={this.state.activateLoadingScreen}
            title={t("global:loading_screen_message")}
          />
          {this.state.applicationFailed && (
            <FailedActionModal onClose={this.handleFailedActionModalClose} />
          )}
          <QuestionEmployerInformation />
          {userStore.showPartnerInfo && userStore.doesPartnerWorks() && (
            <QuestionEmployerInformation isPartner={true} />
          )}
          <QuestionAccountNumber />
          <AccountZekTermsAndConditions />
          {viewStore.should_step_12_validate &&
            !viewStore.validateProfessionalDataForm() && (
              <FormValidationHint>
                {t("global:labels.hint_step")}
              </FormValidationHint>
            )}

          <ButtonWrapper>
            <ButtonPrimary
              to={viewStore.getLocalizedPath(ROUTENAME_STEP_13)}
              onClick={(e) => this.handleOnClickNextButton(e)}
            >
              <span className="a-nexus__text">{t("step-12:next_button")}</span>
            </ButtonPrimary>
            <ButtonSecondary to={viewStore.getLocalizedPath(ROUTENAME_STEP_11)}>
              <span className="a-nexus__icon-left">
                <SVGIcon icon="back-arrow" />
              </span>
              <span className="a-nexus__text">
                {t("global:labels.back_button")}
              </span>
            </ButtonSecondary>
          </ButtonWrapper>
        </div>
      </Layout>
    );
  }
}

ProfessionalData.propTypes = {
  t: PropTypes.func.isRequired,
  userStore: PropTypes.object.isRequired,
  viewStore: PropTypes.object.isRequired,
};

export default withCookies(
  withRouter(
    withTranslation("step-12")(
      inject(({ stores }) => ({
        viewStore: stores.view,
        userStore: stores.user,
        loanStore: stores.user,
        stores: stores,
      }))(observer(ProfessionalData))
    )
  )
);
