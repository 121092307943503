import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import FormControl from "../../../styleguides/Styleguide-FormControl/index";

const InputZipCity = ({ userStore, t }) => {
  const content = "step-11:components.QuestionApplicantsFullAddress";

  return (
    <div className="l-step-item">
      <div className="m-info-block">
        <div className="m-info-block__head">
          <label className="m-info-block__title">
            {t(content + ".question_zip_city")}
          </label>
        </div>
        <div className="m-info-block__pattern">
          <FormControl
            label={t(content + ".question_zip_city")}
            id="zipCity"
            value={`${userStore.address_zip || ""} ${
              userStore.address_city || ""
            }`}
            inputState="readOnly"
            isLast={true}
          />
        </div>
      </div>
    </div>
  );
};

InputZipCity.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
  }))(observer(InputZipCity))
);
