import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { getStreetNamesByZIPandName } from "../../../../api_clients/address_service";
import { isLatin1 } from "../../../../helpers/validationHelper";
import {
  KEYCODE_DOWN_ARROW,
  KEYCODE_UP_ARROW,
  KEYCODE_ESCAPE,
  INPUT_PULLDOWN_RESET_IDX,
} from "../../../../lib/Constants";
import FormControl from "../../../styleguides/Styleguide-FormControl/index";

class InputStreet extends React.Component {
  constructor(props) {
    super(props);

    this.isFormer = props.isFormer ? true : false;
    this.content = this.isFormer
      ? "step-11:components.QuestionApplicantsFullAddress.former"
      : "step-11:components.QuestionApplicantsFullAddress";
    this.content_both = "step-11:components.QuestionApplicantsFullAddress";

    this.type = this.isFormer ? "address_street_former" : "address_street";

    this.pulldownItemFocusedRef = React.createRef();

    this.state = {
      street: props.userStore[this.type] || "",
      inputState: "",
      inputHint: "",
      streetList: [],
      pulldownCursorIdx: INPUT_PULLDOWN_RESET_IDX,
    };
  }

  handleChange = (street) => {
    const { userStore } = this.props;

    if (street.length > 25 || !isLatin1(street)) {
      return null;
    }

    // Check if input are spaces only. If so, reset value
    if (!street.match(/\S/g)) {
      street = "";
    }
    this.setState({ street });

    // only use suggest search if user lives in Switzerland and street is not from former address
    if (!userStore.residenceOutOfSwitzerland && !this.isFormer) {
      if (street.length > 1) {
        getStreetNamesByZIPandName(userStore.address_zip, street)
          .then((response) => {
            this.setState({
              streetList: response,
            });
          })
          .catch(console.log);
      } else {
        this.setState({
          streetList: [],
        });
      }
    }
  };

  handleBlur = () => {
    const { street } = this.state;
    const { t } = this.props;
    const currentState = {
      inputState: "",
      inputHint: "",
    };

    // reset errors /warnings
    this.setState(currentState);

    if (!street || !street.length) {
      this.props.userStore.setApplicantsAddressStreet(null, this.isFormer);
      return false;
    }

    if (street.match(/\d/)) {
      currentState.inputState = "error";
      currentState.inputHint = t(`${this.content}.hint_street`);
    } else if (street.length > 25) {
      currentState.inputState = "error";
      currentState.inputHint = t(`${this.content_both}.hint_street_content`);
    }

    this.setState(currentState);

    // set value in store, when valid
    if (currentState.inputState !== "error") {
      this.props.userStore.setApplicantsAddressStreet(street, this.isFormer);
    }
  };

  handleAddressSelection(event, item) {
    event.preventDefault();
    this.setState({
      street: item.name || "",
      streetList: [],
    });

    this.props.userStore.setApplicantsAddressStreet(
      item.name || null,
      this.isFormer
    );
    this._resetStreetPulldown();
  }

  handleStreetKeyDown = (e) => {
    // handle escape
    if (e.keyCode === KEYCODE_ESCAPE) {
      this._resetStreetPulldown();
    }
    // handle only up/down arrows
    if (e.keyCode !== KEYCODE_UP_ARROW && e.keyCode !== KEYCODE_DOWN_ARROW) {
      return;
    }

    let cursorIdx = this.state.pulldownCursorIdx;
    if (e.keyCode === KEYCODE_UP_ARROW && cursorIdx > 0) {
      cursorIdx--;
    } else if (
      e.keyCode === KEYCODE_DOWN_ARROW &&
      cursorIdx < this.state.streetList.length - 1
    ) {
      cursorIdx++;
    }
    e.preventDefault();
    this._focusOnItemInPulldown(cursorIdx);
  };

  _focusOnItemInPulldown(cursorIdx) {
    this.setState(
      {
        pulldownCursorIdx: cursorIdx,
      },
      () => {
        if (this.pulldownItemFocusedRef.current) {
          this.pulldownItemFocusedRef.current.focus();
        }
      }
    );
  }

  _resetStreetPulldown() {
    this.setState({
      pulldownCursorIdx: INPUT_PULLDOWN_RESET_IDX,
      streetList: [],
    });
  }

  render() {
    const { userStore, viewStore, t } = this.props;

    let { inputState, inputHint } = this.state;
    const { street } = this.state;
    if (
      viewStore.shouldPersonalDataFormValidate &&
      !userStore[this.type] &&
      inputState !== "error"
    ) {
      inputState = "error";
      inputHint = t(`${this.content}.hint_street`);
    }

    if (viewStore.shouldPersonalDataFormValidate && inputState !== "error") {
      viewStore.validatePersonalDataForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": inputState === "error",
    });

    /* TODO (26.11.2018 mariusz) refactor - create a common input-with-pulldown component (CEMWEBSF-582)*/
    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <label className={titleClasses}>
              {t(this.content + ".question_street")}
            </label>
          </div>
          <div className="m-info-block__pattern">
            <div
              className="a-input-with-pulldown-wrapper"
              onKeyDown={this.handleStreetKeyDown}
            >
              <FormControl
                componentClass="input"
                label={t(this.content_both + ".placeholder_street")}
                id={this.isFormer ? "streetFormer" : "street"}
                onChange={this.handleChange}
                value={street}
                inputState={inputState}
                hint={inputHint}
                onBlur={this.handleBlur}
                autoComplete={"noAutocomplete"}
                isLast={true}
              />
              {this.state.streetList.length > 0 && (
                <div className="m-pulldown m-pulldown--autosuggestion state-m-pulldown--open">
                  <ul className="m-pulldown__links">
                    {this.state.streetList.map((item, i) => {
                      return (
                        <li key={i} className="m-pulldown__link-item">
                          <a
                            ref={
                              i === this.state.pulldownCursorIdx &&
                              this.pulldownItemFocusedRef
                            }
                            href="#ignore"
                            className="a-link"
                            onClick={(event) =>
                              this.handleAddressSelection(event, item)
                            }
                          >
                            {item.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

InputStreet.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(InputStreet))
);
