import cn from "classnames";
import i18next from "i18next";
import { inject, observer } from "mobx-react";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { getNationalityData } from "../../../../api_clients/nationality_service";
import CountrySelector from "../../../styleguides/Styleguide-CountrySelector/index";
import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint/index";
import RadioButtonGroup from "../../../styleguides/Styleguide-RadioButtonGroup/index";
import TimeDropdowns from "../../../styleguides/Styleguide-TimeDropdowns/TimeDropDown_DDMMYYYY";
import Tooltip from "../../../styleguides/Styleguide-Tooltip/index";
import PermitIdButtonGroup from "./PermitIdButtonGroup";

class QuestionNationality extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleNationalityChange = this.handleNationalityChange.bind(this);
    this.handlepermitIdChange = this.handlepermitIdChange.bind(this);
    this.handlePermitSinceChange = this.handlePermitSinceChange.bind(this);
    this.state = {
      nationalityOptions: [],
      invalidDate: null,
      invalidDatePartner: null,
      errors: {
        permitSince: null,
        permitSincePartner: null,
      },
    };
    this.countryBlockRef = React.createRef();
    this.permitSinceBlockRef = React.createRef();
    this.permitSincePartnerBlockRef = React.createRef();
    this.content = "step-4:components.QuestionNationality";
  }

  getCountriesList() {
    getNationalityData(i18next.resolvedLanguage)
      .then((data) => {
        let _nationalities = [];
        data.forEach((item) => {
          _nationalities.push({
            value: item.id,
            label: item.name,
          });
        });

        /*	items.sort(function (a, b) {
					return a.order - b.order;
				  });
				  */
        // First 5 values shouldn't be re-ordered
        const _defaultNationalities = _nationalities.slice(0, 6);
        // Remove item for Switzerland
        const defaultNationalities = _defaultNationalities.filter(
          (item) => item.value !== "CH"
        );
        // from value 5, order entries by name
        const otherNationalities = _nationalities.slice(5);
        const sortedNationalities = otherNationalities.sort((itemA, itemB) =>
          itemA.label.localeCompare(itemB.label)
        );
        // Concat arrays and return
        _nationalities = data =
          defaultNationalities.concat(sortedNationalities);

        this.setState({ nationalityOptions: _nationalities });
      })
      .catch((e) => {
        console.log("error get json file of nationalities", e);
      });
  }

  handleChange(event, isPartner = false) {
    const { userStore } = this.props;
    const value = event.currentTarget.value;
    const invalid = isPartner ? "invalidDatePartner" : "invalidDate";

    if (value === "CH") {
      userStore.setPermitId(null, isPartner);
      userStore.clearPermitSince(isPartner);
      this.setState({ [invalid]: false });
    }

    userStore.setNationality(value, isPartner);
  }

  handleNationalityChange(value, isPartner = false) {
    const { userStore } = this.props;

    if (!value) {
      return;
    }
    userStore.setNationality(value.toUpperCase(), isPartner);

    if (value === "LI") {
      userStore.setPermitId(null, isPartner);
      userStore.clearPermitSince(isPartner);
    }
  }

  handlepermitIdChange(event, isPartner = false) {
    if (event.currentTarget && event.currentTarget.value) {
      this.props.userStore.setPermitId(event.currentTarget.value, isPartner);
    }
  }

  handlePermitSinceChange(date, isPartner = false) {
    const userBirthdayType = isPartner
      ? "birthdayValuesPartner"
      : "birthdayValues";
    const invalid = isPartner ? "invalidDatePartner" : "invalidDate";
    const selectedDate = moment(date, "YYYY-MM-DD");
    const birthMonthYear = moment(
      `${this.props.userStore[userBirthdayType].year}-${this.props.userStore[userBirthdayType].month}-${this.props.userStore[userBirthdayType].day}`,
      "YYYY-MM-DD"
    );
    let today = new Date();
    today = moment(today, "YYYY-MM-DD");

    if (
      selectedDate.isValid() &&
      selectedDate >= birthMonthYear &&
      selectedDate <= today
    ) {
      this.props.userStore.setPermitSince(date, isPartner);
      this.setState({ [invalid]: false });
    } else {
      this.setState({ [invalid]: true });
      this.props.userStore.clearPermitSince(isPartner);
    }
  }

  render() {
    const { viewStore, t, userStore } = this.props;
    let selectedValue;
    let invalid = false;
    const invalidNationality = (invalid =
      viewStore.should_step_4_validate &&
      (userStore.nationality === null || userStore.nationality === "__other"));
    const invalidForeignNationality = (invalid =
      viewStore.should_step_4_validate &&
      (userStore.nationality === null || userStore.nationality === "__other"));
    const invalidPermit = (invalid =
      viewStore.should_step_4_validate &&
      userStore.nationality !== "LI" &&
      userStore.nationality !== "CH" &&
      userStore.permitId === null);
    const invalidResidenceTime = (invalid =
      (viewStore.should_step_4_validate &&
        userStore.permitSince === null &&
        userStore.nationality !== "LI" &&
        userStore.nationality !== "CH") ||
      this.state.invalidDate);
    const invalidNationalityPartner = (invalid =
      viewStore.should_step_4_validate &&
      userStore.isEditingPartnerInfo() &&
      (userStore.nationalityPartner === null ||
        userStore.nationalityPartner === "__other"));
    const invalidForeignNationalityPartner = (invalid =
      viewStore.should_step_4_validate &&
      userStore.isEditingPartnerInfo() &&
      (userStore.nationalityPartner === null ||
        userStore.nationalityPartner === "__other"));
    const invalidPermitPartner = (invalid =
      viewStore.should_step_4_validate &&
      userStore.isEditingPartnerInfo() &&
      userStore.permitIdPartner === null &&
      userStore.nationalityPartner !== "LI" &&
      userStore.nationalityPartner !== "CH");
    const invalidResidenceTimePartner = (invalid =
      (viewStore.should_step_4_validate &&
        userStore.isEditingPartnerInfo() &&
        userStore.permitSincePartner === null &&
        userStore.nationalityPartner !== "LI" &&
        userStore.nationalityPartner !== "CH") ||
      this.state.invalidDatePartner);

    if (viewStore.should_step_4_validate && !invalid) {
      if (
        (this.props.isPartner && !this.state.invalidDatePartner) ||
        (!this.props.isPartner && !this.state.invalidDate)
      ) {
        viewStore.validateCustomerAccommodationForm();
      }
    }

    if (userStore.nationality !== null) {
      selectedValue = userStore.nationality !== "CH" ? "__other" : "CH";
    }

    let countrySelectionFieldsSelectedValue;
    if (userStore.nationalityPartner !== null) {
      countrySelectionFieldsSelectedValue =
        userStore.nationalityPartner !== "CH" ? "__other" : "CH";
    }

    const titleNationalityClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalidNationality,
    });

    const titleNationalityPartnerClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error":
        invalidNationalityPartner || invalidForeignNationalityPartner,
    });

    const titlePermitClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalidPermit,
    });

    const titlePermitPartnerClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalidPermitPartner,
    });

    const titleResidenzTimeClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalidResidenceTime,
    });

    const titleResidenzTimePartnerClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalidResidenceTimePartner,
    });

    const list = [
      {
        value: "CH",
        label: t(this.content + ".switzerland"),
      },
      {
        value: "__other",
        label: t(this.content + ".foreign"),
      },
    ];

    const live_user = {
      radioGroupName: "nationality",
      list,
    };

    const live_partner = {
      radioGroupName: "live_partner",
      list,
    };

    if (
      !this.state.nationalityOptions.length &&
      ((userStore.nationality !== null && userStore.nationality !== "CH") ||
        (userStore.nationalityPartner !== null &&
          userStore.nationalityPartner !== "CH"))
    )
      this.getCountriesList();

    return (
      <React.Fragment>
        <h2 className="a-text a-text--subtitle">
          {t("step-4:components:QuestionNationality:subtitle_nationality")}
        </h2>
        <div className="l-step-item">
          <div ref={this.countryBlockRef} className="m-info-block">
            <div className="m-info-block__head">
              <label className={titleNationalityClasses}>
                {t(this.content + ".question_area")}
              </label>
              <Tooltip
                nameID={"is_swiss?"}
                tooltip={t(this.content + ".tooltip_area")}
              />
            </div>
            <div className="m-info-block__pattern">
              <RadioButtonGroup
                fields={live_user}
                onChange={this.handleChange}
                selectedValue={selectedValue}
                invalid={invalidNationality}
              />
              {userStore.nationality !== null &&
                userStore.nationality !== "CH" && (
                  <CountrySelector
                    options={this.state.nationalityOptions}
                    placeholder={t(this.content + ".country_placeholder")}
                    handleChange={this.handleNationalityChange}
                    defaultValue={userStore.nationality}
                    wrapperClassNames="a-dropdown--nationality"
                    invalid={invalidForeignNationality}
                    parentBlockRef={this.countryBlockRef}
                  />
                )}

              {viewStore.should_step_4_validate &&
                (userStore.nationality === null ||
                  userStore.nationality === "__other") && (
                  <FormValidationHint>
                    {t(this.content + ".hint_area")}
                  </FormValidationHint>
                )}
            </div>
          </div>
        </div>

        {
          /* Button group for residence ids*/
          userStore.nationality !== null &&
            ["CH", "__other", "LI"].indexOf(userStore.nationality) === -1 && ( // Do not show section for Liechtenstein
              <div className="l-step-item">
                <div className="m-info-block">
                  <div className="m-info-block__head">
                    <label className={titlePermitClasses}>
                      {t(this.content + ".question_permit_id")}
                    </label>
                    <Tooltip
                      nameID={"Permit"}
                      tooltip={t(this.content + ".tooltip_permit_id")}
                    />
                  </div>
                  <div className="m-info-block__pattern">
                    <PermitIdButtonGroup
                      onChange={this.handlepermitIdChange}
                      selectedValue={userStore.permitId}
                      invalid={invalidPermit}
                    />
                    {invalidPermit && (
                      <FormValidationHint>
                        {t(this.content + ".hint_permit_id")}
                      </FormValidationHint>
                    )}
                  </div>
                </div>
              </div>
            )
        }

        {
          /* Since when does the foreign user lives in Switzerland?*/
          userStore.nationality !== null &&
            ["CH", "__other", "LI"].indexOf(userStore.nationality) === -1 && // Do not show section for Liechtenstein
            ["B", "C"].indexOf(userStore.permitId) !== -1 && (
              <div className="l-step-item">
                <div ref={this.permitSinceBlockRef} className="m-info-block">
                  <div className="m-info-block__head">
                    <label className={titleResidenzTimeClasses}>
                      {t(this.content + ".question_since_ch")}
                    </label>
                  </div>
                  {t(this.content + ".tooltip_since_ch") && (
                    <span className="m-info-block__lead">
                      {t(this.content + ".tooltip_since_ch")}
                    </span>
                  )}
                  <div className="m-info-block__pattern">
                    <TimeDropdowns
                      minDate={userStore.getBirthdate() || "1970-01-01"}
                      onDateChange={this.handlePermitSinceChange}
                      defaultDate={userStore.permitSince}
                      invalid={invalidResidenceTime || this.state.invalidDate}
                      parentBlockRef={this.permitSinceBlockRef}
                    />
                    {this.state.errors.permitSince && (
                      <FormValidationHint>
                        {this.state.errors.permitSince}
                      </FormValidationHint>
                    )}
                    {this.state.invalidDate && (
                      <FormValidationHint>
                        {t(this.content + ".error_invalid_date")}
                      </FormValidationHint>
                    )}
                    {invalidResidenceTime && !this.state.invalidDate && (
                      <FormValidationHint>
                        {t(this.content + ".hint_since_ch")}
                      </FormValidationHint>
                    )}
                  </div>
                </div>
              </div>
            )
        }
        {
          /* partners nationality*/
          userStore.isEditingPartnerInfo() && (
            <div className="l-step-item">
              <div className="m-info-block">
                <div className="m-info-block__head">
                  <label className={titleNationalityPartnerClasses}>
                    {t(this.content + ".partner.question_nationality")}
                  </label>
                  <Tooltip
                    nameID={"partner_is_swiss?"}
                    tooltip={t(this.content + ":partner:tooltip_area")}
                  />
                </div>
                <div className="m-info-block__pattern">
                  <RadioButtonGroup
                    doNotTrack={true}
                    fields={live_partner}
                    onChange={(event) => this.handleChange(event, true)}
                    selectedValue={countrySelectionFieldsSelectedValue}
                    invalid={invalidNationalityPartner}
                  />
                  {userStore.nationalityPartner !== null &&
                    userStore.nationalityPartner !== "CH" && (
                      <CountrySelector
                        options={this.state.nationalityOptions}
                        placeholder={t(this.content + ".country_placeholder")}
                        handleChange={(value) =>
                          this.handleNationalityChange(value, true)
                        }
                        defaultValue={userStore.nationalityPartner}
                        wrapperClassNames="a-dropdown--nationality"
                        invalid={invalidForeignNationalityPartner}
                      />
                    )}
                  {viewStore.should_step_4_validate &&
                    (userStore.nationalityPartner === null ||
                      userStore.nationalityPartner === "__other") && (
                      <FormValidationHint>
                        {t(this.content + ".partner.hint_area")}
                      </FormValidationHint>
                    )}
                </div>
              </div>
            </div>
          )
        }

        {
          /* Button group for residence (partner) ids*/
          userStore.isEditingPartnerInfo() &&
            userStore.nationalityPartner !== null &&
            ["CH", "__other", "LI"].indexOf(userStore.nationalityPartner) ===
              -1 && ( // Do not show section for Liechtenstein
              <div className="l-step-item">
                <div className="m-info-block">
                  <div className="m-info-block__head">
                    <label className={titlePermitPartnerClasses}>
                      {t(this.content + ".partner.question_permit_id")}
                    </label>
                  </div>
                  <div className="m-info-block__pattern">
                    <PermitIdButtonGroup
                      onChange={(event) =>
                        this.handlepermitIdChange(event, true)
                      }
                      selectedValue={userStore.permitIdPartner}
                      isPartner={true}
                      invalid={invalidPermitPartner}
                    />
                    {invalidPermitPartner && (
                      <FormValidationHint>
                        {t(this.content + ".partner.hint_permit_id")}
                      </FormValidationHint>
                    )}
                  </div>
                </div>
              </div>
            )
        }

        {
          /* Since when does the foreign user lives in Switzerland?*/
          userStore.isEditingPartnerInfo() &&
            userStore.nationalityPartner !== null &&
            ["CH", "__other", "LI"].indexOf(userStore.nationalityPartner) ===
              -1 && ( // Do not show section for Liechtenstein
              <div className="l-step-item">
                <div
                  ref={this.permitSincePartnerBlockRef}
                  className="m-info-block"
                >
                  <div className="m-info-block__head">
                    <label className={titleResidenzTimePartnerClasses}>
                      {t(this.content + ".partner.question_since_ch")}
                    </label>
                  </div>
                  {t(this.content + ".partner.description") && (
                    <span className="m-info-block__lead">
                      {t(this.content + ".partner.description")}
                    </span>
                  )}
                  <div className="m-info-block__pattern">
                    <TimeDropdowns
                      minDate={userStore.getBirthdate(true) || "1950-01-01"}
                      onDateChange={(date) =>
                        this.handlePermitSinceChange(date, true)
                      }
                      defaultDate={userStore.permitSincePartner}
                      invalid={invalidResidenceTimePartner}
                      parentBlockRef={this.permitSincePartnerBlockRef}
                    />
                    {this.state.errors.permitSincePartner && (
                      <FormValidationHint>
                        {this.state.errors.permitSincePartner}
                      </FormValidationHint>
                    )}
                    {this.state.invalidDatePartner && (
                      <FormValidationHint>
                        {t(this.content + ".error_invalid_date")}
                      </FormValidationHint>
                    )}
                    {invalidResidenceTimePartner &&
                      !this.state.invalidDatePartner && (
                        <FormValidationHint>
                          {t(this.content + ".partner.hint_since_ch")}
                        </FormValidationHint>
                      )}
                  </div>
                </div>
              </div>
            )
        }
      </React.Fragment>
    );
  }
}

QuestionNationality.propTypes = {
  selectedValue: PropTypes.string,
  viewStore: PropTypes.object.isRequired,
  userStore: PropTypes.object.isRequired,
  loanStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation("step-4")(
  inject(({ stores }) => ({
    viewStore: stores.view,
    userStore: stores.user,
    loanStore: stores.loan,
  }))(observer(QuestionNationality))
);
