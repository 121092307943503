import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { trackStep } from "../../../helpers/trackingHelper";
import * as c from "../../../lib/Constants";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWrapper,
} from "../../styleguides/Styleguide-Button/index";
import FormValidationHint from "../../styleguides/Styleguide-FormValidationHint/index";
import Layout from "../../styleguides/Styleguide-Layout";
import SVGIcon from "../../styleguides/Styleguide-SVGIcon/index";
import QuestionBirthday from "./QuestionBirthday";
import QuestionChildren from "./QuestionChildren";
import QuestionMaritalStatus from "./QuestionMaritalStatus";
import QuestionSalutation from "./QuestionSalutation";

class UserBasicDemographics extends React.Component {
  constructor(props) {
    super(props);
    this.validateAndSendData = this.validateAndSendData.bind(this);
  }
  validateAndSendData(event) {
    const { viewStore } = this.props;

    if (!viewStore.vaildateUserBasicDemographicsForm()) {
      event.preventDefault();
      viewStore.userBasicDemographicsFormComponentsShouldValidate(true);
    } else {
      viewStore.save_session(3);
      trackStep(2);
    }
  }

  componentDidMount() {
    const { loanStore, viewStore } = this.props;

    const queryAmount = viewStore.getSearchParamValue(
      c.SEARCHPARAM_LOAN_AMOUNT
    );
    if (queryAmount) {
      try {
        loanStore.setLoanAmount(queryAmount);
      } catch (e) {
        // MAYBE: track error
      }
    }

    const queryTerm = viewStore.getSearchParamValue(c.SEARCHPARAM_LOAN_TERM);
    if (queryTerm) {
      try {
        loanStore.setLoanTerm(queryTerm);
      } catch (e) {
        // MAYBE: track error
      }
    }

    i18next.loadNamespaces("step-4");
  }

  render() {
    const { viewStore, t, userStore } = this.props;

    const layout_props = {
      pageTitle: "step-3:pageTitle",
      headlineKey: "step-3:headline", // passing only the key, it's translated within Layout Trans component
      inverted_background: true,
      progressBar: true,
    };

    return (
      <Layout data={layout_props}>
        <div className="l-step-group">
          <QuestionSalutation gaTitle={t("step-3:pageTitle")} />
          <QuestionMaritalStatus gaTitle={t("step-3:pageTitle")} />
          <QuestionBirthday isPartner={false} gaTitle={t("step-3:pageTitle")} />
          {userStore.showPartnerInfo && (
            <React.Fragment>
              <QuestionSalutation ispartner={true} />
              <QuestionBirthday isPartner={true} />
            </React.Fragment>
          )}
          <QuestionChildren gaTitle={t("step-3:pageTitle")} />

          {viewStore.should_step_3_validate &&
            !viewStore.vaildateUserBasicDemographicsForm() && (
              <FormValidationHint>
                {t("global:labels.hint_step")}
              </FormValidationHint>
            )}
          <ButtonWrapper>
            <ButtonPrimary
              to={viewStore.getLocalizedPath(c.ROUTENAME_STEP_4)}
              onClick={this.validateAndSendData}
            >
              <span className="a-nexus__text">{t("step-3:next_button")}</span>
            </ButtonPrimary>
            <ButtonSecondary
              to={viewStore.getLocalizedPath(c.ROUTENAME_STEP_1)}
            >
              <span className="a-nexus__icon-left">
                <SVGIcon icon="back-arrow" />
              </span>
              <span className="a-nexus__text">
                {t("global:labels.back_button")}
              </span>
            </ButtonSecondary>
          </ButtonWrapper>
        </div>
      </Layout>
    );
  }
}

UserBasicDemographics.propTypes = {
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
  loanStore: PropTypes.object,
  t: PropTypes.func,
};

export default withRouter(
  withTranslation("step-3")(
    inject(({ stores }) => ({
      userStore: stores.user,
      viewStore: stores.view,
      loanStore: stores.loan,
    }))(observer(UserBasicDemographics))
  )
);
