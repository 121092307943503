import PropTypes from "prop-types";

import FormControlCheckbox from "./FormControlCheckbox";
import FormControlInput from "./FormControlInput";
import FormControlSelect from "./FormControlSelect";
import FormControlTextarea from "./FormControlTextarea";

const elementMapper = {
  input: FormControlInput,
  textarea: FormControlTextarea,
  select: FormControlSelect,
  checkbox: FormControlCheckbox,
};

const FormControl = (props) => {
  const Component = elementMapper[props.componentClass];
  return <Component {...props} />;
};

FormControl.propTypes = {
  componentClass: PropTypes.oneOf(["input", "select", "textarea", "checkbox"])
    .isRequired,
};

FormControl.defaultProps = {
  componentClass: "input",
};

export default FormControl;

export {
  FormControlInput,
  FormControlTextarea,
  FormControlSelect,
  FormControlCheckbox,
};
