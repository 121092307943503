import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint/index";
import RadioButtonGroup from "../../../styleguides/Styleguide-RadioButtonGroup/index";

class QuestionSalutation extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.content = props.ispartner
      ? "step-3:components.salutation.partner"
      : "step-3:components.salutation";
    this.type_user = props.ispartner ? "genderPartner" : "gender";
  }

  handleChange(event) {
    const { userStore, ispartner } = this.props;

    const value = event.currentTarget.value;
    userStore.setGender(value, ispartner);
  }

  render() {
    const { viewStore, userStore, t } = this.props;

    const list = [
      {
        value: "m",
        label: t("step-3:components.salutation.men"),
      },
      {
        value: "f",
        label: t("step-3:components.salutation.women"),
      },
    ];

    const data = {
      list,
      radioGroupName: this.type_user,
    };

    const invalid =
      viewStore.should_step_3_validate && userStore[this.type_user] == null;

    if (viewStore.should_step_3_validate && invalid === false) {
      viewStore.vaildateUserBasicDemographicsForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid,
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <label className={titleClasses}>
              {t(this.content + ".question")}
            </label>
          </div>
          <div className="m-info-block__pattern">
            <RadioButtonGroup
              doNotTrack={this.props.ispartner}
              fields={data}
              onChange={this.handleChange}
              selectedValue={userStore[this.type_user]}
              invalid={invalid}
            />
            {invalid && (
              <FormValidationHint>
                {t(this.content + ".hint")}
              </FormValidationHint>
            )}
          </div>
        </div>
      </div>
    );
  }
}

QuestionSalutation.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation(["step-3"])(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionSalutation))
);
