import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint/index";
import TimeDropdowns from "../../../styleguides/Styleguide-TimeDropdowns/index";

class QuestionCurrentAddressDuration extends React.Component {
  constructor(props) {
    super(props);
    this.setResidenceSince = this.setResidenceSince.bind(this);
    this.content = "step-11:components.QuestionCurrentAddressDuration";

    this.state = {
      inputState: "",
    };
  }

  setResidenceSince(value) {
    const { userStore } = this.props;
    let state = {};

    if (
      userStore.isDateInFuture(value) ||
      userStore.isDateBeforeBirthday(value)
    ) {
      state.inputState = "future";
    } else {
      state.inputState = "";
      if (!userStore.setResidenceSince(value)) {
        state.inputState = "error";
      }
    }
    this.setState(state);
  }

  render() {
    const { userStore, viewStore, t } = this.props;
    const invalid =
      (!!viewStore.shouldPersonalDataFormValidate &&
        !userStore.residenceSince) ||
      !!this.state.inputState;

    if (invalid) {
      userStore.setResidenceSince(null);
    }

    if (viewStore.shouldPersonalDataFormValidate && invalid === false)
      viewStore.validatePersonalDataForm();

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid,
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <label className={titleClasses}>
              {t(this.content + ".question")}
            </label>
          </div>
          <div className="m-info-block__pattern">
            <TimeDropdowns
              minDate={userStore.getBirthdate() || "1970-01-01"}
              onDateChange={this.setResidenceSince}
              defaultDate={userStore.residenceSince}
              invalid={invalid}
            />
            {invalid && (
              <FormValidationHint>
                {this.state.inputState === "future"
                  ? t(this.content + ".error_future")
                  : t(this.content + ".error")}
              </FormValidationHint>
            )}
          </div>
        </div>
      </div>
    );
  }
}

QuestionCurrentAddressDuration.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionCurrentAddressDuration))
);
