/**
 * @param {number} value
 * @returns {number} Rounded number with 2 decimals at max.
 */
export const roundTo2Decimals = (value) => {
  // + casts to number
  return +value.toFixed(2);
};

/**
 * @param {number} value
 * @returns {string}
 */
export const roundTo5Cent = (value) => {
  return (Math.ceil(value * 20 - 0.5) / 20).toFixed(2);
};

/**
 * @param {number | string} value
 * @returns {number}
 */
export const toNumber = (value) => {
  const v = typeof value === "string" ? value.replace(/[^\d\\.]/gm, "") : value;

  return parseFloat(v);
};
