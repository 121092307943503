import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { FormControlCheckbox } from "../../../styleguides/Styleguide-FormControl";
import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint/index";
import RadioButtonGroup from "../../../styleguides/Styleguide-RadioButtonGroup/index";
import Tooltip from "../../../styleguides/Styleguide-Tooltip/index";

class QuestionMaritalStatus extends React.Component {
  setMaterialStatus(value) {
    this.props.userStore.setMaritalStatus(value);
  }

  render() {
    const { userStore, t, viewStore } = this.props;
    const selectedValue = userStore.maritalStatus;
    const invalid =
      viewStore.should_step_3_validate && userStore.maritalStatus === null;
    const invalidYN =
      viewStore.should_step_3_validate &&
      userStore.maritalStatus === 1 &&
      userStore.showPartnerInfo === null;
    const invalidPartnerDisclaimer =
      viewStore.should_step_3_validate &&
      userStore.showPartnerInfo &&
      !userStore.customerAcceptedPartnerDisclaimer;

    if (
      viewStore.should_step_3_validate &&
      invalid === false &&
      invalidYN === false
    ) {
      viewStore.vaildateUserBasicDemographicsForm();
    }

    const yesno = {
      radioGroupName: "provide_partner_details",
      list: [
        {
          value: true,
          label: t("global:labels.yes"),
        },
        {
          value: false,
          label: t("global:labels.no"),
        },
      ],
    };

    const list = [
      {
        value: 2,
        label: t("step-3:components.QuestionMaritalStatus.single"),
      },
      {
        value: 1,
        label: t("step-3:components.QuestionMaritalStatus.married"),
      },
      {
        value: 3,
        label: t("step-3:components.QuestionMaritalStatus.divorced"),
      },
      {
        value: 4,
        label: t("step-3:components.QuestionMaritalStatus.widowed"),
      },
      {
        value: 5,
        label: t("step-3:components.QuestionMaritalStatus.legally_separated"),
      },
    ];

    const data = {
      list,
      radioGroupName: "marital_status",
    };

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid,
    });

    return (
      <React.Fragment>
        <div className="l-step-item">
          <div className="m-info-block">
            <div className="m-info-block__head">
              <label className={titleClasses}>
                {t("step-3:components.QuestionMaritalStatus.question")}
              </label>
              <Tooltip
                tooltip={t("step-3:components.QuestionMaritalStatus.tooltip")}
              />
            </div>
            <div className="m-info-block__pattern">
              <RadioButtonGroup
                fields={data}
                onChange={(event) =>
                  userStore.setMaritalStatus(Number(event.currentTarget.value))
                }
                selectedValue={selectedValue}
                invalid={invalid}
              />
              {invalid && (
                <FormValidationHint>
                  {t("step-3:components.QuestionMaritalStatus.hint")}
                </FormValidationHint>
              )}
            </div>
          </div>
        </div>
        {userStore.maritalStatus === 1 && (
          <div className="l-step-item">
            <div className="m-info-block">
              <div className="m-info-block__head">
                {!invalidYN && (
                  <label className="m-info-block__title">
                    {t(
                      "step-3:components.QuestionMaritalStatus.question_add_partner"
                    )}
                  </label>
                )}
                {invalidYN && (
                  <label className="m-info-block__title m-info-block__title-error">
                    {t(
                      "step-3:components.QuestionMaritalStatus.question_add_partner"
                    )}
                  </label>
                )}

                <Tooltip
                  tooltip={t(
                    "step-3:components.QuestionMaritalStatus.tooltip_partner"
                  )}
                />
              </div>
              <span className="m-info-block__lead">
                {t("step-3:components:QuestionMaritalStatus:hint_partner")}
              </span>
              <div className="m-info-block__pattern">
                <RadioButtonGroup
                  fields={yesno}
                  selectedValue={userStore.showPartnerInfo}
                  onChange={(event) =>
                    userStore.setShowPartnerInfo(
                      JSON.parse(event.currentTarget.value)
                    )
                  }
                  invalid={invalidYN}
                />
                {invalidYN && (
                  <FormValidationHint>
                    {t(
                      "step-3:components.QuestionMaritalStatus.hint_show_partner_info"
                    )}
                  </FormValidationHint>
                )}
              </div>
              {userStore.showPartnerInfo && (
                <div className="a-checkbox" style={{ marginBottom: "-47px" }}>
                  <FormControlCheckbox
                    id="acceptPartnerDisclaimer"
                    name="oos"
                    onChange={(e) =>
                      userStore.setCustomerAcceptedPartnerDisclaimer(
                        e.currentTarget.checked
                      )
                    }
                    defaultChecked={userStore.customerAcceptedPartnerDisclaimer}
                    invalid={invalidPartnerDisclaimer}
                    hint={t(
                      "step-3:components.QuestionMaritalStatus.disclaimer_error"
                    )}
                    noflex
                    labelContent={t(
                      "step-3:components.QuestionMaritalStatus.disclaimer_partner"
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

QuestionMaritalStatus.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation(["step-3"])(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionMaritalStatus))
);
