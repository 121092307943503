import PropTypes, { string } from "prop-types";
import React from "react";

import { truncate_text } from "../../../../lib/Functions";
import SVGIcon from "../../../styleguides/Styleguide-SVGIcon/index";

const ErrorItem = ({ errors, file, removeFile, t }) => (
  <React.Fragment>
    <div className="m-upload-error">
      <div className="m-upload-error__box">
        <div className="m-upload-error__preview">
          <SVGIcon icon="exclamation-mark" />
        </div>
      </div>
      {file && file.name && (
        <div className="a-text--dark-blue" style={{ textAlign: "center" }}>
          <span>{truncate_text(file.name)}</span>
          <div className="m-upload-error__actions" style={{ display: "block" }}>
            <a
              href="#ignore"
              className="a-nexus a-nexus--base"
              onClick={(e) => {
                e.preventDefault();
                removeFile(file);
              }}
            >
              <span className="a-nexus__text">
                {t("step-13:components.DocumentUpload.file_upload_delete")}{" "}
                <SVGIcon icon="clear" />
              </span>
            </a>
          </div>
        </div>
      )}
    </div>
    <div className="m-upload__error">
      <ul className="m-element-list">
        {errors.map((error, i) => (
          <li className="m-element-list__item--upload" key={i}>
            <p className="a-text a-text--error">
              {t(`step-13:components.DocumentUpload.${error}`)}
            </p>
          </li>
        ))}
      </ul>
    </div>
  </React.Fragment>
);

ErrorItem.propTypes = {
  errors: PropTypes.arrayOf(string).isRequired,
  file: PropTypes.object.isRequired,
  removeFile: PropTypes.func.isRequired,
};

export default ErrorItem;
