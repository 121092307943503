// rudimentary query string logic to avoid 3-rd party lib
// like "query-string" or polyfill for "URLSearchParams"

/** Returns single value from query string. */
export const getValueByKey = (queryString, key) => {
  let qs = queryString || "";
  if (qs.startsWith("?")) qs = queryString.substring(1);

  const tuple = qs
    .split("&")
    .map((s) => {
      const [k, v] = s.split("=");
      return { key: k, value: v };
    })
    .find((o) => o.key === key);

  return tuple ? tuple.value : null;
};
