import PropTypes from "prop-types";
import React from "react";

const ButtonWrapper = ({ children }) => (
  <div className="l-buttons">{children}</div>
);

ButtonWrapper.propTypes = {
  children: PropTypes.node,
};

export default ButtonWrapper;
