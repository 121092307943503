import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { isLatin1 } from "../../../../helpers/validationHelper";
import { length } from "../../../../lib/Functions";
import FormControl from "../../../styleguides/Styleguide-FormControl/index";
import Tooltip from "../../../styleguides/Styleguide-Tooltip/index";

class QuestionFirstName extends React.Component {
  constructor(props) {
    super(props);

    this.isPartner = props.isPartner ? true : false;
    this.content = this.isPartner
      ? "step-11:components.QuestionFirstName.partner"
      : "step-11:components.QuestionFirstName";
    this.content_both = "step-11:components.QuestionFirstName";

    this.type = this.isPartner ? "firstNamePartner" : "firstName";
    this.state = {
      name: props.userStore[this.type] || "",
      inputState: null,
      inputHint: null,
    };

    this.MIN_LENGTH = 2;
    this.MAX_LENGTH = 26;
    this.FULLNAME_MAX_LENGTH = this.MIN_LENGTH + this.MAX_LENGTH;
  }

  handleChange = (value) => {
    value = value.replace(/\s\s+/g, " "); // remove double spaces

    if (value.length > this.MAX_LENGTH) {
      return null;
    }

    if (
      this.props.viewStore.hasDigit(value) ||
      this.props.viewStore.isOnlyWhitespace(value) ||
      !isLatin1(value)
    ) {
      return;
    }

    this.setState({ name: value });
  };

  handleOnBlur = () => {
    const { userStore, t, isPartner } = this.props;
    let { name } = this.state;

    const state = {};
    state.inputState = "";
    state.inputHint = "";

    if (name && typeof name.trim === "function") {
      name = name.trim();
      state.name = name;
    }

    if (!name) {
      state.inputState = "error";
      state.inputHint = t(`${this.content}.hint`);
    } else if (name.length < this.MIN_LENGTH) {
      state.inputState = "error";
      state.inputHint = t(this.content_both + ".hintLength");
    }

    // Set userStore only if name is valid
    userStore.setFirstName(name, isPartner);

    this.setState(state);
  };

  render() {
    let { inputState, inputHint, name } = this.state;
    const { userStore, viewStore, t, isPartner } = this.props;
    const invalid =
      viewStore.shouldPersonalDataFormValidate &&
      !userStore[this.type] &&
      inputState !== "error";

    if (invalid) {
      inputState = "error";
      inputHint = t(`${this.content}.hint`);
    } else if (
      isPartner &&
      length(userStore.firstNamePartner) + length(userStore.lastNamePartner) >
        28
    ) {
      inputState = "error";
      inputHint = t(`${this.content_both}.hintLength`);
    } else if (
      !isPartner &&
      length(userStore.firstName) + length(userStore.lastName) > 28
    ) {
      inputState = "error";
      inputHint = t(`${this.content_both}.hintLength`);
    }

    if (viewStore.shouldPersonalDataFormValidate && invalid === false) {
      viewStore.validatePersonalDataForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": inputState === "error",
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <label className={titleClasses}>
              {t(`${this.content}.question`)}
            </label>
            <Tooltip
              nameID={this.type}
              tooltip={t(`${this.content}.tooltip`)}
            />
          </div>
          <label className="m-info-block__lead">
            {t(`${this.content}.description`)}
          </label>
          <div className="m-info-block__pattern">
            <FormControl
              label={t(`${this.content}.question`)}
              id={this.type}
              autoComplete={isPartner ? "" : "given-name"}
              onChange={this.handleChange}
              onBlur={() => this.handleOnBlur()}
              inputState={inputState}
              hint={inputHint}
              value={name}
              isLast={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

QuestionFirstName.propTypes = {
  isPartner: PropTypes.bool,
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionFirstName))
);
