import cn from "classnames";
import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import Pulldown from "../Styleguide-Pulldown";
import { PulldownItem } from "../Styleguide-Pulldown/PulldownItem";
import SVGIcon from "../Styleguide-SVGIcon";

class HeaderMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pulldownOpen: false,
    };
  }

  handleLangPulldownClose = () => {
    this.setState({ pulldownOpen: false });
  };

  handleLangPulldownSelect = (e, langId) => {
    this.handleLangPulldownClose();
    this.props.onLangSelect(e, langId);
  };

  getLanguageLabel(lang, langList) {
    let found = [];
    if (langList && lang) {
      found = langList.filter((item) => item.name === lang);
    }
    return found[0] ? found[0].displayname : "";
  }

  togglePulldownState = () => {
    this.setState({ pulldownOpen: !this.state.pulldownOpen });
  };

  render() {
    const { onBurgerClick, offCanvasMenuVisible, t } = this.props;
    const burgerLinkClasses = cn({
      "a-burger": true,
      "state-a-burger--open": offCanvasMenuVisible,
    });
    const langLogo =
      "o-header__logo o-header__logo-" + i18next.resolvedLanguage;
    return (
      <React.Fragment>
        <div className="l-page-container l-page-container--invert">
          <div className="l-page-inner">
            <div className="g-container g-layout--header">
              <div className="g-cell">
                <div className="o-header">
                  <div className="o-header__claim">
                    <a className={langLogo} href={t("global:cembra_home_url")}>
                      &nbsp;
                    </a>
                  </div>
                  <div className="o-header__wrap-nav">
                    <ul className="m-nav-meta">
                      {this.props.links.map((linkData, i) => {
                        return (
                          <li key={i} className="m-nav-meta__item">
                            <a
                              href={linkData.url}
                              className="a-nexus"
                              target={linkData.target}
                              rel="noopener noreferrer"
                              onClick={linkData.onClick}
                            >
                              <span className="a-nexus__text">
                                {linkData.name}
                              </span>
                            </a>
                          </li>
                        );
                      })}
                      <li className="m-nav-meta__item m-nav-meta__item--pulldown">
                        <div
                          className="a-nexus js-m-nav-meta__pulldown"
                          onClick={this.togglePulldownState}
                        >
                          <span className="a-nexus__text">
                            {this.getLanguageLabel(
                              i18next.resolvedLanguage,
                              this.props.langList
                            )}
                            <span className="a-nexus__icon-right">
                              <SVGIcon icon="pulldown-small-down" />
                            </span>
                          </span>
                        </div>
                      </li>

                      <li className="m-nav-meta__item m-nav-meta__item--offcanvas">
                        <div
                          className={burgerLinkClasses}
                          onClick={onBurgerClick}
                        >
                          <div className="a-burger__flex">
                            <div className="a-burger__pos">
                              <span className="a-burger__icon" />
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div className="o-header__position">
                      {this.state.pulldownOpen && (
                        <Pulldown
                          data={this.props.langList.map(
                            (item) =>
                              new PulldownItem(item.name, item.displayname)
                          )}
                          onCloseClick={this.handleLangPulldownClose}
                          onItemSelect={this.handleLangPulldownSelect}
                          selectedItemId={i18next.resolvedLanguage}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

HeaderMain.propTypes = {
  t: PropTypes.func,
  offCanvasMenuVisible: PropTypes.bool,
  serviceLinks: PropTypes.object,
  onBurgerClick: PropTypes.func,
  onLangSelect: PropTypes.func,
};

export default withTranslation()(
  withRouter(inject(() => ({}))(observer(HeaderMain)))
);
