import axios from "axios";

import { trackApiError } from "../helpers/trackingHelper";
import {
  document_variant_check_url,
  document_upload_url,
  default_document_upload_api_version,
} from "../lib/Constants";

// TODO migrate to fetch api
export function uploadDocument(file, fileUploadToken, documentUploadVersion) {
  var apiVersion = documentUploadVersion || default_document_upload_api_version;

  return new Promise((resolve, reject) => {
    let config = { headers: { "Content-Type": "multipart/form-data" } };

    try {
      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("fileUploadToken", fileUploadToken);

      axios
        .post(
          document_upload_url.replace("{documentUploadVersion}", apiVersion),
          formData,
          config
        )
        .then((response) => {
          if (response.status === 204) {
            resolve(response.status);
          } else {
            reject({ message: "request.status not 204" });
          }
        })
        .catch((e) => {
          trackApiError(
            "document_upload",
            "post",
            e.response && e.response.status
          );
          reject(e);
        });
    } catch (e) {
      trackApiError("document_upload", "post", e.response && e.response.status);
      reject(e);
    }
  });
}

// TODO migrate to fetch api
export function validateToken(token) {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(`${document_variant_check_url}${token}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }
          reject({ message: "no response.data set" });
        })
        .catch(reject);
    } catch (e) {
      trackApiError(
        "document_upload_check",
        "get",
        e.response && e.response.status
      );
      reject({ message: "fetching error" });
    }
  });
}
