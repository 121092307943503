import axios from "axios";

import { trackApiError } from "../helpers/trackingHelper";
import { session_url } from "../lib/Constants";
import { get } from "./http";

/**
 * @returns {Promise<Object>}
 */
export const getSessionData = async () => {
  // endpoint returns 302 instead of 404 in case there is no session,
  // therefore we use Fetch API instead of Axios which can be configured
  // to not follow redirects automatically; just following the redirect
  // results in a CORS issue [anyway most probably the API should be fixed
  // to just respond with 404 instead of a redirect]
  const res = await get(session_url);
  if (res.error) return Promise.reject({ message: "fetching error" });

  return res.data;
};

// TODO migrate to fetch api
export function putSessionData(data) {
  return new Promise((resolve, reject) => {
    const config = { headers: { "Content-Type": "text/plain" } };
    try {
      axios
        .put(session_url, data, config)
        .then(resolve)
        .catch((e) => {
          trackApiError("session", "put", e.response && e.response.status);
          reject(e);
        });
    } catch (e) {
      trackApiError("session", "put", e.response && e.response.status);
      reject({ message: "fetching error" });
    }
  });
}
