import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { isEmail } from "../../../../helpers/validationHelper";
import FormControl from "../../../styleguides/Styleguide-FormControl";
import Tooltip from "../../../styleguides/Styleguide-Tooltip";

class QuestionContactEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customerMail: props.userStore.customerEmail || "",
      inputHint: null,
      inputState: null,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }

  handleEmailChange(customerMail) {
    if (customerMail.length > 50) return null;

    if (!customerMail) {
      this.setState({ customerMail: "" });
    } else {
      this.setState({ customerMail });
    }
  }

  validateEmail() {
    const { t, userStore } = this.props;
    let { customerMail } = this.state;

    if (
      !customerMail ||
      this.state.customerMail === "" ||
      !isEmail(customerMail)
    ) {
      this.setState({
        inputState: "error",
        inputHint: t("step-10:components.QuestionContactEmail.warning"),
      });

      userStore.setCustomerEmail(null);
    } else {
      this.setState({
        inputState: "",
        inputHint: "",
      });
      userStore.setCustomerEmail(customerMail);
    }
  }

  render() {
    const { t, userStore, viewStore } = this.props;
    const { inputState, inputHint, customerMail } = this.state;
    const invalid =
      inputHint ||
      (viewStore.shouldCustomerContactFormValidate &&
        userStore.customerEmail === null);

    if (viewStore.shouldCustomerContactFormValidate && invalid === false) {
      viewStore.validateCustomerContactForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid,
    });

    return (
      <div className="l-step-group">
        <div className="l-step-item">
          <div className="m-info-block">
            <div className="m-info-block__head">
              <span className={titleClasses}>
                {t("step-10:components.QuestionContactEmail.question")}
              </span>
              <Tooltip
                tooltip={t("step-10:components.QuestionContactEmail.tooltip")}
                nameID={"email"}
              />
            </div>
            <div className="m-info-block__pattern">
              <FormControl
                type="email"
                id={"a-input-tel-email"}
                onChange={(value) => this.handleEmailChange(value)}
                label={t("step-10:components.QuestionContactEmail.placeholder")}
                value={customerMail || ""}
                onBlur={this.validateEmail}
                inputState={inputState}
                hint={
                  inputState || invalid
                    ? t("step-10:components.QuestionContactEmail.warning")
                    : ""
                }
                invalid={invalid}
                autoComplete={"email"}
                isLast={true}
                isCustomerEmail={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

QuestionContactEmail.propTypes = {
  checked: PropTypes.bool,
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionContactEmail))
);
