import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { extraIncomeEmploymentStatusValuesBudgetOverview } from "../../../lib/Functions";
import Overlay from "../../styleguides/Styleguide-Overlay";
import {
  Table,
  TableHead,
  TableRow,
  TableHeadItem,
  TableBody,
  TableData,
} from "../../styleguides/Styleguide-Table/index.js";
import ToolTip from "../../styleguides/Styleguide-Tooltip/index";
import LoadingScreen from "../../utils/LoadingScreen";
import ModalPortal from "../../utils/ModalPortal";
import ModalEditQuestions from "./Modal_Edit_Questions/ModalEditQuestions";

class BudgetTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoadingScreen: true,
      editQuestion: null,
      id_key: null,
      _userStore: JSON.parse(JSON.stringify(props.userStore)),
    };
    this.calculate_BC();
  }

  componentDidMount() {
    i18next.loadNamespaces("step-9");
  }

  openModal(question, id_key = null) {
    this.setState({
      _userStore: JSON.parse(JSON.stringify(this.props.userStore)),
      showModal: true,
      lastFocus: document.activeElement,
      editQuestion: question,
      id_key: id_key,
    });
  }

  calculate_BC() {
    const { userStore } = this.props;
    userStore
      .getConsumerWorthinessState()
      .then(() => {
        this.setState({ showLoadingScreen: false });
      })
      .catch((e) => {
        userStore.reset_kremo();
        this.setState({ showLoadingScreen: false });
      });
  }

  closeEditQuestion(value) {
    if (value) {
      //refresh budget calculation
      this.setState({
        editQuestion: null,
        showLoadingScreen: true,
        _userStore: JSON.parse(JSON.stringify(this.props.userStore)),
      });
      this.calculate_BC();
    } else {
      this.setState({ editQuestion: null, showLoadingScreen: false });
      this.props.rootStore.resetUserStore(this.state._userStore);
      //this.calculate_BC()
    }
  }

  render() {
    const { t, viewStore } = this.props;
    const budget_calculation_values =
      this.props.userStore.budget_calculation_values;
    const { editQuestion, id_key, _userStore } = this.state;

    return (
      <React.Fragment>
        <LoadingScreen
          showLoadingScreen={this.state.showLoadingScreen}
          title={t("global:loading_screen_message")}
        />
        {/* !!editQuestion &&
					<ModalEditQuestions {...{editQuestion, id_key}}  closeEditQuestion={(value)=> this.closeEditQuestion(value)}/>
				*/}
        <Table>
          <TableHead>
            {/* main applicant income */}
            <TableRow>
              <TableHeadItem>
                {t("step-7:components:BudgetCalculation:subtitle_income")}
              </TableHeadItem>
              <TableHeadItem />
              <TableHeadItem>
                {t("step-7:components:BudgetCalculation:in_chf")}
              </TableHeadItem>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableData>
                {t("step-7:components:BudgetCalculation:applicant_net_income")}
              </TableData>
              <TableData smallView>
                {`x${_userStore.incomeFrequency}`}
              </TableData>
              <TableData onClick={() => this.openModal("applicant_income")}>
                <span className="editt">
                  {viewStore.prettifyNumber(_userStore.income) || 0}
                </span>
              </TableData>
            </TableRow>
            {!!_userStore.extraIncomeSource &&
              _userStore.extraIncomeSource.map((item, i) => {
                return (
                  <TableRow key={i}>
                    <TableData>
                      {t(
                        "step-7:components:BudgetCalculation:applicant_extra_income"
                      ) +
                        ` - ${
                          extraIncomeEmploymentStatusValuesBudgetOverview(
                            t
                          ).filter(
                            (item) =>
                              item.value === _userStore.extraIncomeSource[i]
                          )[0].label
                        }`}
                    </TableData>
                    <TableData smallView>
                      {`x${_userStore.extraIncomeFrequency[i]}`}
                    </TableData>
                    <TableData
                      onClick={() =>
                        this.openModal("applicant_income_extra_null", i)
                      }
                    >
                      <span className="editt">
                        {viewStore.prettifyNumber(_userStore.extraIncome[i]) ||
                          0}
                      </span>
                    </TableData>
                  </TableRow>
                );
              })}
            {!_userStore.extraIncomeSource.length && (
              <TableRow>
                <TableData>
                  {t(
                    "step-7:components:BudgetCalculation:applicant_extra_income"
                  )}
                </TableData>
                <TableData smallView>{""}</TableData>
                <TableData
                  onClick={() => this.openModal("applicant_income_extra_null")}
                >
                  <span className="editt">{0}</span>
                </TableData>
              </TableRow>
            )}

            {/* partner applicant income */}
            {this.props.userStore.isEditingPartnerInfo() &&
              this.props.userStore.doesPartnerWorks() && (
                <React.Fragment>
                  <TableRow>
                    <TableData>
                      {t(
                        "step-7:components:BudgetCalculation:partner_net_income"
                      )}
                    </TableData>
                    <TableData smallView>
                      {`x${_userStore.incomeFrequencyPartner}`}
                    </TableData>
                    <TableData onClick={() => this.openModal("partner_income")}>
                      <span className="editt">
                        {viewStore.prettifyNumber(_userStore.incomePartner) ||
                          0}
                      </span>
                    </TableData>
                  </TableRow>
                  {!!_userStore.extraIncomeSourcePartner.length &&
                    _userStore.extraIncomeSourcePartner.map((item, i) => {
                      return (
                        <TableRow key={20 + i}>
                          <TableData>
                            {t(
                              "step-7:components:BudgetCalculation:partner_extra_income"
                            ) +
                              ` - ${
                                extraIncomeEmploymentStatusValuesBudgetOverview(
                                  t
                                ).filter(
                                  (item) =>
                                    item.value ===
                                    _userStore.extraIncomeSourcePartner[i]
                                )[0].label
                              }` +
                              " " +
                              t("step-5:components:ExtraIncomeLabels:partner")}
                          </TableData>
                          <TableData smallView>
                            {`x${_userStore.extraIncomeFrequencyPartner[i]}`}
                          </TableData>
                          <TableData
                            onClick={() =>
                              this.openModal("partner_income_extra_null", i)
                            }
                          >
                            <span className="editt">
                              {viewStore.prettifyNumber(
                                _userStore.extraIncomePartner[i]
                              ) || 0}
                            </span>
                          </TableData>
                        </TableRow>
                      );
                    })}
                  {!_userStore.extraIncomeSourcePartner.length && (
                    <TableRow>
                      <TableData>
                        {t(
                          "step-7:components:BudgetCalculation:partner_extra_income"
                        ) +
                          " " +
                          t("step-5:components:ExtraIncomeLabels:partner")}
                      </TableData>
                      <TableData smallView>{""}</TableData>
                      <TableData
                        onClick={() =>
                          this.openModal("partner_income_extra_null")
                        }
                      >
                        <span className="editt">{0}</span>
                      </TableData>
                    </TableRow>
                  )}
                </React.Fragment>
              )}
          </TableBody>

          <TableHead>
            <TableRow>
              <TableHeadItem>
                {t("step-7:components:BudgetCalculation:subtitle_outgoings")}
              </TableHeadItem>
              <TableHeadItem />
              <TableHeadItem>
                {t("step-7:components:BudgetCalculation:in_chf")}
              </TableHeadItem>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableData>
                {t("step-7:components:BudgetCalculation:outgoings_rent")}
                <ToolTip
                  nameID={"outgoings_rent"}
                  tooltip={t(
                    "step-7:components:BudgetCalculation:info_outgoings_rent"
                  )}
                />
              </TableData>
              <TableData smallView>{""}</TableData>
              <TableData
                onClick={() => this.openModal("expenses_costs_housing")}
              >
                <span className="editt">
                  {viewStore.prettifyNumber(
                    _userStore.expenses_costs_housing
                  ) || 0}
                </span>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>
                {t("step-7:components:BudgetCalculation:outgoings_work")}
                <ToolTip
                  nameID={"outgoings_work"}
                  tooltip={t(
                    "step-7:components:BudgetCalculation:info_outgoings_work"
                  )}
                />
              </TableData>
              <TableData smallView>{""}</TableData>
              <TableData onClick={() => this.openModal("expenses_costs_work")}>
                <span className="editt">
                  {viewStore.prettifyNumber(_userStore.expenses_costs_work) ||
                    0}
                </span>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>
                {t("step-7:components:BudgetCalculation:outgoings_food")}
                <ToolTip
                  nameID={"info_outgoings_food"}
                  tooltip={t(
                    "step-7:components:BudgetCalculation.info_outgoings_food"
                  )}
                />
              </TableData>
              <TableData smallView>{""}</TableData>
              <TableData onClick={() => this.openModal("expenses_costs_food")}>
                <span className="editt">
                  {viewStore.prettifyNumber(_userStore.expenses_costs_food) ||
                    0}
                </span>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>
                {t("step-7:components:BudgetCalculation:outgoings_alimony")}
                <ToolTip
                  nameID={"outgoings_alimony"}
                  tooltip={t(
                    "step-7:components:BudgetCalculation:info_outgoings_alimony"
                  )}
                />
              </TableData>
              <TableData smallView>{""}</TableData>
              <TableData
                onClick={() => this.openModal("expenses_costs_alimony")}
              >
                <span className="editt">
                  {viewStore.prettifyNumber(
                    _userStore.expenses_costs_alimony
                  ) || 0}
                </span>
              </TableData>
            </TableRow>

            {this.props.userStore.hasChildrenUnder25 && (
              <TableRow>
                <TableData>
                  {t(
                    "step-7:components:BudgetCalculation:outgoings_child_care"
                  )}
                  <ToolTip
                    nameID={"outgoings_child_care"}
                    tooltip={t(
                      "step-7:components:BudgetCalculation:info_outgoings_child_care"
                    )}
                  />
                </TableData>
                <TableData smallView>{""}</TableData>
                <TableData
                  onClick={() => this.openModal("expenses_costs_childcare")}
                >
                  <span className="editt">
                    {viewStore.prettifyNumber(
                      _userStore.expenses_costs_childcare
                    ) || 0}
                  </span>
                </TableData>
              </TableRow>
            )}

            <TableRow>
              <TableData>
                {t("step-7:components:BudgetCalculation:outgoings_credit")}
                <ToolTip
                  nameID={"outgoings_credit_monthly"}
                  tooltip={t(
                    "step-7:components:BudgetCalculation:info_outgoings_credit_monthly"
                  )}
                />
              </TableData>
              <TableData smallView>{""}</TableData>
              <TableData
                onClick={() => this.openModal("expenses_costs_otherDebts")}
              >
                <span className="editt">
                  {viewStore.prettifyNumber(
                    _userStore.expenses_costs_otherDebts
                  ) || 0}
                </span>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>
                {t(
                  "step-7:components:BudgetCalculation:outgoings_extra_monthly"
                )}
                <ToolTip
                  nameID={"outgoings_extra_monthly"}
                  tooltip={t(
                    "step-7:components:BudgetCalculation:info_outgoings_extra_monthly"
                  )}
                />
              </TableData>
              <TableData smallView>{""}</TableData>
              <TableData
                onClick={() => this.openModal("expenses_costs_additional")}
              >
                <span className="editt">
                  {viewStore.prettifyNumber(
                    _userStore.expenses_costs_additional
                  ) || 0}
                </span>
              </TableData>
            </TableRow>
            {this.props.userStore.isEditingPartnerInfo() && (
              <React.Fragment>
                <TableRow>
                  <TableData>
                    {t(
                      "step-7:components:BudgetCalculation:outgoings_alimony_partner"
                    )}
                    <ToolTip
                      nameID={"outgoings_alimony_monthly_partner"}
                      tooltip={t(
                        "step-7:components:BudgetCalculation:info_outgoings_alimony_monthly_partner"
                      )}
                    />
                  </TableData>
                  <TableData smallView>{""}</TableData>
                  <TableData
                    onClick={() =>
                      this.openModal("expenses_pays_alimonyPartner")
                    }
                  >
                    <span className="editt">
                      {viewStore.prettifyNumber(
                        _userStore.expenses_costs_alimonyPartner
                      ) || 0}
                    </span>
                  </TableData>
                </TableRow>

                <TableRow>
                  <TableData>
                    {t(
                      "step-7:components:BudgetCalculation:outgoings_extra_monthly_partner"
                    )}
                    <ToolTip
                      nameID={"outgoings_extra_monthly_partner"}
                      tooltip={t(
                        "step-7:components:BudgetCalculation:info_outgoings_extra_monthly_partner"
                      )}
                    />
                  </TableData>
                  <TableData smallView>{""}</TableData>
                  <TableData
                    onClick={() =>
                      this.openModal("expenses_costs_additionalPartner")
                    }
                  >
                    <span className="editt">
                      {viewStore.prettifyNumber(
                        _userStore.expenses_costs_additionalPartner
                      ) || 0}
                    </span>
                  </TableData>
                </TableRow>
              </React.Fragment>
            )}

            {budget_calculation_values.applicant_expenses_calc_basic > 0 && (
              <React.Fragment>
                <TableRow>
                  <TableData>
                    {t(
                      "step-7:components:BudgetCalculation:outgoings_calculated_basic_amount"
                    )}
                    <ToolTip
                      nameID={"outgoings_calculated_basic_amount"}
                      tooltip={t(
                        "step-7:components:BudgetCalculation:info_outgoings_calculated_basic_amount"
                      )}
                    />
                  </TableData>
                  <TableData smallView>{""}</TableData>
                  <TableData>
                    {viewStore.prettifyNumber(
                      budget_calculation_values.applicant_expenses_calc_basic
                    ) || "--"}
                  </TableData>
                </TableRow>

                <TableRow>
                  <TableData>
                    {t(
                      "step-7:components:BudgetCalculation:outgoings_calculated_basic_amount_kids"
                    )}
                    <ToolTip
                      nameID={"outgoings_calculated_basic_amount_kids"}
                      tooltip={t(
                        "step-7:components:BudgetCalculation:info_outgoings_calculated_basic_amount_kids"
                      )}
                    />
                  </TableData>
                  <TableData smallView>{""}</TableData>
                  <TableData>
                    {viewStore.prettifyNumber(
                      budget_calculation_values.applicant_expenses_calc_children_cost
                    ) || "--"}
                  </TableData>
                </TableRow>

                <TableRow>
                  <TableData>
                    {t(
                      "step-7:components:BudgetCalculation:outgoings_calculated_health_insurance"
                    )}
                    <ToolTip
                      nameID={"outgoings_calculated_health_insurance"}
                      tooltip={t(
                        "step-7:components:BudgetCalculation:info_outgoings_calculated_health_insurance"
                      )}
                    />
                  </TableData>
                  <TableData smallView>{""}</TableData>
                  <TableData
                    onClick={() => this.openModal("expenses_costs_insurance")}
                  >
                    <span className="editt">
                      {_userStore.expenses_costs_provided_health_insurance !=
                      null
                        ? viewStore.prettifyNumber(
                            _userStore.expenses_costs_provided_health_insurance
                          )
                        : viewStore.prettifyNumber(
                            budget_calculation_values.applicant_expenses_calc_insurance
                          ) || "--"}
                    </span>
                  </TableData>
                </TableRow>
                <TableRow>
                  <TableData>
                    {t(
                      "step-7:components:BudgetCalculation:outgoings_calculated_taxes"
                    )}
                    <ToolTip
                      nameID={"outgoings_calculated_taxes"}
                      tooltip={t(
                        "step-7:components:BudgetCalculation:info_outgoings_calculated_taxes"
                      )}
                    />
                  </TableData>
                  <TableData smallView>{""}</TableData>
                  <TableData>
                    {viewStore.prettifyNumber(
                      budget_calculation_values.applicant_expenses_calc_tax
                    ) || "--"}
                  </TableData>
                </TableRow>
              </React.Fragment>
            )}
          </TableBody>
        </Table>

        {this.state.editQuestion && (
          <ModalPortal>
            <Overlay
              isOpen={this.state.showModal}
              id="editQuestion"
              onClose={(value) => this.closeEditQuestion(false)}
              title={""}
              classes="editQuestion"
            >
              <ModalEditQuestions
                {...{ editQuestion, id_key }}
                closeEditQuestion={(value) => this.closeEditQuestion(value)}
              />
            </Overlay>
          </ModalPortal>
        )}
      </React.Fragment>
    );
  }
}

BudgetTable.propTypes = {
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation(["step-7", "step-5", "step-6"])(
  inject(({ stores }) => ({
    rootStore: stores,
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(BudgetTable))
);
