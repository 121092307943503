import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation, Trans } from "react-i18next";
import { withRouter } from "react-router-dom";

import { email_validation_token } from "../../../api_clients/email_token_validation_service";
import { getValueByKey } from "../../../helpers/queryStringHelper";
import Layout from "../../styleguides/Styleguide-Layout";

class EmailConfirmationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailValid: null,
    };
    this.validateToken();
  }

  validateToken() {
    const { location } = this.props;

    const token = getValueByKey(location.search, "token");

    email_validation_token(token)
      .then((isEmailValid) => this.setState({ isEmailValid }))
      .catch(() => this.setState({ isEmailValid: false }));
  }

  render() {
    const { t } = this.props;

    if (this.state.isEmailValid === null) {
      // TODO make more beautiful, e.g. centered and with loader icon
      return <div>{t("loading_screen_message", { ns: "global" })}</div>;
    }

    const layout_props = {
      pageTitle: "email-confirmation:pageTitle",
    };

    return (
      <Layout data={layout_props}>
        {!this.state.isEmailValid && (
          <React.Fragment>
            <h1 className="a-page-title">
              <Trans
                i18nKey={
                  "email-confirmation:components.EmailConfirmationPage.title_confirmation_failed"
                }
              />
            </h1>
            <h2 className="a-text">
              {t(
                "email-confirmation:components.EmailConfirmationPage.subtitle_confirmation_failed"
              )}
            </h2>
            <p className="a-text a-text--dark-blue-large">
              <Trans
                i18nKey={
                  "email-confirmation:components.EmailConfirmationPage.description_confirmation_failed"
                }
              >
                # <a href="tel:+4144 439 54 06">#</a> #
              </Trans>
            </p>
            <p className="a-text a-text--dark-blue-large">
              <Trans
                i18nKey={
                  "email-confirmation:components.EmailConfirmationPage.greetings_confirmation"
                }
              />
            </p>
          </React.Fragment>
        )}
        {this.state.isEmailValid && (
          <React.Fragment>
            <h1 className="a-page-title">
              <Trans
                i18nKey={
                  "email-confirmation:components.EmailConfirmationPage.title_confirmation"
                }
              />
            </h1>
            <h2 className="a-text">
              {t(
                "email-confirmation:components.EmailConfirmationPage.subtitle_confirmation"
              )}
            </h2>
            <p className="a-text a-text--dark-blue-large">
              {t(
                "email-confirmation:components.EmailConfirmationPage.description_confirmation"
              )}
            </p>
            <p className="a-text a-text--dark-blue-large">
              <Trans
                i18nKey={
                  "email-confirmation:components.EmailConfirmationPage.greetings_confirmation"
                }
              />
            </p>
          </React.Fragment>
        )}
      </Layout>
    );
  }
}

EmailConfirmationPage.propTypes = {
  location: PropTypes.object,
  t: PropTypes.func,
};

export default withRouter(
  withTranslation(["email-confirmation", "global"])(
    observer(EmailConfirmationPage)
  )
);
