export function truncate_text(str, length = 21) {
  if (str.length >= length) return str.substring(0, length) + "...";
  else return str;
}

export function length(str) {
  if (str) return str.length;
  else return 0;
}

// MAYBE use i18nKey instead of label
// TODO rename function and move to data dir
export function extraIncomeEmploymentStatusValuesBudgetOverview(t) {
  return [
    {
      value: 1,
      label: t("step-5:components:ExtraIncomeLabels.employed"),
    },
    {
      value: 2,
      label: t("step-5:components:ExtraIncomeLabels.independent"),
    },
    {
      value: 3,
      label: t("step-5:components:ExtraIncomeLabels.alimony"),
    },
    {
      value: 4,
      label: t("step-5:components:ExtraIncomeLabels.retired"),
    },
    {
      value: 5,
      label: t("step-5:components:ExtraIncomeLabels.properties"),
    },
    {
      value: 6,
      label: t("step-5:components:ExtraIncomeLabels.apprentice_kids"),
    },
  ];
}

// MAYBE use i18nKey instead of label
// TODO rename function and move to data dir
export function extraIncomeEmploymentStatusValues(t) {
  return [
    {
      value: 1,
      label: t("step-5:components:QuestionExtraIncome.employed"),
    },
    {
      value: 2,
      label: t("step-5:components:QuestionExtraIncome.independent"),
    },
    {
      value: 3,
      label: t("step-5:components:QuestionExtraIncome.alimony"),
    },
    {
      value: 4,
      label: t("step-5:components:QuestionExtraIncome.retired"),
    },
    {
      value: 5,
      label: t("step-5:components:QuestionExtraIncome.properties"),
    },
    {
      value: 6,
      label: t("step-5:components:QuestionExtraIncome.apprentice_kids"),
    },
  ];
}
