import { observer, inject } from "mobx-react";
import { PropTypes } from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import ZipCity from "../../../shared/ZIP_city";
import InputName from "./InputName";
import InputWorkDuration from "./InputWorkDuration";

class QuestionEmployerInformation extends React.Component {
  constructor(props) {
    super(props);

    this.isPartner = props.isPartner ? true : false;
    this.content = this.isPartner
      ? "step-12:components:QuestionEmployerAddress:partner:"
      : "step-12:components.QuestionEmployerAddress:";

    this.type_user_zip = "address_zip_employer";
    this.type_user_city = "address_city_employer";

    if (this.isPartner) {
      this.type_user_zip = "address_zip_employer_partner";
      this.type_user_city = "address_city_employer_partner";
    }
  }

  update_postal(items) {
    const { userStore, viewStore } = this.props;

    if (this.isPartner) {
      userStore.setAddress(
        {
          zip: items.zip,
          city: items.city,
          zipAdditional: items.zipAdditional,
          canton: items.canton,
        },
        "employer_partner"
      );
    } else {
      userStore.setAddress(
        {
          zip: items.zip,
          city: items.city,
          zipAdditional: items.zipAdditional,
          canton: items.canton,
        },
        "employer"
      );
    }

    if (viewStore.should_step_12_validate && items.zip && items.city)
      viewStore.validateProfessionalDataForm();
  }

  render() {
    const { t, userStore, viewStore } = this.props;
    const data = {
      zip: userStore[this.type_user_zip],
      city: userStore[this.type_user_city],
      withSuggestService: true,
      should_step_validate: viewStore.should_step_12_validate,
      read_only: !this.isPartner && userStore.residenceOutOfSwitzerland,
      question_zip: t(this.content + "question_zip"),
      question_city: t(this.content + "question_city"),
      type_user_zip: this.type_user_zip,
      type_user_city: this.type_user_city,
      error_zip: t(this.content + "error_zip"),
    };

    return (
      <React.Fragment>
        <InputName {...this.props} />
        <ZipCity
          data={data}
          update_postal={(items) => this.update_postal(items)}
        />
        <InputWorkDuration {...this.props} />
      </React.Fragment>
    );
  }
}

QuestionEmployerInformation.propTypes = {
  userStore: PropTypes.object.isRequired,
  viewStore: PropTypes.object.isRequired,
};

export default withTranslation()(
  inject(({ stores }) => ({
    viewStore: stores.view,
    userStore: stores.user,
  }))(observer(QuestionEmployerInformation))
);
