import cn from "classnames";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import Dropzone from "react-dropzone";
import { withTranslation } from "react-i18next";

import FileStore from "../../../../stores/FileStore";
import ErrorItem from "./ErrorItem";
import FilePreview from "./FilePreview";
import SuccessItem from "./SuccessItem";
import UploadFields from "./UploadFields";

const Files = FileStore.files;

class QuestionUploadItem extends React.Component {
  render() {
    const { onFileAccepted, t, title } = this.props;
    const item = Files[this.props.id];

    const itemClasses = cn({
      "m-upload": true,
      "state-m-upload--selected": !!item.file,
      "state-m-upload--error": !!item.errors.length,
      "state-m-upload--uploaded": item.uploaded,
    });

    const dropzoneOptions = {
      accept: {
        "application/pdf": [],
        "image/jpeg": [],
        "image/png": [],
      },
      multiple: false,
      onDrop: (acceptedFiles) => {
        // only one file because of "multiple" false; WARN acceptedFiles
        // can be an empty array in case of drag & drop of a file of an
        // unsupported type
        item.addFileForUpload(acceptedFiles[0]);
        if (onFileAccepted) onFileAccepted();
      },
      style: { border: "none", position: "auto" },
    };

    return (
      <li className="m-upload-list__item">
        <div className={itemClasses}>
          <div className="m-upload__position-context">
            <div className="m-upload__position-use">
              <div className="m-upload__flex-title">
                <label className="m-upload__title">{title}</label>
              </div>
            </div>
          </div>
          {!item.file && (
            <Dropzone {...dropzoneOptions}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <UploadFields />
                </div>
              )}
            </Dropzone>
          )}
          {!item.uploaded && !item.errors.length && item.file && (
            <FilePreview file={item.file} removeFile={item.reset} />
          )}

          {/*ERROR STATE*/}
          {item.errors.length > 0 && (
            <ErrorItem
              file={item.file}
              errors={item.errors}
              removeFile={item.reset}
              {...{ t }}
            />
          )}

          {/* UPLOAD SUCCESS */}
          {item.uploaded && <SuccessItem file={item.file} />}
        </div>
      </li>
    );
  }
}

QuestionUploadItem.propTypes = {
  onFileAccepted: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default withTranslation("step-13")(observer(QuestionUploadItem));
