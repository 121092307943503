import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

class FormControlInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      hasFocus: !!props.value.length,
      label: props.label,
    };
    this.inputRef = !props.inputRef ? React.createRef() : props.inputRef;
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  };

  handleBlur = () => {
    this.setState({ hasFocus: false });
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  handleFocus = () => {
    this.setState({ hasFocus: true });
  };

  isEllipsisActive = (element) => {
    return element ? element.offsetWidth < element.scrollWidth : false;
  };

  render() {
    const {
      type,
      value,
      id,
      secondary,
      hint,
      inputState,
      list,
      invalid,
      autoComplete,
      fullWidth,
      isLast,
      hasFocus,
      isCustomerEmail,
    } = this.props;
    const controllerClassnames = cn({
      "a-input": true,
      "a-input--full-width": fullWidth || false,
      "a-input--secondary": secondary,
      "state-a-input--active":
        value.length > 0 || this.state.hasFocus || hasFocus,
      "state-a-input--error": inputState === "error" || invalid,
      "state-a-input--warn": inputState === "warn",
      "state-a-input--readonly": inputState === "readOnly",
    });
    const inputProps = {
      type: type || "text",
      ref: this.inputRef,
      id,
      className: "a-input__input",
      onChange: (e) => this.handleChange(e),
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      list,
      readOnly: inputState === "readOnly",
      value,
      autoComplete: autoComplete || "no-autocomplete",
      title: value,
    };
    const wrapperClassNames = cn({
      "a-input__wrapper": true,
      "a-input__wrapper-CustomerEmail": isCustomerEmail || false,
    });

    return (
      <React.Fragment>
        <div className={isLast ? "" : "a-input-container"}>
          <div className={controllerClassnames}>
            <div className={wrapperClassNames}>
              <input {...inputProps} />
              {this.state.label && (
                <label htmlFor={id} className="a-input__label">
                  {this.props.label}
                </label>
              )}
            </div>

            {hint && (
              <span
                className="a-input__hint"
                dangerouslySetInnerHTML={{ __html: hint }}
              />
            )}
          </div>
          {this.state.hasFocus &&
            inputProps.value &&
            this.isEllipsisActive(this.inputRef.current) && (
              <div className="a-input__preview">{inputProps.value}</div>
            )}
        </div>
      </React.Fragment>
    );
  }
}

FormControlInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputRef: PropTypes.func,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  hint: PropTypes.string,
  inputState: PropTypes.string,
  list: PropTypes.string,
  autoComplete: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default FormControlInput;
