import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { trackNavLink } from "../../../helpers/trackingHelper";

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/
const Footer = ({ t }) => (
  <footer className="l-foot">
    <div className="o-footer">
      <div className="o-footer__wrap">
        <p className="a-text">
          {t("global:footer.copyright_label", {
            year: new Date().getFullYear(),
          })}
        </p>
        <div className="push-right">
          <a
            className="a-link"
            target="_blank"
            href={t("global:footer.privacy_policy_link")}
            rel="noopener noreferrer"
            onClick={() => trackNavLink("footer_privacy_policy")}
          >
            {t("global:footer.privacy_policy")}
          </a>
          <a
            className="a-link"
            target="_blank"
            href={t("global:footer.cookies_link")}
            rel="noopener noreferrer"
            onClick={() => trackNavLink("footer_cookies")}
          >
            Cookies
          </a>
          <a
            className="a-link"
            target="_blank"
            href={t("global:footer.link_legal_info")}
            rel="noopener noreferrer"
            onClick={() => trackNavLink("footer_legal_information")}
          >
            {t("global:footer.legal_text")}
          </a>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(Footer);
