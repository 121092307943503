import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";

import { FormControlCheckbox } from "../../../styleguides/Styleguide-FormControl/index";
import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint/index";
import Overlay from "../../../styleguides/Styleguide-Overlay/index";
import ModalPortal from "../../../utils/ModalPortal/index";

class AccountZekTermsAndConditions extends Component {
  state = {
    showModal: false,
    lastFocus: null,
  };
  content = "step-12:components.AccountZekTermsAndConditions";

  handleOnClickLink = (event) => {
    event.preventDefault();
    this.setState({ showModal: true, lastFocus: document.activeElement });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
    this.state.lastFocus.focus();
  };

  openModal = (event) => {
    event.preventDefault();
    this.setState({ showModal: true, lastFocus: document.activeElement });
  };

  render() {
    const { viewStore, userStore, t } = this.props;
    const invalid =
      viewStore.should_step_12_validate &&
      !userStore.customerAcceptedZekTermsAndConditions;

    if (viewStore.should_step_12_validate && invalid === false) {
      viewStore.validateProfessionalDataForm();
    }

    return (
      <div className="l-step-item l-step-item--m">
        <FormControlCheckbox
          id="acceptZEKTermsAndConditions"
          name="oos"
          onChange={(e) =>
            userStore.setAcceptanceAccountZekTermsAndConditions(
              e.currentTarget.checked
            )
          }
          defaultChecked={userStore.customerAcceptedZekTermsAndConditions}
          labelContent={
            <span>
              <Trans i18nKey={this.content + ".description"}>
                #
                <a href="#ignore" onClick={this.openModal}>
                  #
                </a>
                #
              </Trans>
            </span>
          }
          invalid={invalid}
        />
        {invalid && (
          <FormValidationHint>{t(this.content + ".hint")}</FormValidationHint>
        )}

        {this.state.showModal && (
          <ModalPortal>
            <Overlay
              isOpen={this.state.showModal}
              id="acceptZEKTermsAndConditions"
              onClose={this.handleCloseModal}
              title={t(this.content + ".disclaimerTitle")}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: t(this.content + ".disclaimer"),
                }}
              />
            </Overlay>
          </ModalPortal>
        )}
      </div>
    );
  }
}

AccountZekTermsAndConditions.propTypes = {
  t: PropTypes.func.isRequired,
  userStore: PropTypes.object.isRequired,
  viewStore: PropTypes.object.isRequired,
};

export default withTranslation("step-12")(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(AccountZekTermsAndConditions))
);
