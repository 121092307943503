import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import FormControl from "../../../../styleguides/Styleguide-FormControl/index";

class QuestionExtraIncome extends React.Component {
  constructor(props) {
    super(props);

    const { id_key, isPartner, userStore } = props;

    this.state = {
      extraIncomeAmount:
        userStore.getExtraIncomeAmount(id_key, isPartner) || "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.content = isPartner
      ? "step-5:components.QuestionNetIncome.partner"
      : "step-5:components.QuestionNetIncome";
    this.type_user = isPartner ? "extraIncomePartner" : "extraIncome";
  }

  handleChange(value) {
    const { userStore, viewStore, id_key, isPartner } = this.props;
    value = viewStore.validate_number(value);

    if (value !== "err") {
      this.setState({ extraIncomeAmount: value });
      userStore.setExtraIncomeAmount(id_key, value, isPartner);
    }
  }

  render() {
    let inputState, inputHint;
    const { t, viewStore, userStore, isPartner, id_key } = this.props;

    if (
      !isPartner &&
      viewStore.shouldCustomerIncomeFormValidate &&
      userStore.hasExtraIncome &&
      (userStore.extraIncome.length === 0 ||
        userStore.extraIncome[id_key] === 0 ||
        !userStore.extraIncome[id_key])
    ) {
      inputState = "error";
      inputHint = t("step-5:components:QuestionExtraIncome:hint");
    } else if (
      isPartner &&
      viewStore.shouldCustomerIncomeFormValidate &&
      userStore.hasExtraIncomePartner &&
      (userStore.extraIncomePartner.length === 0 ||
        userStore.extraIncomePartner[id_key] === 0 ||
        !userStore.extraIncomePartner[id_key])
    ) {
      inputState = "error";
      inputHint = t("step-5:components:QuestionExtraIncome:hint");
    }

    if (viewStore.shouldCustomerIncomeFormValidate && inputState !== "error") {
      viewStore.validateCustomerIncomeForm();
    }

    const titleClasses = cn({
      "m-info-block__pattern": true,
      "m-info-block__title-error": !!inputState,
    });

    return (
      <div className="m-info-block">
        <div className={titleClasses}>
          <FormControl
            type="tel"
            label={t("global:labels.CHF_month")}
            id={this.type_user + this.props.id_key}
            onChange={(value) => this.handleChange(value)}
            inputState={inputState}
            hint={inputHint}
            value={viewStore.prettifyNumber(this.state.extraIncomeAmount)}
            isLast={true}
            hasFocus={this.state.extraIncomeAmount !== ""}
          />
        </div>
      </div>
    );
  }
}

QuestionExtraIncome.propTypes = {
  id_key: PropTypes.number.isRequired,
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation("step-5")(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionExtraIncome))
);
