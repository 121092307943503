import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Select, { createFilter, StylesConfig } from "react-select";

import { IPAD_LANDSCAPE_MAX_WIDTH_PX } from "../../../lib/Constants";

const customStyles = {
  control: (base) => ({
    ...base,
    width: "auto",
    backgroundColor: "#ffffff",
    minWidth: "100px",
  }),
  option: (base) => ({
    ...base,
  }),
  // input value
  input: (base) => ({
    ...base,
    color: "#004d8c",
  }),
  // selected value
  singleValue: (base) => ({
    ...base,
    color: "#004d8c",
  }),
  menuList: (base) => ({
    ...base,
    color: "#004d8c",
  }),
  dropdownIndicator: (base) => ({
    ...base,
  }),
};

// Always start searching from start
const filterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFrom: "start",
};

const FormControlSelect = (props) => {
  const { t } = useTranslation("global");

  const _props = Object.assign(
    {},
    {
      styles: customStyles,
      className: "react-select-container",
      classNamePrefix: "react-select",
    },
    props
  );
  let wrapperClassNames = `a-dropdown ${props.wrapperClassNames || ""}`;

  if (props.invalid) {
    wrapperClassNames = wrapperClassNames + " a-dropdown-error";
  }

  const scrollSelectIntoView = () => {
    if (
      document !== undefined &&
      document.getElementsByTagName("body")[0].offsetWidth <=
        IPAD_LANDSCAPE_MAX_WIDTH_PX
    ) {
      props.parentBlockRef &&
        props.parentBlockRef.current &&
        props.parentBlockRef.current.scrollIntoView();
    }
  };

  const handleFocus = (event) => {
    if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
      if (event.keyCode === 13) {
        props.parentBlockRef.current.focus();
      } else if (event.keyCode === 9) {
        document.activeElement.parentElement.nextSibling.focus();
      }
    }
  };
  return (
    <div className={wrapperClassNames}>
      <Select
        {..._props}
        filterOption={createFilter(filterConfig)}
        onMenuOpen={() => scrollSelectIntoView()}
        onKeyDown={handleFocus}
        noOptionsMessage={() => t("labels.no_options")}
      />
    </div>
  );
};

FormControlSelect.propTypes = {
  parentBlockRef: PropTypes.object,
  wrapperClassNames: PropTypes.string,
};

export default FormControlSelect;
