import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import RadioButtonGroup from "../../../styleguides/Styleguide-RadioButtonGroup/index";

class PermitIdButtonGroup extends React.Component {
  render() {
    const residenceFields = {
      radioGroupName: this.props.isPartner
        ? "permit_type_partner"
        : "permit_type",
      list: [
        {
          value: "B",
          label: "B",
        },
        {
          value: "C",
          label: "C",
        },
      ],
    };

    return (
      <RadioButtonGroup
        doNotTrack={this.props.isPartner}
        fields={residenceFields}
        invalid={this.props.invalid}
        onChange={this.props.onChange}
        selectedValue={this.props.selectedValue}
      />
    );
  }
}

PermitIdButtonGroup.propTypes = {
  isPartner: PropTypes.bool,
  onChange: PropTypes.func,
  selectedValue: PropTypes.string,
};

export default withTranslation()(PermitIdButtonGroup);
