import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { ExpensesAdditionalCosts } from "../../../../lib/Constants";
import FormControl from "../../../styleguides/Styleguide-FormControl";
import Tooltip from "../../../styleguides/Styleguide-Tooltip";

class QuestionAdditionalExpensesCosts extends React.Component {
  constructor(props) {
    super(props);

    this.type_user = props.isPartner
      ? "expenses_costs_additionalPartner"
      : "expenses_costs_additional";

    this.handleChange = this.handleChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);

    this.validate = false;
  }

  handleChange(value) {
    const { userStore, viewStore, isPartner } = this.props;
    value = viewStore.validate_number(value);
    if (value !== "err")
      userStore.setExpenses_costs_additional(value, isPartner);

    this.validate = true;
  }

  handleOnBlur() {}

  render() {
    const { t, viewStore, userStore, isPartner } = this.props;
    let inputState, inputHint;

    if (viewStore.shouldCustomerExpensesFormValidate || this.validate) {
      const value = userStore[this.type_user];

      if (value == null) {
        inputState = "error";
        inputHint = isPartner
          ? t(
              "step-6:components:QuestionAdditionalExpensesCosts:input_error_partner"
            )
          : t("step-6:components:QuestionAdditionalExpensesCosts:input_error");
      } else if (value > ExpensesAdditionalCosts.max) {
        inputState = "error";
        inputHint = t(
          "step-6:components:QuestionAdditionalExpensesCosts:max_error"
        );
      } else if (value > ExpensesAdditionalCosts.plausibility) {
        inputState = "warn";
        inputHint = t(
          "step-6:components:QuestionAdditionalExpensesCosts:plausibility_error"
        );
      }

      if (
        inputState !== "error" &&
        viewStore.shouldCustomerExpensesFormValidate
      ) {
        viewStore.validateCustomerExpensesForm();
      }
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": inputState === "error",
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <span className={titleClasses}>
              {isPartner
                ? t(
                    "step-6:components:QuestionAdditionalExpensesCosts:questionPartner"
                  )
                : t(
                    "step-6:components:QuestionAdditionalExpensesCosts:question"
                  )}
            </span>
            {!this.props.isInModal && (
              <Tooltip
                nameID={"expenses_additional"}
                tooltip={t(
                  "step-6:components:QuestionAdditionalExpensesCosts:tooltip"
                )}
              />
            )}
          </div>
          <span className="m-info-block__lead">
            {t("step-6:components:QuestionAdditionalExpensesCosts:description")}
          </span>
          <div className="m-info-block__pattern">
            <FormControl
              type="tel"
              label={t("global:labels.CHF_month")}
              id={this.type_user}
              onChange={this.handleChange}
              onBlur={this.handleOnBlur}
              inputState={inputState}
              hint={inputHint}
              value={viewStore.prettifyNumber(userStore[this.type_user])}
              isLast={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

QuestionAdditionalExpensesCosts.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionAdditionalExpensesCosts))
);
