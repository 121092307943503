import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { ExpensesWorkingCosts as expensesLimits } from "../../../../lib/Constants";
import FormControl from "../../../styleguides/Styleguide-FormControl";
import Tooltip from "../../../styleguides/Styleguide-Tooltip";

class QuestionWorkExpensesCosts extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);

    this.validate = false;
  }

  handleChange(value) {
    const { userStore, viewStore } = this.props;
    value = viewStore.validate_number(value);

    if (value !== "err") userStore.setExpenses_costs_work(value);

    this.validate = true;
  }

  handleOnBlur() {}

  render() {
    const { t, viewStore, userStore } = this.props;

    // Validate this optional field when global form should validate
    let inputState, inputHint;

    if (viewStore.shouldCustomerExpensesFormValidate || this.validate) {
      let value = userStore.expenses_costs_work;

      if (value == null) {
        inputState = "error";
        inputHint = t("step-6:components:QuestionWorkExpensesCosts:max_error");
      } else if (value > expensesLimits.max) {
        inputState = "error";
        inputHint = t("step-6:components:QuestionWorkExpensesCosts:max_error");
      } else if (value > expensesLimits.plausibility) {
        inputState = "warn";
        inputHint = t(
          "step-6:components:QuestionWorkExpensesCosts:plausibility_error"
        );
      }

      if (
        inputState !== "error" &&
        viewStore.shouldCustomerExpensesFormValidate
      ) {
        viewStore.validateCustomerExpensesForm();
      }
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": inputState === "error",
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <span className={titleClasses}>
              {t("step-6:components:QuestionWorkExpensesCosts:question")}
            </span>
            {!this.props.isInModal && (
              <Tooltip
                nameID={"expenses_work"}
                tooltip={t(
                  "step-6:components:QuestionWorkExpensesCosts:tooltip"
                )}
              />
            )}
          </div>
          <div className="m-info-block__pattern">
            <FormControl
              type="tel"
              label={t("global:labels.CHF_month")}
              id={"workexpenses"}
              onChange={this.handleChange}
              onBlur={this.handleOnBlur}
              inputState={inputState}
              hint={inputHint}
              value={viewStore.prettifyNumber(userStore.expenses_costs_work)}
              isLast={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

QuestionWorkExpensesCosts.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionWorkExpensesCosts))
);
