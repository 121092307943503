import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { trackStep } from "../../../helpers/trackingHelper";
import { ROUTENAME_STEP_5, ROUTENAME_STEP_7 } from "../../../lib/Constants";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWrapper,
} from "../../styleguides/Styleguide-Button";
import FormValidationHint from "../../styleguides/Styleguide-FormValidationHint";
import Layout from "../../styleguides/Styleguide-Layout";
import SVGIcon from "../../styleguides/Styleguide-SVGIcon";
import QuestionAdditionalExpensesCosts from "./QuestionAdditionalExpensesCosts";
import QuestionAlimonyCosts from "./QuestionAlimonyCosts";
import QuestionExternalFoodCosts from "./QuestionFoodCosts";
import QuestionHousingCosts from "./QuestionHousingCosts";
import QuestionMonthlyChildCareCosts from "./QuestionMonthlyChildCareCosts";
import QuestionOtherDebtsCosts from "./QuestionOtherDebtsCosts";
import QuestionWorkExpensesCosts from "./QuestionWorkExpensesCosts";

class CustomerExpenses extends React.Component {
  constructor(props) {
    super(props);

    this.validateAndSendData = this.validateAndSendData.bind(this);
  }

  componentDidMount() {
    const { viewStore, history } = this.props;

    if (
      !(
        viewStore.get_step_validated() < 12 &&
        viewStore.get_step_validated() >= 5
      )
    ) {
      history.push(viewStore.getLocalizedPath(ROUTENAME_STEP_5));
    }
    viewStore.customerExpensesFormShouldValidate(false);
    i18next.loadNamespaces("step-7");
  }

  validateAndSendData(event) {
    const { viewStore } = this.props;
    // Check if all required data are filled out in this step (only the viewStore knows that!)
    if (!viewStore.validateCustomerExpensesForm()) {
      event.preventDefault();
      //Activate error messages in each component that is connected to this step
      viewStore.customerExpensesFormShouldValidate(true);
    } else {
      viewStore.save_session(6);
      trackStep(5);
    }
  }

  render() {
    const { t, viewStore, userStore } = this.props;
    let formState = true;

    if (
      viewStore.shouldCustomerExpensesFormValidate &&
      !viewStore.validateCustomerExpensesForm()
    ) {
      formState = false;
    }

    const layout_props = {
      pageTitle: "step-6:pageTitle", // passing only the key, it's translated within Layout component
      headlineKey: "step-6:headline", // passing only the key, it's translated within Layout Trans component
      inverted_background: true,
      progressBar: true,
    };

    return (
      <Layout data={layout_props}>
        <div className="l-step-group">
          <QuestionHousingCosts />
          <QuestionWorkExpensesCosts />
          <QuestionExternalFoodCosts />
          <QuestionAlimonyCosts isPartner={false} />
          {userStore.hasChildrenUnder25 && <QuestionMonthlyChildCareCosts />}
          <QuestionOtherDebtsCosts />
          <QuestionAdditionalExpensesCosts isPartner={false} />
          {userStore.isEditingPartnerInfo() && (
            <React.Fragment>
              <h2 className="a-text a-text--subtitle">
                {t("step-6:components:CustomerExpenses:sub_headline")}
              </h2>
              <QuestionAlimonyCosts isPartner={true} />
              <QuestionAdditionalExpensesCosts isPartner={true} />
            </React.Fragment>
          )}
          {!formState && (
            <FormValidationHint>
              {t("global:labels.hint_step")}
            </FormValidationHint>
          )}
          <ButtonWrapper>
            <ButtonPrimary
              to={viewStore.getLocalizedPath(ROUTENAME_STEP_7)}
              onClick={this.validateAndSendData}
            >
              <span className="a-nexus__text">
                {t("step-6:next_button_label")}
              </span>
            </ButtonPrimary>
            <ButtonSecondary to={viewStore.getLocalizedPath(ROUTENAME_STEP_5)}>
              <span className="a-nexus__icon-left">
                <SVGIcon icon="back-arrow" />
              </span>
              <span className="a-nexus__text">
                {t("global:labels:back_button")}
              </span>
            </ButtonSecondary>
          </ButtonWrapper>
        </div>
      </Layout>
    );
  }
}

CustomerExpenses.propTypes = {
  t: PropTypes.func,
  viewStore: PropTypes.object,
  userStore: PropTypes.object,
};

export default withRouter(
  withTranslation("step-6")(
    inject(({ stores }) => ({
      viewStore: stores.view,
      userStore: stores.user,
    }))(observer(CustomerExpenses))
  )
);
