import PropTypes from "prop-types";
import React from "react";

const TableHeadItem = ({ children }) => <th>{children}</th>;

TableHeadItem.propTypes = {
  children: PropTypes.any,
};

export default TableHeadItem;
