import PropTypes from "prop-types";
import React from "react";

import iconsSvg from "../../../assets/media/icons.svg";

const SVGIcon = ({ icon }) => {
  const href = `${iconsSvg}#${icon}`;
  // Do not render on serverside since xlinkHref throws errors in <= IE 11
  if (typeof window === "undefined") {
    return null;
  }
  return (
    <svg className="a-icon" xmlns="http://www.w3.org/2000/svg" role="img">
      <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={href} />
    </svg>
  );
};

SVGIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default SVGIcon;
