import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { list_income_frequency } from "../../../../../lib/Constants";
import FormValidationHint from "../../../../styleguides/Styleguide-FormValidationHint/index";
import RadioButtonGroup from "../../../../styleguides/Styleguide-RadioButtonGroup/index";

class QuestionNetIncomeFrequency extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.type_user = props.isPartner
      ? "incomeFrequencyPartner"
      : "incomeFrequency";
    this.content = props.isPartner
      ? "step-5:components.QuestionNetIncomeFrequency.partner"
      : "step-5:components.QuestionNetIncomeFrequency";
    this.content_both = "step-5:components.QuestionNetIncomeFrequency";
  }

  handleChange(event) {
    const { isPartner, userStore } = this.props;
    const value = Number(event.currentTarget.value);
    userStore.setIncomeFrequency(value, isPartner);
  }

  render() {
    const { userStore, t, viewStore, isPartner } = this.props;
    const invalid =
      viewStore.shouldCustomerIncomeFormValidate &&
      userStore[this.type_user] === null;

    if (viewStore.shouldCustomerIncomeFormValidate && invalid === false) {
      viewStore.validateCustomerIncomeForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid,
    });

    const fields = {
      radioGroupName: `income_frequency${isPartner ? "_partner" : ""}`,
      list: list_income_frequency,
    };

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <label className={titleClasses}>
              {t(this.content + ".question")}
            </label>
          </div>
          {t(this.content + ":hint_frequency") && (
            <span className="m-info-block__lead">
              {t(this.content + ":hint_frequency")}
            </span>
          )}
          <div className="m-info-block__pattern">
            <RadioButtonGroup
              doNotTrack={isPartner}
              {...{ fields }}
              onChange={this.handleChange}
              selectedValue={userStore[this.type_user]}
              invalid={invalid}
            />

            {invalid && (
              <FormValidationHint>
                {t(this.content + ".hint")}
              </FormValidationHint>
            )}
          </div>
        </div>
      </div>
    );
  }
}

QuestionNetIncomeFrequency.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation("step-5")(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionNetIncomeFrequency))
);
