//import 'core-js';
//import 'whatwg-fetch';
//import 'element-closest';
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import svg4everybody from "svg4everybody";

import App from "./App";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";

svg4everybody({});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

/**
 * Load cookie banner. This can only be done after i18n was initialized,
 * because we need to know selected language to load banner with.
 */
i18n.on("initialized", () => {
  const ot = {
    id: "c8560af5-5a67-4e80-b7b4-cda364e7af5d",
    src: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
  };

  var hn = document.location ? document.location.hostname : "";
  if (hn && (hn.includes("localhost") || hn.includes("sit"))) {
    ot.id += "-test";
  }

  const script = document.createElement("script");
  script.setAttribute("charset", "UTF-8");
  script.setAttribute("data-language", i18n.resolvedLanguage);
  script.setAttribute("data-domain-script", ot.id);
  script.setAttribute("src", ot.src);
  script.setAttribute("type", "text/javascript");
  document.head.appendChild(script);

  window.OptanonWrapper = () => {
    /**
     * Logs to console on localhost and SIT environments.
     * @param {Array<Object>} data
     * @returns {void}
     */
    const debug =
      document.location &&
      document.location.hostname &&
      /^localhost|sit\d?\./.test(document.location.hostname)
        ? (data) => {
            console.debug(data);
          }
        : () => {};

    /**
     * @returns {string} CSV of previously active group ids or all groups if
     *    value isn't set.
     */
    const getOtCstActiveGroups = () =>
      window.otCstActiveGroups ||
      window.Optanon.GetDomainData()
        .Groups.map((g) => g.OptanonGroupId)
        .join();
    /**
     * Sets currently active group ids.
     * @param {string} value CSV of active group ids.
     * @returns {void}
     */
    const setOtCstActiveGroups = (value) => {
      window.otCstActiveGroups = value;
    };

    /**
     * @returns {Array<string>} Names of visible (1st party non-"HTTP only")
     *    cookies.
     */
    const getNamesOfVisibleCookies = () =>
      document.cookie.split("; ").map((c) => c.split("=")[0]);

    /**
     * Deletes all 1st party non-"HTTP only" cookies of categories user rejected
     * consent for.
     */
    const delete1stPartyCookies = () => {
      const groups = JSON.parse(
        JSON.stringify(window.Optanon.GetDomainData().Groups)
      );
      const rejectedGroupIds = getRejectedGroupIds(
        getOtCstActiveGroups(),
        window.OnetrustActiveGroups
      );
      if (rejectedGroupIds.length > 0) {
        const names = getNamesOfVisibleCookies();
        // allow quick check if 1st party cookie exists
        const namesSet = new Set(names);
        for (const group of groups) {
          if (
            group["CustomGroupId"] !== "" &&
            rejectedGroupIds.includes(group["CustomGroupId"])
          ) {
            // only use 1st party cookies to avoid that 1st party cookie is
            // deleted by 3rd party cookie with same name, e.g. JSESSIONID
            // strictly necessary session cookie
            for (const firstPartyCookie of group["FirstPartyCookies"]) {
              const name = firstPartyCookie["Name"];
              const isMaskedName = /^(.+?)x{2,}$/.exec(name);
              if (isMaskedName) {
                const regExp = new RegExp(`^${isMaskedName[1]}.{2,}$`);
                const matches = names.filter((n) => regExp.test(n));
                for (const match of matches) delete1stPartyCookie(match);
              }
              // avoid unnecesary deletions in the dark
              else if (namesSet.has(name)) {
                delete1stPartyCookie(name);
              }
            }
          }
        }
      }
      // cache current active groups to speed up deletion in case user rejects
      // further categories in future
      setOtCstActiveGroups(window.OnetrustActiveGroups);
    };

    /**
     * @param {string} oldGroups
     * @param {string} newGroups
     * @returns {Array<string>} Ids of groups / categories user rejected.
     */
    const getRejectedGroupIds = (oldGroups, newGroups) => {
      const oldGroupIds = oldGroups.split(",").filter(Boolean);
      const newGroupIds = newGroups.split(",").filter(Boolean);

      var result = [];
      for (var i = 0; i < oldGroupIds.length; i++) {
        const groupId = oldGroupIds[i];
        if (newGroupIds.indexOf(groupId) <= -1) {
          result.push(groupId);
        }
      }
      return result;
    };

    /**
     * Deletes a visible cookie (1st party, non-"HTML only") from current domain
     * as well all subdomains up to root domain.
     * @param {string} name
     * @returns {void}
     */
    const delete1stPartyCookie = (name) => {
      // necessary to delete cookies that have been added without domain; such
      // cookies are bound to the domain without leading "." and can only be
      // "deleted" without domain specification
      let deleteStr = `${name}=; path=/; max-age=0;`;
      document.cookie = deleteStr;
      debug(`[OTW] Delete cookie '${deleteStr}'`);

      // x.y.z.cembra.ch => ch, cembra, uat, z, y, x
      const [root, ...subdomains] = window.location.hostname
        .split(".")
        .reverse();
      let domain = root; // also support localhost
      if (root !== "localhost" && subdomains.length > 0) {
        domain = `${subdomains.shift()}.${root}`;
      }
      for (let i = 0; i <= subdomains.length; i++) {
        deleteStr = `${name}=; path=/; max-age=0; domain=${domain};`;
        document.cookie = deleteStr;
        debug(`[OTW] Delete cookie '${deleteStr}'`);
        if (subdomains[i]) domain = `${subdomains[i]}.${domain}`;
      }
    };

    const nowMs = Date.now();
    delete1stPartyCookies();
    debug(`[OTW] ${Date.now() - nowMs}ms`);
  };

  window.OptanonWrapper.version = "1.2.1-f3245e4";
});

i18n.on("languageChanged", (lng) => {
  console.debug("Language changed");
  // update cookie banner language after change
  if (window.OneTrust) {
    console.debug(`Change OT language to '${lng}'`);
    window.OneTrust.changeLanguage(lng);
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// TODO check what this is about?
reportWebVitals();
