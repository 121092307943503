import { Slider } from "antd";
import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import * as numberHelper from "../../../helpers/numberHelper";
import {
  trackAmount,
  trackStep,
  trackTerm,
} from "../../../helpers/trackingHelper";
import * as c from "../../../lib/Constants";
import { ButtonPrimary } from "../../styleguides/Styleguide-Button";
import FormControl from "../../styleguides/Styleguide-FormControl";
import Layout from "../../styleguides/Styleguide-Layout";

class LoanAmount extends React.Component {
  constructor(props) {
    super(props);

    const amount = this.props.loanStore.loanAmountValue;

    this.state = {
      amount,
      validate: false,
      showToolTip: false,
    };

    this.content = "step-1:components.LoanAmount";
  }

  componentDidMount() {
    const { viewStore } = this.props;

    const queryAmount = viewStore.getSearchParamValue(
      c.SEARCHPARAM_LOAN_AMOUNT
    );
    if (queryAmount) this.storeAmount(queryAmount);

    const queryTerm = viewStore.getSearchParamValue(c.SEARCHPARAM_LOAN_TERM);
    if (queryTerm) this.storeTerm(queryTerm);

    i18next.loadNamespaces("step-3"); // TODO use const

    const toolTipTimeout = setTimeout(() => {
      this.setState({ showToolTip: true });
    }, 300);
    this.setState({ toolTipTimeout });
  }

  componentWillUnmount() {
    clearTimeout(this.state.toolTipTimeout);
  }

  saveSessionAndGoToNextStep = (event) => {
    event.preventDefault();
    const { history, viewStore } = this.props;
    trackStep(1);

    viewStore.save_session(1);

    history.push(viewStore.getLocalizedPath(c.ROUTENAME_STEP_3));
  };

  onChangeAmount = (amount) => {
    let a = numberHelper.toNumber(amount);
    if (!isNaN(a) && a > c.loan_default_values.max_loan_value) {
      a = c.loan_default_values.max_loan_value;
    }
    this.setState({ amount: a });
  };

  storeAmount = (amount) => {
    try {
      this.props.loanStore.setLoanAmount(amount);
      trackAmount(amount);
    } catch (e) {
      // empty catch required; MAYBE: track error
    } finally {
      this.setState({ amount: this.props.loanStore.loanAmountValue });
    }
  };

  storeTerm = (term) => {
    try {
      this.props.loanStore.setLoanTerm(term);
    } catch (e) {
      // MAYBE: track error
    }
  };

  storeTermByIdx = (termIdx) => {
    try {
      const idx = Math.min(Math.max(0, termIdx), c.terms.length - 1);
      const term = this.props.loanStore.setLoanTermByIdx(idx);
      trackTerm(term);
    } catch (e) {
      // MAYBE: track error
    }
  };

  formatMonthlyMaxRateRoundTo5CentHtml() {
    const { t, viewStore } = this.props;
    return t(`${this.content}.tileText`, {
      monthlyRate: viewStore.prettifyNumber_html(
        this.props.loanStore.monthlyMaxRateRoundTo5Cent()
      ),
    });
  }

  render() {
    const { loanStore, t, viewStore } = this.props;

    const marks_loan = {
      [c.loan_default_values.min_loan_value]: {
        label: viewStore.prettifyNumber(c.loan_default_values.min_loan_value),
      },
      [c.loan_default_values.max_loan_value]: {
        label: viewStore.prettifyNumber(c.loan_default_values.max_loan_value),
      },
    };

    const marks_term = c.terms.reduce((acc, cur, idx) => {
      acc[idx] = { style: { color: "#000" }, label: cur };
      return acc;
    }, {});

    const layout_props = {
      pageTitle: "step-1:pageTitle", // passing only the key, it's translated within Layout component
      headlineKey: "step-1:headline", // passing only the key, it's translated within Layout Trans component
      inverted_background: true,
      metaDescriptionKey: "step-1:metaDescription",
      progressBar: true,
    };

    return (
      <Layout data={layout_props}>
        <div style={{ paddingBottom: "30px" }}>
          <div>
            <br></br>
            <div className="line">
              <h2 className="a-text a-text--subtitle">
                {t("step-1:components.LoanAmount.amount_title")}
              </h2>
              <div className="m-info-block__pattern">
                <FormControl
                  type="tel"
                  tabindex="-1"
                  autofocus="true"
                  z-index="0"
                  id="loan_amount"
                  onBlur={() => this.storeAmount(this.state.amount)}
                  onChange={this.onChangeAmount}
                  value={viewStore.prettifyNumber(this.state.amount)}
                  isLast={false}
                />
              </div>
            </div>

            <div className="slider_container-1">
              <Slider
                marks={marks_loan}
                step={1000}
                tipFormatter={viewStore.prettifyNumber}
                value={loanStore.loanAmountValue}
                onChange={(v) => this.storeAmount(v)}
                min={c.loan_default_values.min_loan_value}
                max={c.loan_default_values.max_loan_value}
              />
            </div>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>

          <div className="line">
            <h2 className="a-text a-text--subtitle">
              {t("step-1:components.LoanAmount.term_title")}
            </h2>
            <div className="term_container">
              <div
                className="term_button term_left"
                onClick={() =>
                  this.storeTermByIdx(this.props.loanStore.loanTermIdx - 1)
                }
              >
                &lt;
                <span className="split"></span>
              </div>
              <div className="term_center">{this.props.loanStore.loanTerm}</div>
              <div
                className="term_button term_right"
                onClick={() =>
                  this.storeTermByIdx(this.props.loanStore.loanTermIdx + 1)
                }
              >
                <span className="split"></span>
                &gt;
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <div className="slider_container-2">
            <Slider
              className="slider1"
              marks={marks_term}
              value={this.props.loanStore.loanTermIdx}
              onChange={(e) => this.storeTermByIdx(e)}
              step={null}
              min={0}
              defaultValue={6}
              max={c.terms.length - 1}
              tooltipVisible={this.state.showToolTip}
              tooltipPrefixCls="tooltip_term slider-tooltip ant-tooltip"
              tipFormatter={() => this.formatMonthlyMaxRateRoundTo5CentHtml()}
            />
          </div>
          <div className="container-monthly">
            <div>
              <img
                className="monthly-triangle"
                src="files/arrow-down-triangle.svg"
                alt="Arrow down"
              />
              <div>
                max{" "}
                {viewStore.prettifyNumber(
                  this.props.loanStore.monthlyMaxRateRoundTo5Cent(c.terms[0])
                )}
              </div>
            </div>

            <div>
              <img
                className="monthly-triangle triangle-right"
                src="files/arrow-down-triangle.svg"
                alt="Arrow down"
              />
              <div>
                max{" "}
                {viewStore.prettifyNumber(
                  this.props.loanStore.monthlyMaxRateRoundTo5Cent(
                    c.terms[c.terms.length - 1]
                  )
                )}
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <div className="summery_box">
            <span
              className="a-text"
              dangerouslySetInnerHTML={{
                __html: t(`${this.content}.summery_content`, {
                  loan_amount: viewStore.prettifyNumber_html(
                    loanStore.loanAmountValue
                  ),
                  max_credit_costs: viewStore.prettifyNumber_html(
                    loanStore.totalMaxCreditCosts(),
                    2,
                    2
                  ),
                  interest_rate: c.loan_default_values.max_interest_rate,
                  monthly_term: this.props.loanStore.loanTerm,
                }),
              }}
            />
          </div>

          <div className="l-buttons step1-button">
            <ButtonPrimary
              to={viewStore.getLocalizedPath(c.ROUTENAME_STEP_3)}
              onClick={this.saveSessionAndGoToNextStep}
            >
              <span className="a-nexus__text">{t("step-1:next_button")}</span>
            </ButtonPrimary>
          </div>

          <p className="a-text">{t("step-1:components.LoanAmount.text-1")}</p>
        </div>
      </Layout>
    );
  }
}

LoanAmount.propTypes = {
  history: PropTypes.object,
  loanStore: PropTypes.object,
  t: PropTypes.func,
  viewStore: PropTypes.object,
};

export default withRouter(
  withTranslation(["step-1"])(
    inject(({ stores }) => ({
      loanStore: stores.loan,
      viewStore: stores.view,
    }))(observer(LoanAmount))
  )
);
