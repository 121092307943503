import PropTypes from "prop-types";
import React from "react";

export default class FormGroup extends React.Component {
  render() {
    return (
      <div className="form-group" {...this.props}>
        {this.props.children}
      </div>
    );
  }
}

FormGroup.propTypes = {
  children: PropTypes.node,
};
