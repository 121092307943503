import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { list_extra_income_frequency } from "../../../../../lib/Constants";
import FormValidationHint from "../../../../styleguides/Styleguide-FormValidationHint/index";
import RadioButtonGroup from "../../../../styleguides/Styleguide-RadioButtonGroup/index";

class QuestionExtraIncomeFrequency extends React.Component {
  constructor(props) {
    super(props);
    this.type_user = props.isPartner
      ? "extra_income_frequency_partner"
      : "extra_income_frequency";
    this.content = props.isPartner
      ? "step-5:components.QuestionNetIncomeFrequency.partner"
      : "step-5:components.QuestionNetIncomeFrequency";
  }

  render() {
    const { userStore, t, viewStore, id_key, isPartner } = this.props;
    const invalid =
      !isPartner &&
      viewStore.shouldCustomerIncomeFormValidate &&
      userStore.hasExtraIncome &&
      userStore.getExtraIncomeSource(id_key, isPartner) === 1 &&
      !userStore.getExtraIncomeFrequency(id_key, isPartner);
    const invalidPartner =
      isPartner &&
      viewStore.shouldCustomerIncomeFormValidate &&
      userStore.hasExtraIncomePartner &&
      userStore.getExtraIncomeSource(id_key, isPartner) === 1 &&
      !userStore.getExtraIncomeFrequency(id_key, isPartner);

    const fields = {
      radioGroupName: this.type_user + id_key,
      list: list_extra_income_frequency,
    };

    if (viewStore.shouldCustomerIncomeFormValidate && invalid === false)
      viewStore.validateCustomerIncomeForm();

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": isPartner ? invalidPartner : invalid,
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <span className={titleClasses}>
            {t(this.content + ".questionExtra")}
          </span>

          <div className="m-info-block__pattern">
            <RadioButtonGroup
              doNotTrack={isPartner}
              {...{ fields }}
              onChange={(event) => {
                userStore.setExtraIncomeFrequency(
                  id_key,
                  Number(event.currentTarget.value),
                  isPartner
                );
              }}
              selectedValue={userStore.getExtraIncomeFrequency(
                id_key,
                isPartner
              )}
              invalid={isPartner ? invalidPartner : invalid}
            />

            {isPartner && invalidPartner && (
              <FormValidationHint>
                {t(
                  "step-5:components.QuestionNetIncomeFrequency.partner.hintExtra"
                )}
              </FormValidationHint>
            )}

            {!isPartner && invalid && (
              <FormValidationHint>
                {t("step-5:components.QuestionNetIncomeFrequency.hintExtra")}
              </FormValidationHint>
            )}
          </div>
        </div>
      </div>
    );
  }
}

QuestionExtraIncomeFrequency.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation("step-5")(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionExtraIncomeFrequency))
);
