import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation, Trans } from "react-i18next";
import { withRouter } from "react-router-dom";

import { trackStep } from "../../../helpers/trackingHelper";
import { ROUTENAME_STEP_6, ROUTENAME_STEP_9 } from "../../../lib/Constants";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWrapper,
} from "../../styleguides/Styleguide-Button/index";
import { FormControlCheckbox } from "../../styleguides/Styleguide-FormControl/index";
import FormValidationHint from "../../styleguides/Styleguide-FormValidationHint/index";
import Layout from "../../styleguides/Styleguide-Layout";
import SVGIcon from "../../styleguides/Styleguide-SVGIcon/index";
import BudgetTable from "./BudgetTable";

class BudgetCalculation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invalid: false,
    };
  }

  validateAndSendData(event) {
    const { viewStore, userStore } = this.props;

    if (userStore.applicant_budget_confirmation !== true) {
      this.setState({ invalid: true });
      event.preventDefault();
    } else {
      viewStore.save_session(7);
      trackStep(6);
    }
  }

  componentDidMount() {
    const { viewStore, history, userStore, loanStore } = this.props;

    if (
      !(
        viewStore.get_step_validated() < 12 &&
        viewStore.get_step_validated() >= 6
      )
    ) {
      history.push(viewStore.getLocalizedPath(ROUTENAME_STEP_6));
    }

    if (userStore.step9skipped) {
      loanStore.resetInsurance();
    }
  }

  render() {
    const { t, viewStore, userStore } = this.props;
    const { budget_calculation_values } = userStore;
    const layout_props = {
      pageTitle: "step-7:pageTitle", // passing only the key, it's translated within Layout component
      headlineKey: "step-7:headline", // passing only the key, it's translated within Layout Trans component
      inverted_background: true,
      progressBar: true,
    };

    return (
      <React.Fragment>
        <Layout data={layout_props}>
          <div className="a-text--dark-blue">
            <p className="a-text--dark-blue-hint">{t("step-7:checkDetails")}</p>
          </div>
          <BudgetTable />

          <div className="a-checkbox">
            <FormControlCheckbox
              id="acceptTerms"
              name="oos"
              onChange={(e) => {
                userStore.setApplicant_budget_confirmation(
                  e.currentTarget.checked
                );
                this.setState({ invalid: !e.currentTarget.checked });
              }}
              defaultChecked={userStore.applicant_budget_confirmation}
              invalid={this.state.invalid}
              labelContent={
                <span>
                  {budget_calculation_values.applicant_expenses_calc_basic >
                  0 ? (
                    t("step-7:components:BudgetCalculation.info_confirmation")
                  ) : (
                    <Trans
                      i18nKey={"step-7:components:BudgetCalculation:kremo_fail"}
                    >
                      {/* Text below is actually not used, it is replaced by translation,
											here just to be on safe side in case "Trans" stops working someday  */}
                      Ich bestätige, dass die oben genannten Angaben vollständig
                      und korrekt sind.
                    </Trans>
                  )}
                </span>
              }
            />

            {this.state.invalid && (
              <React.Fragment>
                <FormValidationHint>
                  {t("step-7:components:BudgetCalculation:error_message")}
                </FormValidationHint>

                <FormValidationHint>
                  {t("global:labels.hint_step")}
                </FormValidationHint>
              </React.Fragment>
            )}
          </div>

          <ButtonWrapper>
            <ButtonPrimary
              to={viewStore.getLocalizedPath(ROUTENAME_STEP_9)}
              onClick={(e) => this.validateAndSendData(e)}
            >
              <span className="a-nexus__text">
                {t("step-7:next_button_label")}
              </span>
            </ButtonPrimary>
            <ButtonSecondary to={viewStore.getLocalizedPath(ROUTENAME_STEP_6)}>
              <span className="a-nexus__icon-left">
                <SVGIcon icon="back-arrow" />
              </span>
              <span className="a-nexus__text">
                {t("global:labels:back_button")}
              </span>
            </ButtonSecondary>
          </ButtonWrapper>
        </Layout>
      </React.Fragment>
    );
  }
}

BudgetCalculation.propTypes = {
  t: PropTypes.func.isRequired,
  userStore: PropTypes.object,
  loanStore: PropTypes.object,
  viewStore: PropTypes.object.isRequired,
};

export default withRouter(
  withTranslation("step-7")(
    inject(({ stores }) => ({
      userStore: stores.user,
      viewStore: stores.view,
      loanStore: stores.loan,
    }))(observer(BudgetCalculation))
  )
);
