import PropTypes from "prop-types";
import React from "react";

import { truncate_text } from "../../../../lib/Functions";
import SVGIcon from "../../../styleguides/Styleguide-SVGIcon/index";

const SuccessItem = ({ file }) => {
  return (
    <div className="m-upload-uploaded">
      <div className="m-upload-uploaded__box">
        <div className="m-upload-uploaded__preview">
          <SVGIcon icon="apply-now" />
        </div>
      </div>
      <div className="m-upload-uploaded__actions">
        <div className="m-info-text m-info-text--upload-filename">
          <SVGIcon icon="check" />
          <span className="m-info-text__text">{truncate_text(file.name)}</span>
        </div>
      </div>
    </div>
  );
};

SuccessItem.propTypes = {
  file: PropTypes.object.isRequired,
};

export default SuccessItem;
