import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { ExpensesAlimonyCosts as expensesLimits } from "../../../../lib/Constants";
import FormControl from "../../../styleguides/Styleguide-FormControl";
import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint";
import RadioButtonGroup from "../../../styleguides/Styleguide-RadioButtonGroup";
import Tooltip from "../../../styleguides/Styleguide-Tooltip";

class QuestionAlimonyCosts extends React.Component {
  constructor(props) {
    super(props);

    this.handleChangeSelection = this.handleChangeSelection.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.type_user = props.isPartner
      ? "expenses_costs_alimonyPartner"
      : "expenses_costs_alimony";
    this.type_user_ToF = props.isPartner
      ? "expenses_pays_alimonyPartner"
      : "expenses_pays_alimony";
    this.validate = false;
  }

  handleChangeSelection(event) {
    const { userStore, isPartner } = this.props;

    const value = JSON.parse(event.currentTarget.value);
    userStore.setExpenses_pays_alimony(value, isPartner);

    if (!value) {
      userStore.setExpenses_costs_alimony(0, isPartner);
    } else {
      userStore.setExpenses_costs_alimony(null, isPartner);
    }
  }

  handleChange(value) {
    const { userStore, viewStore, isPartner } = this.props;
    value = viewStore.validate_number(value);

    if (value !== "err") {
      userStore.setExpenses_costs_alimony(value, isPartner);
      this.validate = true;
    } else {
      this.validate = false;
    }
  }

  render() {
    const { t, viewStore, userStore, isPartner } = this.props;
    let inputState, inputHint;

    const invalid =
      viewStore.shouldCustomerExpensesFormValidate &&
      userStore[this.type_user_ToF] === null;

    const yesno = {
      radioGroupName: `has_monthly_alimonies_expenses${
        isPartner ? "_partner" : ""
      }`,
      list: [
        {
          value: true,
          label: t("global:labels:yes"),
        },
        {
          value: false,
          label: t("global:labels:no"),
        },
      ],
    };

    if (
      userStore[this.type_user_ToF] &&
      (viewStore.shouldCustomerExpensesFormValidate || this.validate)
    ) {
      const value = Number(userStore[this.type_user]);

      if (value < expensesLimits.min) {
        inputState = "error";
        inputHint = t("step-6:components:QuestionAlimonyCosts:max_error");
      } else if (value > expensesLimits.max) {
        inputState = "error";
        inputHint = t("step-6:components:QuestionAlimonyCosts:max_error");
      } else if (value > expensesLimits.plausibility) {
        inputState = "warn";
        inputHint = t(
          "step-6:components:QuestionAlimonyCosts:plausibility_error"
        );
      }
    }

    if (
      inputState !== "error" &&
      invalid === false &&
      viewStore.shouldCustomerExpensesFormValidate
    ) {
      viewStore.validateCustomerExpensesForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid,
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <span className={titleClasses}>
              {isPartner
                ? t("step-6:components:QuestionAlimonyCosts:questionPartner")
                : t("step-6:components:QuestionAlimonyCosts:question")}
            </span>
            {!this.props.isInModal && (
              <Tooltip
                nameID={"expenses_alimony"}
                tooltip={t("step-6:components:QuestionAlimonyCosts:tooltip")}
              />
            )}
          </div>
          <span className="m-info-block__lead">
            {t("step-6:components:QuestionAlimonyCosts:description")}
          </span>
          <div className="m-info-block__pattern">
            <RadioButtonGroup
              doNotTrack={isPartner}
              fields={yesno}
              onChange={this.handleChangeSelection}
              selectedValue={userStore[this.type_user_ToF]}
              invalid={invalid}
            />
            {invalid && (
              <FormValidationHint>
                {t("step-6:error_yes_no")}
              </FormValidationHint>
            )}
          </div>
          {userStore[this.type_user_ToF] && (
            <div className="m-info-block__pattern">
              <FormControl
                type="tel"
                label={t("global:labels.CHF_month")}
                id={this.type_user}
                onChange={this.handleChange}
                inputState={inputState}
                hint={inputHint}
                value={viewStore.prettifyNumber(userStore[this.type_user])}
                isLast={true}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

QuestionAlimonyCosts.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionAlimonyCosts))
);
