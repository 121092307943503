import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

class TableData extends React.Component {
  render() {
    const smallView = this.props.smallView;

    const tableDataClassName = smallView
      ? cn({
          "m-table__data--small": smallView,
        })
      : {};

    let style = this.props.pointer ? { cursor: "pointer" } : {};

    return (
      <React.Fragment>
        <td
          className={tableDataClassName}
          style={style}
          onClick={this.props.onClick}
        >
          {this.props.children}
        </td>
      </React.Fragment>
    );
  }
}

TableData.propTypes = {
  children: PropTypes.any,
  smallView: PropTypes.bool,
};

export default TableData;
