import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  // load translation using fetch or xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // configure i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      cleanCode: true, // lowercase main languages e.g. "eN" => "en"
      debug: process.env.NODE_ENV === "development",
      defaultNS: [], // no default ns
      fallbackLng: ["de"],
      interpolation: { escapeValue: false }, // don't escape passed in values
      load: "languageOnly",
      ns: ["global", "step-1"], // load this ns initially
      preload: ["en"],
      supportedLngs: ["de", "en", "fr", "it"],
      detection: {
        // where to detect user's language from
        order: [
          "path",
          "cookie",
          "localStorage",
          "navigator",
          "htmlTag",
          "subdomain",
          "querystring",
        ],
      },
      react: {
        transSupportBasicHtmlNodes: true,
      },
    },
    (err, t) => {
      if (err) return console.log("Error loading content: ", err);
    }
  );

i18n.on("missingKey", function (lngs, ns, key, res) {
  console.log("missingKey", lngs, ns, key, res);
});

i18n.on("failedLoading", function (lng, ns, msg) {
  console.log("failedLoading", lng, ns, msg);
});

export default i18n;
