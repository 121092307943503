import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";

import ZipCity from "../../../shared/ZIP_city";
import InputNumber from "./InputNumber";
import InputStreet from "./InputStreet";
import InputZipCity from "./InputZipCity";

const update_postal = (items, props) => {
  const { userStore, viewStore } = props;

  userStore.setAddress(
    {
      zip: items.zip,
      city: items.city,
      zipAdditional: items.zipAdditional,
      canton: items.canton,
    },
    "address_former"
  );

  if (viewStore.shouldPersonalDataFormValidate && items.zip && items.city)
    viewStore.validatePersonalDataForm();
};

const QuestionApplicantsFullAddress = (props) => {
  const { userStore, viewStore, t } = props;

  const isFormer = props.isFormer ? true : false;
  const content = "step-11:components.QuestionApplicantsFullAddress.former:";

  const data = {
    zip: userStore.address_zip_former,
    city: userStore.address_city_former,
    withSuggestService: false,
    should_step_validate: viewStore.shouldPersonalDataFormValidate,
    read_only: false,
    question_zip: t(content + "question_zip"),
    question_city: null,
    type_user_zip: "zip_former",
    type_user_city: "city_former",
    error_zip: t(content + "error_zip"),
  };

  return (
    <React.Fragment>
      {
        /* only shown for current address*/
        !isFormer && (
          <React.Fragment>
            <InputStreet {...props} />
            <InputNumber {...props} />
            <InputZipCity {...props} />
          </React.Fragment>
        )
      }
      {
        /* only shown for former address*/
        props.viewStore.shouldAskForFormerApplicantAddress() && isFormer && (
          <React.Fragment>
            <InputStreet {...props} />
            <InputNumber {...props} />
            <ZipCity
              data={data}
              update_postal={(items) => update_postal(items, props)}
            />
          </React.Fragment>
        )
      }
    </React.Fragment>
  );
};

QuestionApplicantsFullAddress.propTypes = {
  viewStore: PropTypes.object,
  userStore: PropTypes.object,
};

export default inject(({ stores }) => ({
  viewStore: stores.view,
  userStore: stores.user,
}))(observer(QuestionApplicantsFullAddress));
