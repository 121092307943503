import cn from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { KEYCODE_ESCAPE, KEYCODE_TAB } from "../../../lib/Constants";

const LAYOUT_OVERLAY_ACTIVE_CLASS = "state-l-overlay--active";
const OVERLAY_BLURRED_CLASS = "state-l-overlay--blurred";

class Overlay extends Component {
  constructor(props) {
    super(props);
    this.overlayRef = React.createRef();
    this.overlayCloseRef = React.createRef();

    this.state = {
      contentFocused: true,
    };
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  componentDidMount() {
    document.body.classList.add(LAYOUT_OVERLAY_ACTIVE_CLASS);
    document.getElementById("app-root").classList.add(OVERLAY_BLURRED_CLASS);
    this.focusOnOverlay();
  }

  componentWillUnmount() {
    document.body.classList.remove(LAYOUT_OVERLAY_ACTIVE_CLASS);
    document.getElementById("app-root").classList.remove(OVERLAY_BLURRED_CLASS);
  }

  handleOnClose = () => {
    this.props.onClose();
  };

  handleOverlayKeyDown(e) {
    if (e.keyCode === KEYCODE_ESCAPE) {
      this.handleOnClose();
    } else if (e.keyCode === KEYCODE_TAB) {
      e.preventDefault();
      // TODO(29.11.2018) definitely not generic solution, just to avoid "tabbing out" from modal and just switching focus between content and close button
      this.state.contentFocused
        ? this.setState({ contentFocused: false }, () =>
            this.overlayRef.current.focus()
          )
        : this.setState({ contentFocused: true }, () =>
            this.overlayCloseRef.current.focus()
          );
    }
  }

  focusOnOverlay() {
    if (!this.props.withoutPageContainer) {
      window.requestAnimationFrame(() => this.overlayRef.current.focus());
    }
  }

  render() {
    const { id, title, children, isOpen, withoutPageContainer, onClose } =
      this.props;

    const overlayClasses = cn({
      "m-overlay": true,
      "m-overlay--loader": withoutPageContainer,
      "state-m-overlay--animate": isOpen,
      "state-m-overlay--visible": isOpen,
      editQuestion: this.props.classes,
    });

    return (
      <div
        ref={this.overlayRef}
        className={overlayClasses}
        data-t-name="Overlay"
        data-overlay={`overlay-${id}`}
        role="dialog"
        onKeyDown={this.handleOverlayKeyDown.bind(this)}
        tabIndex="-1"
      >
        <div className="m-overlay__wrap" role="document">
          <div className="m-overlay__container">
            {withoutPageContainer ? (
              <div>
                <div className="m-overlay__content">
                  {children && (
                    <div className="m-overlay-content__wrap">{children}</div>
                  )}
                </div>
                {onClose && (
                  <button
                    ref={this.overlayCloseRef}
                    className="m-overlay__close js-m-overlay__close"
                    type="button"
                    onClick={this.handleOnClose}
                  >
                    &times;
                  </button>
                )}
              </div>
            ) : (
              <div>
                <div className="m-overlay__content">
                  <div className="l-page-container l-page-container--invert">
                    <div className="l-page-inner">
                      <div className="g-container g-layout--s12-l8">
                        <div className="g-cell">
                          <div className="m-overlay-content__wrap">
                            {title && (
                              <div className="m-overlay-content__head">
                                <h2 className="m-overlay-content__title">
                                  {title}
                                </h2>
                              </div>
                            )}
                            {children && (
                              <div className="m-overlay-content__content">
                                {children}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  ref={this.overlayCloseRef}
                  className="m-overlay__close js-m-overlay__close"
                  type="button"
                  onClick={this.handleOnClose}
                >
                  &times;
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Overlay.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  withoutPageContainer: PropTypes.bool,
};

Overlay.defaultProps = {
  isOpen: false,
};

export default Overlay;
