import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

import SVGIcon from "../Styleguide-SVGIcon/index";

class Nexus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showClickSate: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    if (this.props.onClick) {
      this.props.onClick(e);
    }

    this.setState({ showClickSate: true });

    setTimeout(() => {
      this.setState({ showClickSate: false });
    }, 500);
  }

  render() {
    const { type, children, icon } = this.props;
    const nexusClassNames = cn({
      "a-nexus": true,
      [`a-nexus--${type}`]: true,
      "state-a-nexus--clicked": this.state.showClickSate,
    });

    return (
      <span
        className={nexusClassNames}
        onClick={(event) => this.handleClick(event)}
      >
        <span className="a-nexus__icon-left">
          <SVGIcon icon={icon} />
        </span>
        <span className="a-nexus__text">{children}</span>
      </span>
    );
  }
}

Nexus.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string.isRequired,
};

export default Nexus;
