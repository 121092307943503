import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { ExpensesMonthlyChildCareCosts as expensesLimits } from "../../../../lib/Constants";
import FormControl from "../../../styleguides/Styleguide-FormControl";
import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint";
import RadioButtonGroup from "../../../styleguides/Styleguide-RadioButtonGroup";
import Tooltip from "../../../styleguides/Styleguide-Tooltip";

class QuestionMonthlyChildCareCosts extends React.Component {
  constructor(props) {
    super(props);

    this.handleChangeSelection = this.handleChangeSelection.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);

    this.validate = false;
  }

  handleChangeSelection(event) {
    const { userStore } = this.props;
    const value = JSON.parse(event.currentTarget.value);

    userStore.setExpenses_pays_childcare(value);

    if (!value) userStore.setExpenses_costs_childcare(0);
    else userStore.setExpenses_costs_childcare(null);
  }

  handleChange(value) {
    const { userStore, viewStore } = this.props;
    value = viewStore.validate_number(value);

    if (value !== "err") {
      userStore.setExpenses_costs_childcare(value);
      this.validate = true;
    }
  }

  handleOnBlur() {}

  render() {
    const { t, viewStore, userStore } = this.props;

    // Validate this optional field when global form should validate
    let inputState, inputHint;
    const invalid =
      viewStore.shouldCustomerExpensesFormValidate &&
      userStore.expenses_pays_childcare === null;

    const yesno = {
      radioGroupName: "has_monthly_childcare_expenses",
      list: [
        {
          value: true,
          label: t("global:labels.yes"),
        },
        {
          value: false,
          label: t("global:labels.no"),
        },
      ],
    };

    if (
      (viewStore.shouldCustomerExpensesFormValidate || this.validate) &&
      userStore.expenses_pays_childcare
    ) {
      const value = userStore.expenses_costs_childcare;

      if (value < expensesLimits.min || value > expensesLimits.max) {
        inputState = "error";
        inputHint = t(
          "step-6:components:QuestionMonthlyChildCareCosts:max_error"
        );
      } else if (value > expensesLimits.plausibility) {
        inputState = "warn";
        inputHint = t(
          "step-6:components:QuestionMonthlyChildCareCosts:plausibility_error"
        );
      }
    }

    if (
      inputState !== "error" &&
      viewStore.shouldCustomerExpensesFormValidate
    ) {
      viewStore.validateCustomerExpensesForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid,
    });

    // Do not render, if no children are given
    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <span className={titleClasses}>
              {t("step-6:components:QuestionMonthlyChildCareCosts:question")}
            </span>
            {!this.props.isInModal && (
              <Tooltip
                nameID={"expenses_child"}
                tooltip={t(
                  "step-6:components:QuestionMonthlyChildCareCosts:tooltip"
                )}
              />
            )}
          </div>
          <span className="m-info-block__lead">
            {t("step-6:components:QuestionMonthlyChildCareCosts:description")}
          </span>
          <div className="m-info-block__pattern">
            <RadioButtonGroup
              fields={yesno}
              onChange={this.handleChangeSelection}
              selectedValue={userStore.expenses_pays_childcare}
              invalid={invalid}
            />
            {invalid && (
              <FormValidationHint>
                {t("step-6:error_yes_no")}
              </FormValidationHint>
            )}
          </div>
          {userStore.expenses_pays_childcare && (
            <div className="m-info-block__pattern">
              <FormControl
                type="tel"
                label={t("global:labels.CHF_month")}
                id="childcare_costs"
                onChange={this.handleChange}
                onBlur={this.handleOnBlur}
                inputState={inputState}
                hint={inputHint}
                value={viewStore.prettifyNumber(
                  userStore.expenses_costs_childcare
                )}
                isLast={true}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

QuestionMonthlyChildCareCosts.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionMonthlyChildCareCosts))
);
