import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { ExpensesFoodCosts as expensesLimits } from "../../../../lib/Constants";
import FormControl from "../../../styleguides/Styleguide-FormControl";
import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint";
import RadioButtonGroup from "../../../styleguides/Styleguide-RadioButtonGroup";
import Tooltip from "../../../styleguides/Styleguide-Tooltip";

class QuestionFoodCosts extends React.Component {
  constructor(props) {
    super(props);

    this.handleChangeSelection = this.handleChangeSelection.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.type_user = "expenses_costs_food";
    this.type_user_ToF = "expenses_pays_food";
    this.validate = false;
  }

  handleChangeSelection(event) {
    const { userStore } = this.props;

    const value = JSON.parse(event.currentTarget.value);
    userStore.setExpenses_pays_food(value);

    if (!value) {
      userStore.setExpenses_costs_food(0);
    } else {
      userStore.setExpenses_costs_food(expensesLimits.default);
    }
  }

  handleChange(value) {
    const { userStore, viewStore } = this.props;
    value = viewStore.validate_number(value);

    if (value !== "err") {
      userStore.setExpenses_costs_food(value);
      this.validate = true;
    } else {
      this.validate = false;
    }
  }

  render() {
    const { t, viewStore, userStore } = this.props;
    let inputState, inputHint;
    const invalid =
      viewStore.shouldCustomerExpensesFormValidate &&
      userStore[this.type_user_ToF] &&
      !userStore[this.type_user];
    const invalidYN =
      viewStore.shouldCustomerExpensesFormValidate &&
      userStore[this.type_user_ToF] === null;

    const yesno = {
      radioGroupName: "has_monthly_meals_expenses",
      list: [
        {
          value: true,
          label: t("global:labels:yes"),
        },
        {
          value: false,
          label: t("global:labels:no"),
        },
      ],
    };

    if (
      userStore[this.type_user_ToF] &&
      (viewStore.shouldCustomerExpensesFormValidate || this.validate)
    ) {
      const value = userStore[this.type_user];

      if (value < expensesLimits.min) {
        inputState = "error";
        inputHint = t("step-6:components:QuestionFoodCosts:error");
      } else if (value > expensesLimits.max) {
        inputState = "error";
        inputHint = t("step-6:components:QuestionFoodCosts:error");
      } else if (value > expensesLimits.plausibility) {
        inputState = "warn";
        inputHint = t("step-6:components:QuestionFoodCosts:plausibility_error");
      }
    }

    if (
      inputState !== "error" &&
      invalid === false &&
      invalidYN === false &&
      viewStore.shouldCustomerExpensesFormValidate
    ) {
      viewStore.validateCustomerExpensesForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid || invalidYN,
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <span className={titleClasses}>
              {t("step-6:components:QuestionFoodCosts:question")}
            </span>
            {!this.props.isInModal && (
              <Tooltip
                tooltip={t("step-6:components:QuestionFoodCosts:tooltip")}
              />
            )}
          </div>
          <div className="m-info-block__pattern">
            <RadioButtonGroup
              fields={yesno}
              onChange={this.handleChangeSelection}
              selectedValue={userStore[this.type_user_ToF]}
              invalid={invalidYN}
            />
            {invalidYN && (
              <FormValidationHint>
                {t("step-6:error_yes_no")}
              </FormValidationHint>
            )}
          </div>
          {userStore[this.type_user_ToF] && (
            <div className="m-info-block__pattern">
              <FormControl
                type="tel"
                label={t("global:labels.CHF_month")}
                id={this.type_user}
                onChange={this.handleChange}
                inputState={inputState}
                hint={inputHint}
                value={viewStore.prettifyNumber(userStore[this.type_user])}
                isLast={true}
                invalid={invalid}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

QuestionFoodCosts.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionFoodCosts))
);
