import i18next from "i18next";
import { isValid as isIbanValid } from "iban";
import { getParent, types } from "mobx-state-tree";
import moment from "moment";
import numeral from "numeral";

import { putSessionData } from "../api_clients/session_service";
import {
  KEY_NONE,
  KEY_PPI,
  KEY_PPI_PLUS,
  KEY_PPI_SE,
  getInsuranceByKey,
} from "../data/insuranceData";
import { roundTo2Decimals } from "../helpers/numberHelper";
import * as c from "../lib/Constants";
import { ExpensesHousingCosts, income_frequency_main } from "../lib/Constants";
import { length } from "../lib/Functions";

export const ViewStore = types
  .model("ViewStore", {
    navUrls: types.maybeNull(types.map(types.string)),
    should_step_3_validate: false,
    should_step_4_validate: false,
    shouldCustomerIncomeFormValidate: false,
    shouldCustomerExpensesFormValidate: false,
    shouldPersonalDataFormValidate: false,
    shouldCustomerContactFormValidate: false,
    should_step_12_validate: false,
    activateLoadingScreen: false,
    step_validated: 0,
  })
  .views((self) => ({
    /**
     * @returns {string|void}
     */
    getStepId() {
      // remove trailing slashes
      const path = window.location.pathname.replace(/\/+$/, "");
      return c.ROUTENAMES.find((r) => path === self.getLocalizedPath(r));
    },
    /**
     * @param stepId {string}
     * @param lng {string=} E.g. en
     * @returns {string}
     */
    getLocalizedPath(stepId, lng = null) {
      return i18next.getFixedT(lng, "global")(`urls.${stepId}`);
    },
    // TODO has lost nothing here
    unPrettifyNumber(number) {
      if (!number) {
        return number;
      }
      return String(number).replace(new RegExp("'", "gm"), "");
    },
    // Prettify money amount TODO has lost nothing here
    prettifyNumber(number) {
      if (!number) if (number !== 0) return "";

      number = self.unPrettifyNumber(number);
      if (isNaN(number)) {
        return number;
      }

      if (i18next.resolvedLanguage === "en") {
        return numeral(number).format("0,0");
      } else {
        // MAYBE use custom numeral format
        return numeral(number)
          .format("0,0")
          .replace(new RegExp(",", "gm"), "'");
      }
    },
    // TODO has lost nothing here
    prettifyNumber_html(number, minFractionDigits = 0, maxFractionDigits = 2) {
      number = self.unPrettifyNumber(number);
      let calcNumber = 0;

      if (isNaN(number)) {
        return number;
      }

      if (window.Intl) {
        if (i18next.resolvedLanguage === "en") {
          calcNumber = new Intl.NumberFormat("es-US", {
            minimumFractionDigits: minFractionDigits,
            maximumFractionDigits: maxFractionDigits,
          }).format(number);
        } else {
          calcNumber = new Intl.NumberFormat("de-CH", {
            minimumFractionDigits: minFractionDigits,
            maximumFractionDigits: maxFractionDigits,
          }).format(number);
        }
      } else {
        return number;
      }

      return calcNumber;
    },
    // TODO not used?
    chunkString(value, chunkLength) {
      if (!value) return "";

      return value.match(new RegExp(`.{1,${chunkLength}}`, "g")).join(" ");
    },
    // TODO not used?
    chunkString2(str, size) {
      const numChunks = Math.ceil(str.length / size);
      let chunks = "";

      for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
        chunks += str.substr(o, size) + " ";
      }

      return chunks;
    },
    cloneStoreObject(object) {
      if (!object) {
        return null;
      }
      return object.map((item, i) => object[i]);
    },
    shouldAskForFormerApplicantAddress() {
      const userStore = getParent(self).user;
      const current = userStore.residenceSince;
      if (!current) {
        return false;
      }
      const now = new moment();
      const then = new moment(current, "YYYY-MM-DD");
      const months = moment.duration(now.diff(then)).asMonths();

      return months && months < 6;
    },
    leadingZero(number) {
      if (String(number).length === 1) {
        return `0${number}`;
      }
      return number;
    },
    validatePhoneByRegex(phone) {
      return /^(?:[0-9, \s]+$)/.test(phone);
    },
    hasDigit(input) {
      return /[0-9]/.test(input);
    },
    isOnlyWhitespace(input) {
      return /^\s+$/.test(input);
    },
    getSearchParamValue(key) {
      let value = null;
      if (
        typeof window !== "undefined" &&
        window.location &&
        window.location.search
      ) {
        const url = new URL(window.location.href);
        value = url.searchParams.get(key);
      }
      return value;
    },
    // TODO fix typo
    get_setp_validated() {
      return self.step_validated;
    },
    // TODO move to helper
    // TODO fix typo
    rouding5cents(value) {
      return (Math.ceil(value * 20 - 0.5) / 20).toFixed(2);
      //return parseFloat((Math.round(value / 0.05) * 0.05).toFixed(2))
    },
    paddingFraction(value) {
      return value.toFixed(2);
    },
    show_value_P_R(value) {
      return self.prettifyNumber(self.rouding5cents(value));
    },
  }))
  .actions((self) => ({
    // Step-3 should validate
    userBasicDemographicsFormComponentsShouldValidate(bool) {
      self.should_step_3_validate = bool;
    },
    // TODO fix typo
    vaildateUserBasicDemographicsForm() {
      const userStore = getParent(self).user;
      const selectedDate = moment(
        `${userStore.birthdayValues.year}-${userStore.birthdayValues.month}-${userStore.birthdayValues.day}`,
        "YYYY-MM-DD"
      );
      const selectDatePartner = moment(
        `${userStore.birthdayValuesPartner.year}-${userStore.birthdayValuesPartner.month}-${userStore.birthdayValuesPartner.day}`,
        "YYYY-MM-DD"
      );

      let validates = !(
        !userStore.gender ||
        !userStore.maritalStatus ||
        !userStore.birthdayValues.day ||
        !userStore.birthdayValues.month ||
        !userStore.birthdayValues.year ||
        !selectedDate.isValid() ||
        userStore.hasChildrenUnder25 === null ||
        (userStore.hasChildrenUnder25 &&
          !userStore.childrensBirthYears.length) ||
        (userStore.isEditingPartnerInfo() &&
          (!userStore.birthdayValuesPartner.day ||
            !userStore.birthdayValuesPartner.month ||
            !userStore.birthdayValuesPartner.year ||
            !userStore.genderPartner)) ||
        (userStore.isEditingPartnerInfo() && !selectDatePartner.isValid()) ||
        (userStore.hasPartner() && userStore.showPartnerInfo === null) ||
        (userStore.hasPartner() &&
          userStore.showPartnerInfo &&
          !userStore.customerAcceptedPartnerDisclaimer)
      );
      if (validates === true) self.should_step_3_validate = false;
      return validates;
    },
    // Step-4 should validate
    customerAccommodationFormShouldValidate(bool) {
      self.should_step_4_validate = bool;
    },
    validateCustomerAccommodationForm() {
      let validates = true;
      const userStore = getParent(self).user;
      // is users nationality set
      if (!userStore.nationality || userStore.nationality === "__other") {
        validates = false;
      }
      // if user is foreigner (no Swiss)
      if (["CH", "__other", "LI"].indexOf(userStore.nationality) === -1) {
        // has not set an permission ID (B, C or G)
        if (!userStore.permitId) {
          validates = false;
        }
        // If not residence time given for persons with B or C permission
        if (!userStore.permitSince) {
          validates = false;
        }
      }
      // If partner infos should be considered
      if (userStore.isEditingPartnerInfo()) {
        if (
          !userStore.nationalityPartner ||
          userStore.nationalityPartner === "__other"
        ) {
          validates = false;
        }
        // if user is foreigner (no Swiss)
        if (
          ["CH", "__other", "LI"].indexOf(userStore.nationalityPartner) === -1
        ) {
          // has not set an permission ID (B, C or G)
          if (!userStore.permitIdPartner) {
            validates = false;
          }
          // If not residence time given for partners
          if (userStore.permitIdPartner && !userStore.permitSincePartner) {
            validates = false;
          }
        }
      }
      // If no user address is given
      if (!userStore.address_zip || !userStore.address_city) {
        validates = false;
      }
      // If residence is outside of Switzerland
      if (userStore.residenceOutOfSwitzerland) {
        // City is invalid
        if (
          !userStore.address_city ||
          userStore.address_city.match(/\d+/g) !== null
        ) {
          validates = false;
        }
        // No Employer Address is given
        if (
          !userStore.address_zip_employer ||
          !userStore.address_city_employer
        ) {
          validates = false;
        }
      }
      // if no living status is given
      if (!userStore.livingStatus) {
        validates = false;
      }
      if (validates === true) self.should_step_4_validate = false;
      return validates;
    },
    // Step-5 should validate
    customerIncomeFormShouldValidate(bool) {
      self.shouldCustomerIncomeFormValidate = bool;
    },
    validateCustomerIncomeForm() {
      let validates = true;
      const userStore = getParent(self).user;
      const MAX_VALUE = 99999;

      // user selected employment status
      if (userStore.incomeSourceId === null) {
        validates = false;
      }

      // user set valid income
      if (
        userStore.income === null ||
        userStore.income < 2500 ||
        userStore.income > MAX_VALUE
      ) {
        validates = false;
      }

      // user selected valid income frequency
      if (
        userStore.incomeFrequency === null ||
        income_frequency_main.indexOf(userStore.incomeFrequency) === -1
      ) {
        validates = false;
      }

      // user has / has not extra income
      if (userStore.hasExtraIncome === null) {
        validates = false;
      }

      if (
        userStore.extraIncomeSource.length === 0 &&
        userStore.hasExtraIncome
      ) {
        validates = false;
      }

      // user set valid optional extra income
      if (
        userStore.hasExtraIncome &&
        userStore.getNumberOfExtraIncomes() > 0 &&
        userStore.extraIncomeSource.some(
          (item, i) =>
            !item ||
            !userStore.getExtraIncomeFrequency(i) ||
            !userStore.getExtraIncomeAmount(i) ||
            userStore.getExtraIncomeAmount(i) > MAX_VALUE
        )
      ) {
        validates = false;
      }

      // partner info if applicable
      if (userStore.isEditingPartnerInfo()) {
        // partner selected employment status
        if (userStore.incomeSourceIdPartner === null) {
          validates = false;
        }

        // check other values only, if partner is not unemployed (unemployed = 0, housewife = 100)
        if (userStore.incomeSourceIdPartner && userStore.doesPartnerWorks()) {
          // partner set valid income
          if (
            userStore.incomePartner === null ||
            userStore.incomePartner === 0 ||
            userStore.incomePartner > MAX_VALUE
          ) {
            validates = false;
          }

          // partner selected valid income frequency
          if (
            userStore.incomeFrequencyPartner === null ||
            income_frequency_main.indexOf(userStore.incomeFrequencyPartner) ===
              -1
          ) {
            validates = false;
          }

          // partner has / has not extra income
          if (userStore.hasExtraIncomePartner === null) {
            validates = false;
          }

          // partner set valid optional extra income
          if (
            userStore.hasExtraIncomePartner &&
            (userStore.getNumberOfExtraIncomes(true) === 0 ||
              userStore.extraIncomeSourcePartner.some(
                (item, i) =>
                  !item ||
                  !userStore.getExtraIncomeFrequency(i, true) ||
                  !userStore.getExtraIncomeAmount(i, true) ||
                  userStore.getExtraIncomeAmount(i, true) > MAX_VALUE
              ))
          ) {
            validates = false;
          }
        }
      }

      if (validates === true) self.shouldCustomerIncomeFormValidate = false;

      return validates;
    },
    customerExpensesFormShouldValidate(bool) {
      self.shouldCustomerExpensesFormValidate = bool;
    },
    validateCustomerExpensesForm() {
      let validates = true;
      const userStore = getParent(self).user;
      const MAX_VALUE = 99999;

      if (
        userStore.expenses_costs_housing < ExpensesHousingCosts.min ||
        userStore.expenses_costs_housing > ExpensesHousingCosts.max
      ) {
        validates = false;
      } else if (
        userStore.expenses_costs_work === null ||
        userStore.expenses_costs_work > MAX_VALUE
      ) {
        validates = false;
      } else if (
        userStore.expenses_pays_food === null ||
        (userStore.expenses_pays_food && !userStore.expenses_costs_food)
      ) {
        validates = false;
      } else if (
        userStore.expenses_pays_alimony === null ||
        (userStore.expenses_pays_alimony && !userStore.expenses_costs_alimony)
      ) {
        validates = false;
      } else if (
        (userStore.hasChildrenUnder25 &&
          userStore.expenses_pays_childcare === null) ||
        (userStore.expenses_pays_childcare &&
          !userStore.expenses_costs_childcare)
      ) {
        validates = false;
      } else if (
        userStore.expenses_pays_otherDebt === null ||
        (userStore.expenses_pays_otherDebt &&
          !userStore.expenses_costs_otherDebts)
      ) {
        validates = false;
      } else if (
        userStore.expenses_pays_otherDebt &&
        userStore.expenses_wantCompensate_otherDebts == null
      ) {
        validates = false;
      } else if (
        userStore.expenses_costs_additional === null ||
        userStore.expenses_costs_additional > MAX_VALUE
      ) {
        validates = false;
      }

      // partner section only applies, when partner infos should be shown and partner has income
      else if (userStore.isEditingPartnerInfo()) {
        if (
          userStore.expenses_pays_alimonyPartner === null ||
          (userStore.expenses_pays_alimonyPartner &&
            !userStore.expenses_costs_alimonyPartner)
        ) {
          validates = false;
        }
        if (userStore.expenses_costs_additionalPartner === null) {
          validates = false;
        }
      }

      if (validates) self.customerExpensesFormShouldValidate(false);

      return validates;
    },
    personalDataFormShouldValidate(bool) {
      self.shouldPersonalDataFormValidate = bool;
    },
    validatePersonalDataForm() {
      let validates = true;
      const userStore = getParent(self).user;
      const MIN_NAME_LENGTH = 2;
      const MAX_NAME_LENGTH = 28;

      if (
        length(userStore.firstName) < MIN_NAME_LENGTH ||
        length(userStore.lastName) < MIN_NAME_LENGTH ||
        length(userStore.firstName) + length(userStore.lastName) >
          MAX_NAME_LENGTH
      ) {
        validates = false;
      } else if (
        !userStore.address_street ||
        !userStore.address_street.length
      ) {
        validates = false;
      } else if (
        !userStore.address_house_number ||
        !String(userStore.address_house_number).length
      ) {
        validates = false;
      } else if (
        !userStore.residenceSince ||
        !moment(userStore.residenceSince, "YYYY-MM-DD").isValid()
      ) {
        validates = false;
      }

      // Validate former address, if user lives in current address for less than 6 month
      if (self.shouldAskForFormerApplicantAddress()) {
        if (
          !userStore.address_street_former ||
          !userStore.address_street_former.length
        ) {
          validates = false;
        } else if (
          !userStore.address_house_number_former ||
          !String(userStore.address_house_number_former).length
        ) {
          validates = false;
        } else if (!userStore.address_zip_former) {
          validates = false;
        } else if (!userStore.address_city_former) {
          validates = false;
        }
      }

      // Validate partner fields if necessary
      if (userStore.isEditingPartnerInfo()) {
        if (
          length(userStore.firstNamePartner) < MIN_NAME_LENGTH ||
          length(userStore.lastNamePartner) < MIN_NAME_LENGTH ||
          length(userStore.firstNamePartner) +
            length(userStore.lastNamePartner) >
            MAX_NAME_LENGTH
        ) {
          validates = false;
        }
      }
      if (validates) self.shouldPersonalDataFormValidate = false;
      return validates;
    },
    validateProfessionalDataForm() {
      let validates = true;
      const userStore = getParent(self).user;

      // employer check, applicatn
      if (!userStore.employerName) {
        validates = false;
      } else if (!userStore.startDateCurrentEmployer) {
        validates = false;
      } else if (!userStore.address_city_employer) {
        validates = false;
      } else if (!userStore.address_zip_employer) {
        validates = false;
      } else if (
        userStore.applicantIbanNumber &&
        !self.validate_iban(userStore.applicantIbanNumber)
      ) {
        validates = false;
      }

      // partner employer
      if (userStore.isEditingPartnerInfo()) {
        // employer check, applicant
        if (userStore.doesPartnerWorks()) {
          if (!userStore.employerNamePartner) {
            validates = false;
          } else if (!userStore.startDateCurrentEmployerPartner) {
            validates = false;
          } else if (!userStore.address_city_employer_partner) {
            validates = false;
          } else if (!userStore.address_zip_employer_partner) {
            validates = false;
          }
        }
      }

      if (!userStore.customerAcceptedZekTermsAndConditions) {
        validates = false;
      } else if (
        userStore.applicantIbanNumber &&
        !self.validate_iban(userStore.applicantIbanNumber)
      ) {
        validates = false;
      }
      if (validates) self.should_step_12_validate = false;
      return validates;
    },
    set_should_step_12_validate(bool) {
      self.should_step_12_validate = bool;
    },
    // Step-10 should validate
    customerContactFormShouldValidate(bool) {
      self.shouldCustomerContactFormValidate = bool;
    },
    validateCustomerContactForm() {
      let validates = true;
      const userStore = getParent(self).user;
      // email, phone, accepted terms
      if (
        !userStore.customerPhone ||
        !userStore.customerEmail ||
        !userStore.customerAcceptedTerms ||
        (i18next.resolvedLanguage === "en" &&
          !userStore.customerContactLanguage)
      ) {
        validates = false;
      }
      if (validates) self.shouldCustomerContactFormValidate = false;
      return validates;
    },
    set_step_validated(step) {
      self.step_validated = step;
    },
    get_step_validated() {
      return self.step_validated;
    },
    save_session(step) {
      self.set_step_validated(step);
      const Store = getParent(self);
      putSessionData(JSON.stringify(Store));
    },
    validate_number(value, max = 99999) {
      if (!value) {
        return null;
      }

      if (typeof value === "string") {
        value = value.replace(new RegExp("'|,", "gm"), "");
      }

      value = Number(value);

      if (isNaN(value)) return "err";

      if (value < 0 || value > max) {
        return "err";
      } else return value;
    },
    validate_iban(value) {
      if (!value) return true;
      else if (!isIbanValid(value)) {
        return false;
      } else if (
        !(
          value.toUpperCase().substring(0, 2) === "CH" ||
          value.toUpperCase().substring(0, 2) === "LI"
        )
      ) {
        return false;
      } else {
        return true;
      }
    },
    // TODO re-locate
    pushGTMConfirmation(appID) {
      const loanStore = getParent(self).loan;
      const userStore = getParent(self).user;

      let insuranceName = getInsuranceByKey(KEY_NONE).name;
      // TODO why have employed and self-employed insurances same id?
      if (loanStore.insuranceTypeId === getInsuranceByKey(KEY_PPI).id) {
        insuranceName =
          userStore.incomeSourceId === 9 // self-employed
            ? getInsuranceByKey(KEY_PPI_SE).name
            : getInsuranceByKey(KEY_PPI).name;
      } else if (
        loanStore.insuranceTypeId === getInsuranceByKey(KEY_PPI_PLUS).id
      ) {
        insuranceName = getInsuranceByKey(KEY_PPI_PLUS).name;
      }

      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          transaction_id: appID,
          // real transaction value: earnings over contract
          // term plus additional revenue from insurance
          value: roundTo2Decimals(
            loanStore.totalMaxCreditCosts() +
              (loanStore.insuranceValue || 0) * loanStore.loanTerm
          ),
          tax: 0,
          shipping: 0,
          currency: "CHF",
          items: [
            {
              item_id: "private_loan",
              item_name: "Private Loan",
              discount: 0,
              item_brand: "Cembra",
              item_category: "Loan",
              item_category2: "Private Loan",
              item_category3: `${loanStore.loanTerm}`,
              item_category4: `${loanStore.loanAmountValue}`,
              item_category5: "standard",
              // earnings over contract term
              price: roundTo2Decimals(loanStore.totalMaxCreditCosts()),
              quantity: 1,
            },
            {
              item_id: "loan_insurance",
              item_name: "Loan Insurance",
              discount: 0,
              item_brand: "Cembra",
              item_category: "Insurance",
              // type of insurance
              item_category2: insuranceName,
              // insurance price
              price: roundTo2Decimals(
                (loanStore.insuranceValue || 0) * loanStore.loanTerm
              ),
              quantity: 1,
            },
          ],
        },
      });
      getParent(self).reset();
      self.save_session(12);
      return true;
    },
  }));
