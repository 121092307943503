import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import FormValidationHint from "../../../../styleguides/Styleguide-FormValidationHint/index";
import RadioButtonGroup from "../../../../styleguides/Styleguide-RadioButtonGroup/index";

class QuestionLoanCompensationCosts extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let value = JSON.parse(event.currentTarget.value);
    this.props.userStore.setExpenses_wantCompensate_otherDebts(value);
  }

  render() {
    const { userStore, viewStore, t } = this.props;
    const yesno = {
      radioGroupName: "wants_redeem_existing_obligations",
      list: [
        {
          value: true,
          label: t("global:labels.yes"),
        },
        {
          value: false,
          label: t("global:labels.no"),
        },
      ],
    };
    const invalid =
      viewStore.shouldCustomerExpensesFormValidate &&
      userStore.expenses_wantCompensate_otherDebts === null;
    if (invalid === false && viewStore.shouldCustomerExpensesFormValidate) {
      viewStore.validateCustomerExpensesForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid,
    });

    // only render, when other debts are given by user
    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <span className={titleClasses}>
              {t("step-6:components:QuestionLoanCompensationCosts:question")}
            </span>
          </div>
          <div className="m-info-block__pattern">
            {yesno.list && yesno.list.length > 0 && (
              <RadioButtonGroup
                fields={yesno}
                onChange={this.handleChange}
                selectedValue={userStore.expenses_wantCompensate_otherDebts}
                invalid={invalid}
              />
            )}
            {invalid && (
              <FormValidationHint>
                {t("step-6:error_yes_no")}
              </FormValidationHint>
            )}
          </div>
        </div>
      </div>
    );
  }
}

QuestionLoanCompensationCosts.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionLoanCompensationCosts))
);
