import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import {
  loan_income_values_app,
  loan_income_values_partner,
} from "../../../../../lib/Constants";
import FormControl from "../../../../styleguides/Styleguide-FormControl/index";
import Tooltip from "../../../../styleguides/Styleguide-Tooltip/index";

class QuestionNetIncome extends React.Component {
  constructor(props) {
    super(props);

    this.limits = props.isPartner
      ? loan_income_values_partner
      : loan_income_values_app;

    this.content = props.isPartner
      ? "step-5:components.QuestionNetIncome.partner"
      : "step-5:components.QuestionNetIncome";
    this.content_both = "step-5:components.QuestionNetIncome";

    this.type_user = props.isPartner ? "incomePartner" : "income";

    this.handleChange = this.handleChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);

    this.state = {
      validate: false,
    };
  }

  handleChange(value) {
    const { userStore, viewStore, isPartner } = this.props;
    value = viewStore.validate_number(value);
    if (value !== "err") {
      userStore.setIncome(value, isPartner);
    }
  }

  handleOnBlur() {
    this.setState({ validate: true });
  }

  render() {
    const { viewStore, t, userStore, isPartner } = this.props;

    const value = userStore[this.type_user];
    let inputState, inputHint;

    if (this.state.validate || viewStore.shouldCustomerIncomeFormValidate) {
      if (isPartner && userStore[this.type_user] == null) {
        inputState = "error";
        inputHint = t(this.content + ".error_empty");
      } else if (value > this.limits.max_income) {
        inputState = "error";
        inputHint = t(this.content + ".hint_max_min");
      } else if (value < this.limits.min_income) {
        inputState = "error";
        inputHint = t(this.content + ".hint_max_min");
      } else if (value > this.limits.plausable_income) {
        inputState = "warn";
        inputHint = t(this.content_both + ".hint_plausability");
      }
    }

    if (viewStore.shouldCustomerIncomeFormValidate && inputState !== "error") {
      viewStore.validateCustomerIncomeForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": inputState === "error",
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <label className={titleClasses}>
              {t(this.content + ".question")}
            </label>

            {!this.props.isInModal && (
              <Tooltip tooltip={t(this.content_both + ".tooltip_income")} />
            )}
          </div>
          {!isPartner &&
            t("step-5:components:QuestionNetIncome:description") && (
              <span className="m-info-block__lead">
                {t("step-5:components:QuestionNetIncome:description")}
              </span>
            )}
          <div className="m-info-block__pattern">
            <FormControl
              type="tel"
              label={t("global:labels.CHF_month")}
              id={this.type_user}
              onBlur={this.handleOnBlur}
              onChange={this.handleChange}
              inputState={inputState}
              hint={inputHint}
              value={viewStore.prettifyNumber(userStore[this.type_user])}
              isLast={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

QuestionNetIncome.propTypes = {
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation("step-5")(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionNetIncome))
);
