import axios from "axios";

import { trackApiError } from "../helpers/trackingHelper";
import { submit_application_url } from "../lib/Constants";

// TODO migrate to fetch api
export function sendIndusData(data) {
  return new Promise((resolve, reject) => {
    const config = {
      headers: { "Content-Type": "application/json;charset=ISO-8859-1" },
    };

    try {
      axios
        .post(submit_application_url, data, config)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject({ message: "no response.data set" });
          }
        })
        .catch((e) => {
          trackApiError("application", "post", e.response && e.response.status);
          reject(e);
        });
    } catch (e) {
      trackApiError("application", "post", e.response && e.response.status);
      reject(e);
    }
  });
}
