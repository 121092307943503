import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint/index";
import RadioButtonGroup from "../../../styleguides/Styleguide-RadioButtonGroup/index";
import SVGIcon from "../../../styleguides/Styleguide-SVGIcon/index";
import Tooltip from "../../../styleguides/Styleguide-Tooltip/index";
import QuestionExtraIncomeSource from "./QuestionExtraIncomeSource";

class ExtraIncome extends React.Component {
  constructor(props) {
    super(props);
    this.type_user_ToF = props.isPartner
      ? "hasExtraIncomePartner"
      : "hasExtraIncome";
    this.type_user = props.isPartner
      ? "extraIncomeSourcePartner"
      : "extraIncomeSource";
    this.contentPartner = props.isPartner ? ".Partner" : "";

    if (
      !!props.userStore[this.type_user_ToF] &&
      !props.userStore[this.type_user].length
    )
      this.state = { extraIncomes: 1 };
    else
      this.state = {
        extraIncomes: props.userStore[this.type_user].length,
      };

    this.addExtraIncome = this.addExtraIncome.bind(this);
    this.removeExtraIncome = this.removeExtraIncome.bind(this);
  }

  addExtraIncome() {
    this.setState((state) => ({ extraIncomes: state.extraIncomes + 1 }));
  }

  removeExtraIncome() {
    const { userStore, isPartner } = this.props;
    userStore.removeExtraIncome(this.state.extraIncomes - 1, isPartner);
    this.setState((state) => ({ extraIncomes: state.extraIncomes - 1 }));
  }

  ExtraIncome_Y_N(value) {
    const { userStore, isPartner } = this.props;
    userStore.setHasExtraIncome(value, isPartner);

    if (value) this.addExtraIncome();
    else {
      // clean the local counter
      this.setState({
        extraIncomes: userStore.getNumberOfExtraIncomes(isPartner),
      });
      this.props.viewStore.validateCustomerIncomeForm();
    }
  }

  render() {
    const { t, userStore, viewStore, isPartner } = this.props;
    let extraIncomeElements = [];

    for (let i = 0; i < this.state.extraIncomes; i++) {
      extraIncomeElements.push(
        <React.Fragment key={i}>
          <QuestionExtraIncomeSource
            {...{ isPartner }}
            id_key={i}
            isInModal={this.props.isInModal}
          />
        </React.Fragment>
      );
    }

    const invalid =
      viewStore.shouldCustomerIncomeFormValidate &&
      userStore.hasExtraIncome === null;
    const invalidPartner =
      viewStore.shouldCustomerIncomeFormValidate &&
      userStore.hasExtraIncomePartner === null;

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": isPartner ? invalidPartner : invalid,
    });

    const yesno = {
      radioGroupName: `has_extra_income${isPartner ? "_partner" : ""}`,
      list: [
        {
          value: true,
          label: t("global:labels.yes"),
        },
        {
          value: false,
          label: t("global:labels.no"),
        },
      ],
    };

    return (
      <React.Fragment>
        <div className="l-step-item">
          <div className="m-info-block">
            <div className="m-info-block__head">
              <label className={titleClasses}>
                {t(
                  `step-5:components:QuestionExtraIncome${this.contentPartner}.questionHasIncome`
                )}
              </label>
              {!this.props.isInModal && (
                <Tooltip
                  tooltip={t("step-5:components.QuestionExtraIncome.tooltip")}
                />
              )}
            </div>
            {yesno && yesno.list.length > 0 && (
              <div className="m-info-block__pattern">
                <RadioButtonGroup
                  doNotTrack={isPartner}
                  fields={yesno}
                  selectedValue={userStore[this.type_user_ToF]}
                  onChange={(event) =>
                    this.ExtraIncome_Y_N(JSON.parse(event.currentTarget.value))
                  }
                  invalid={isPartner ? invalidPartner : invalid}
                />
                {((!isPartner && invalid) || (isPartner && invalidPartner)) && (
                  <FormValidationHint>
                    {t("step-5:components.QuestionExtraIncome.hintYN")}
                  </FormValidationHint>
                )}
              </div>
            )}
          </div>
        </div>

        {extraIncomeElements}

        {!!userStore[this.type_user_ToF] && this.state.extraIncomes < 5 && (
          <React.Fragment>
            <div
              className="a-nexus a-nexus--load-more"
              onClick={this.addExtraIncome}
              onKeyDown={this.addExtraIncome}
              tabIndex="0"
            >
              <span className="a-nexus__icon-left">
                <SVGIcon icon="plus" />
              </span>
              <span className="a-nexus__text">
                {t(
                  `step-5:components.QuestionExtraIncome${this.contentPartner}.AddJob`
                )}
              </span>
            </div>
          </React.Fragment>
        )}

        {this.state.extraIncomes > 1 && (
          <div
            className="a-nexus a-nexus--load-more"
            onClick={() => this.removeExtraIncome(isPartner)}
            onKeyDown={(e) =>
              e.keyCode === 13 ? this.removeExtraIncome(isPartner) : null
            }
            tabIndex="0"
          >
            <span className="a-nexus__icon-left">
              <SVGIcon icon="minus" />
            </span>
            <span className="a-nexus__text">
              {t("step-5:components.QuestionExtraIncome.RemoveJob")}
            </span>
          </div>
        )}
      </React.Fragment>
    );
  }
}

ExtraIncome.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation("step-5")(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(ExtraIncome))
);
