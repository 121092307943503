import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { trackStep } from "../../../helpers/trackingHelper";
import { ROUTENAME_STEP_3, ROUTENAME_STEP_5 } from "../../../lib/Constants";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWrapper,
} from "../../styleguides/Styleguide-Button/index";
import FormValidationHint from "../../styleguides/Styleguide-FormValidationHint/index";
import Layout from "../../styleguides/Styleguide-Layout";
import SVGIcon from "../../styleguides/Styleguide-SVGIcon/index";
import QuestionAccomodation from "./QuestionAccomodation";
import QuestionNationality from "./QuestionNationality";
import QuestionResidence from "./QuestionResidence";

class CustomerAccommodation extends React.Component {
  constructor(props) {
    super(props);
    this.validateAndSendData = this.validateAndSendData.bind(this);
  }

  componentDidMount() {
    const { viewStore, history } = this.props;

    if (
      !(
        viewStore.get_step_validated() < 12 &&
        viewStore.get_step_validated() >= 3
      )
    ) {
      history.push(viewStore.getLocalizedPath(ROUTENAME_STEP_3));
    }
    i18next.loadNamespaces("step-5");
  }
  validateAndSendData(event) {
    const { viewStore } = this.props;

    // Check if all required data are filled out in this step (only the viewStore knows that!)
    if (!viewStore.validateCustomerAccommodationForm()) {
      event.preventDefault();
      viewStore.customerAccommodationFormShouldValidate(true);
    } else {
      viewStore.save_session(4);
      trackStep(3);
    }
  }

  render() {
    const { viewStore, t } = this.props;
    const layout_props = {
      pageTitle: "step-4:pageTitle",
      headlineKey: "step-4:headline", // passing only the key, it's translated within Layout Trans component
      inverted_background: true,
      progressBar: true,
    };

    return (
      <Layout data={layout_props}>
        <div className="l-step-group">
          <QuestionNationality gaTitle={t("step-4:pageTitle")} />
          <QuestionResidence gaTitle={t("step-4:pageTitle")} />
          <QuestionAccomodation gaTitle={t("step-4:pageTitle")} />

          {viewStore.should_step_4_validate &&
            !viewStore.validateCustomerAccommodationForm() && (
              <FormValidationHint>
                {t("global:labels.hint_step")}
              </FormValidationHint>
            )}
          <ButtonWrapper>
            <ButtonPrimary
              to={viewStore.getLocalizedPath(ROUTENAME_STEP_5)}
              onClick={this.validateAndSendData}
            >
              <span className="a-nexus__text">{t("step-4:next_button")}</span>
            </ButtonPrimary>
            <ButtonSecondary to={viewStore.getLocalizedPath(ROUTENAME_STEP_3)}>
              <span className="a-nexus__icon-left">
                <SVGIcon icon="back-arrow" />
              </span>
              <span className="a-nexus__text">
                {t("global:labels.back_button")}
              </span>
            </ButtonSecondary>
          </ButtonWrapper>
        </div>
      </Layout>
    );
  }
}

CustomerAccommodation.propTypes = {
  viewStore: PropTypes.object,
  loanStore: PropTypes.object,
  userStore: PropTypes.object,
  t: PropTypes.func,
};

export default withRouter(
  withTranslation("step-4")(
    inject(({ stores }) => ({
      viewStore: stores.view,
      loanStore: stores.loan,
      userStore: stores.user,
    }))(observer(CustomerAccommodation))
  )
);
