import { types } from "mobx-state-tree";

import { LoanStore } from "./LoanStore";
import { UserStore } from "./UserStore";
import { ViewStore } from "./ViewStore";

export const RootStore = types
  .model("RootStore", {
    user: types.optional(UserStore, {}),
    view: types.optional(ViewStore, {}),
    loan: types.optional(LoanStore, {}),
  })
  .actions((self) => ({
    reset() {
      self.user = {};
      self.loan = {};
    },
    resetUserStore(userStore) {
      self.user = userStore;
    },
  }));
