import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { isLatin1 } from "../../../../helpers/validationHelper";
import FormControl from "../../../styleguides/Styleguide-FormControl/index";

class QuestionEmployerName extends React.Component {
  constructor(props) {
    super(props);

    this.isPartner = props.isPartner ? true : false;
    this.content_both = "step-12:components.QuestionEmployerName";
    this.content = this.isPartner
      ? "step-12:components.QuestionEmployerName.partner"
      : "step-12:components.QuestionEmployerName";

    this.type_user = "employerName";

    if (this.isPartner) {
      this.type_user = "employerNamePartner";
    }
  }

  handleOnChangeEmployerNameInput = (value) => {
    const { userStore } = this.props;

    if (value.length > 20 || !isLatin1(value)) {
      return null;
    }

    userStore.setEmployerName(value, this.isPartner);
  };

  render() {
    const { viewStore, userStore, t } = this.props;

    let inputState, inputHint;

    if (!userStore[this.type_user] && viewStore.should_step_12_validate) {
      inputState = "error";
      inputHint = this.isPartner
        ? t(this.content + ".hint")
        : t(this.content_both + ".hint");
    }

    if (viewStore.should_step_12_validate && inputState !== "error") {
      viewStore.validateProfessionalDataForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": inputState === "error",
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <label className={titleClasses}>
              {t(this.content + ".question")}
            </label>
          </div>
        </div>
        <div className="m-info-block__pattern">
          <FormControl
            componentClass="input"
            id={this.type_user}
            autoComplete={!this.isPartner ? "company" : "noAutocomplete"}
            label={t(this.content + ".placeholder")}
            value={userStore[this.type_user] || ""}
            onChange={this.handleOnChangeEmployerNameInput}
            onBlur={this.handleOnBlurEmployerNameInput}
            inputState={inputState}
            hint={inputHint}
            isLast={true}
          />
        </div>
      </div>
    );
  }
}

QuestionEmployerName.propTypes = {
  t: PropTypes.func.isRequired,
  userStore: PropTypes.object.isRequired,
  viewStore: PropTypes.object.isRequired,
};

export default withTranslation("step-12")(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionEmployerName))
);
