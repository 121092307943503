import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint";
import RadioButtonGroup from "../../../styleguides/Styleguide-RadioButtonGroup";

class QuestionContactLanguage extends React.Component {
  handleLanguageChange = (event) => {
    if (!event.currentTarget || !event.currentTarget.value) {
      return false;
    }
    this.props.userStore.setCustomerContactLanguage(event.currentTarget.value);
  };

  render() {
    const { t, viewStore, userStore } = this.props;
    const invalid =
      viewStore.shouldCustomerContactFormValidate &&
      userStore.customerContactLanguage === null;

    if (viewStore.shouldCustomerContactFormValidate && invalid === false) {
      viewStore.validateCustomerContactForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid,
    });

    // TODO (27.03.2019 mariusz) this should be refactored along with HeaderMain langs after then language/urls handling is fixed
    const langList = [
      {
        label: t("global:header.options.german"),
        value: "de",
      },
      {
        label: t("global:header.options.french"),
        value: "fr",
      },
      {
        label: t("global:header.options.italian"),
        value: "it",
      },
    ];

    // TODO (27.03.2019 mariusz) RadioButtonGroup should also be refactored
    const radioButtonGroupData = {
      list: langList,
      radioGroupName: "correspondence_language",
    };

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <span className={titleClasses}>
              {t("step-10:components.QuestionContactLanguage.question")}
            </span>
          </div>
          <div className="m-info-block__pattern">
            <RadioButtonGroup
              fields={radioButtonGroupData}
              onChange={this.handleLanguageChange}
              selectedValue={userStore.customerContactLanguage}
              invalid={invalid}
            />
            {invalid && (
              <FormValidationHint>
                {t("step-10:components.QuestionContactLanguage.hint")}
              </FormValidationHint>
            )}
          </div>
        </div>
      </div>
    );
  }
}

QuestionContactLanguage.propTypes = {
  t: PropTypes.func.isRequired,
  userStore: PropTypes.object.isRequired,
  viewStore: PropTypes.object.isRequired,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionContactLanguage))
);
