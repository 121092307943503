import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { trackStep } from "../../../helpers/trackingHelper";
import {
  ROUTENAME_STEP_11,
  ROUTENAME_STEP_9,
  ROUTENAME_STEP_7,
} from "../../../lib/Constants";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWrapper,
} from "../../styleguides/Styleguide-Button";
import FormValidationHint from "../../styleguides/Styleguide-FormValidationHint";
import Layout from "../../styleguides/Styleguide-Layout";
import SVGIcon from "../../styleguides/Styleguide-SVGIcon";
import QuestionContactAcceptTerms from "./QuestionContactAcceptTerms";
import QuestionContactEmail from "./QuestionContactEmail";
import QuestionContactLanguage from "./QuestionContactLanguage";
import QuestionContactPhone from "./QuestionContactPhone";

class CustomerContact extends React.Component {
  constructor(props) {
    super(props);
    this.validateAndSendData = this.validateAndSendData.bind(this);
  }

  componentDidMount() {
    const { viewStore, history } = this.props;
    if (
      !(
        viewStore.get_step_validated() < 12 &&
        viewStore.get_step_validated() >= 7
      )
    ) {
      history.push(
        this.props.userStore.step9skipped
          ? viewStore.getLocalizedPath(ROUTENAME_STEP_7)
          : viewStore.getLocalizedPath(ROUTENAME_STEP_9)
      );
    }
    this.props.viewStore.customerContactFormShouldValidate(false);
    i18next.loadNamespaces("step-11");
  }

  validateAndSendData(event) {
    const { viewStore } = this.props;

    // Check if all required data are filled out in this step (only the viewStore knows that!)
    if (!viewStore.validateCustomerContactForm()) {
      event.preventDefault();
      viewStore.customerContactFormShouldValidate(true);
    } else {
      viewStore.save_session(10);
      trackStep(8);
    }
  }

  render() {
    const { t, viewStore } = this.props;

    const layout_props = {
      pageTitle: "step-10:pageTitle", // passing only the key, it's translated within Layout component
      headlineKey: "step-10:headline", // passing only the key, it's translated within Layout Trans component
      inverted_background: true,
      progressBar: true,
    };

    return (
      <Layout data={layout_props}>
        <div className="l-step-group">
          <QuestionContactPhone />
          <QuestionContactEmail />
          <QuestionContactAcceptTerms />
          {i18next.resolvedLanguage === "en" && <QuestionContactLanguage />}
          {viewStore.shouldCustomerContactFormValidate &&
            !viewStore.validateCustomerContactForm() && (
              <FormValidationHint>
                {t("global:labels.hint_step")}
              </FormValidationHint>
            )}
          <ButtonWrapper>
            <ButtonPrimary
              to={viewStore.getLocalizedPath(ROUTENAME_STEP_11)}
              onClick={(e) => this.validateAndSendData(e)}
            >
              <span className="a-nexus__text">{t("step-10:next_button")}</span>
            </ButtonPrimary>
            <ButtonSecondary
              to={
                this.props.userStore.step9skipped
                  ? viewStore.getLocalizedPath(ROUTENAME_STEP_7)
                  : viewStore.getLocalizedPath(ROUTENAME_STEP_9)
              }
            >
              <span className="a-nexus__icon-left">
                <SVGIcon icon="back-arrow" />
              </span>
              <span className="a-nexus__text">
                {t("global:labels.back_button")}
              </span>
            </ButtonSecondary>
          </ButtonWrapper>
        </div>
      </Layout>
    );
  }
}

CustomerContact.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withRouter(
  withTranslation("step-10")(
    inject(({ stores }) => ({
      viewStore: stores.view,
      userStore: stores.user,
    }))(observer(CustomerContact))
  )
);
