import axios from "axios";

import { trackApiError } from "../helpers/trackingHelper";
import { address_url } from "../lib/Constants";

// TODO migrate to fetch api
export function getAddressByZIP(zip) {
  return new Promise((resolve, reject) => {
    try {
      let zips = [];

      axios
        .get(`${address_url}towns?zip=${zip}`)
        .then((response) => {
          if (response.data) {
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i]["canton"] !== "FL") {
                zips.push(response.data[i]);
              }
            }
            resolve(zips);
          } else {
            reject({ message: "no response.data set" });
          }
        })
        .catch((e) => {
          trackApiError("postal_towns", "get", e.response && e.response.status);
          reject(e);
        });
    } catch (e) {
      trackApiError("postal_towns", "get", e.response && e.response.status);
      reject(e);
    }
  });
}

// TODO migrate to fetch api
export function getStreetNamesByZIPandName(zip, name) {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(`${address_url}streets?zip=${zip}&name=${name}`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }
          reject({ message: "no response.data set" });
        })
        .catch((e) => {
          trackApiError(
            "postal_streets",
            "get",
            e.response && e.response.status
          );
          reject(e);
        });
    } catch (e) {
      trackApiError("postal_streets", "get", e.response && e.response.status);
      reject(e);
    }
  });
}
