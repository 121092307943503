export const KEY_HOUSEWIFE = "house_wife"; // TODO change to housewife
export const KEY_NOT_WORKING = "not_working";

const i18nBaseKey = "step-5:components.QuestionJobSituation";

export const employmentStatus = [
  {
    value: 0,
    i18nKey: `${i18nBaseKey}.not_working`,
    key: "not_working",
    order: 5,
  },
  {
    value: 2,
    i18nKey: `${i18nBaseKey}.employed`,
    key: "employed",
    order: 1,
  },
  {
    value: 3,
    i18nKey: `${i18nBaseKey}.manager`,
    key: "manager",
    order: 3,
  },
  {
    value: 6,
    i18nKey: `${i18nBaseKey}.retired`,
    key: "retired",
    order: 7,
  },
  {
    value: 8,
    i18nKey: `${i18nBaseKey}.temporary`,
    key: "temporary",
    order: 4,
  },
  {
    value: 9,
    i18nKey: `${i18nBaseKey}.independent`,
    key: "independent",
    order: 2,
  },
  {
    value: 100,
    i18nKey: `${i18nBaseKey}.house_wife`,
    key: "house_wife",
    order: 6,
  },
];

/**
 * @param {Function|null} t
 * @returns {Array<Object>} Ordered list of employment status, extended by localized
 *    label if t was specified.
 */
export const getEmploymentStatus = (t) =>
  employmentStatus
    .map((e) => {
      const copy = Object.assign({}, e);
      if (t) copy.label = t(copy.i18nKey);
      return copy;
    })
    .sort((a, b) => a.order - b.order);

/**
 * @param {number} value
 * @returns {string|null}
 */
export const getKeyByValue = (value) => {
  const e = employmentStatus.find((e) => e.value === value);
  return e ? e.key : null;
};
