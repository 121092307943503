import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { getAccommodations } from "../../../../data/accommodationData";
import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint/index";
import RadioButtonGroup from "../../../styleguides/Styleguide-RadioButtonGroup/index";

class QuestionAccomodation extends React.Component {
  constructor(props) {
    super(props);

    this.content = "step-4:components.QuestionAccomodation";
  }

  render() {
    const { userStore, viewStore, t } = this.props;

    const living_status_list = {
      radioGroupName: "residential_status",
      list: getAccommodations(t),
    };
    const invalid =
      viewStore.should_step_4_validate && userStore.livingStatus === null;

    if (viewStore.should_step_4_validate && !invalid) {
      viewStore.validateCustomerAccommodationForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid,
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <label className={titleClasses}>
              {t(this.content + ".country_placeholder")}
            </label>
          </div>
          <div className="m-info-block__pattern">
            <RadioButtonGroup
              fields={living_status_list}
              onChange={(event) =>
                userStore.setLivingStatus(Number(event.currentTarget.value))
              }
              selectedValue={userStore.livingStatus}
              invalid={invalid}
            />
            {invalid && (
              <FormValidationHint>
                {t(this.content + ".hint")}
              </FormValidationHint>
            )}
          </div>
        </div>
      </div>
    );
  }
}

QuestionAccomodation.propTypes = {
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation("step-4")(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionAccomodation))
);
