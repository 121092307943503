const onFulfilled = async (response) => {
  const res = { response };
  try {
    const json = await response.json();
    if (response.ok) {
      res.data = json;
    } else {
      res.error = json;
    }
  } catch (e) {
    // responses without body land here
    if (!response.ok) {
      res.error = { message: "Fetch failed w/o body" };
    }
  }
  return res;
};

const onRejected = async (reason) => ({
  error: { message: typeof reason === "string" ? reason : "Fetch failed" },
});

/**
 * HTTP GET via Fetch API.
 * @param {string} url
 * @param {  boolean } followRedirect Whether to follow redirects like 301, 302, etc. automatically.
 * @returns {Promise<Object>} {data?, error?, response?}
 */
export const get = async (url, followRedirect = false) => {
  const redirect = followRedirect ? "follow" : "manual";
  return fetch(url, { redirect }).then(onFulfilled, onRejected);
};
