import cn from "classnames";
import Popper from "popper.js";
import PropTypes from "prop-types";
import React from "react";

import SVGIcon from "../Styleguide-SVGIcon";

const TOOLTIP_ICON_WIDTH_PX = 16;

class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: this.props.showTooltip,
      tooltipArrowPosPx: undefined,
    };
    this.tooltipBoxRef = React.createRef();
    this.tooltipIconRef = React.createRef();
    this.handleIconClick = this.handleIconClick.bind(this);
    this.closeTooltip = this.closeTooltip.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.closeAllTooltips = this.closeAllTooltips.bind(this);
    this.setArrowPos = this.setArrowPos.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.setArrowPos);

    if (this.tooltipIconRef.current && this.tooltipBoxRef.current) {
      new Popper(this.tooltipIconRef.current, this.tooltipBoxRef.current, {
        placement: "bottom",
        modifiers: {
          flip: {
            enabled: true,
            behaviour: "flip",
          },
          preventOverflow: {
            boundariesElement: this.tooltipIconRef.current.closest("#app-root"),
          },
          offset: {
            offset: "0px, 12px",
          },
          computeStyle: {
            gpuAcceleration: false, // blurry tooltip on zoom-out https://github.com/FezVrasta/popper.js/issues/682
          },
        },
      });
    }

    document.body.addEventListener("click", this.closeAllTooltips);
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction);
    window.removeEventListener("resize", this.setArrowPos);
    document.body.removeEventListener("click", this.closeAllTooltips);
  }

  handleIconClick(event) {
    event.stopPropagation();
    event.preventDefault();
    this.closeAllTooltips(event);
    this.setState({ showTooltip: !this.state.showTooltip });
    this.setArrowPos();
  }

  setArrowPos() {
    if (this.tooltipIconRef.current && this.tooltipBoxRef.current) {
      const iconRect = this.tooltipIconRef.current.getBoundingClientRect();
      const tooltipBoxRect = this.tooltipBoxRef.current.getBoundingClientRect();
      this.setState({
        tooltipArrowPosPx: `${Math.floor(
          iconRect.left - tooltipBoxRect.left + TOOLTIP_ICON_WIDTH_PX / 2
        )}px`,
      });
    }
  }

  closeTooltip(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ showTooltip: false });
  }

  closeAllTooltips(event) {
    if (
      !event.target.getAttribute("class") ||
      event.target.getAttribute("class").indexOf("tooltip") < 0 ||
      event.target.getAttribute("class") === "m-tooltip__open"
    ) {
      this.setState({ showTooltip: false });
    }
  }

  escFunction(event) {
    if (event.keyCode === 27 && this.state.showTooltip) {
      this.setState({ showTooltip: false });
    }
  }

  makeHtml(text) {
    return { __html: text };
  }

  render() {
    // Do not render if tooltip is empty
    if (!this.props.tooltip || this.props.tooltip.value === "") {
      return null;
    }

    const tooltipClasses = cn({
      "m-tooltip": true,
      "state-m-tooltip--active": this.state.showTooltip,
    });

    const tooltipArrowStyle = {
      left: this.state.tooltipArrowPosPx,
    };

    return (
      <div className={tooltipClasses}>
        <span
          className="m-tooltip__open"
          onClick={this.handleIconClick}
          ref={this.tooltipIconRef}
        >
          <SVGIcon icon="tooltip" />
        </span>
        <div className="m-tooltip__box" ref={this.tooltipBoxRef}>
          <div className="m-tooltip__flex">
            <div className="m-tooltip__action">
              <span className="m-tooltip__close" onClick={this.closeTooltip}>
                <SVGIcon icon="clear" />
              </span>
            </div>
            <span className="m-tooltip__arrow" style={tooltipArrowStyle} />
            <span
              className="m-tooltip__text"
              dangerouslySetInnerHTML={this.makeHtml(this.props.tooltip)}
            ></span>
          </div>
        </div>
      </div>
    );
  }
}

Tooltip.propTypes = {
  showTooltip: PropTypes.bool,
  tooltip: PropTypes.string.isRequired,
};

Tooltip.defaultProps = {
  showTooltip: false,
};

export default Tooltip;
