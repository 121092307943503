import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";

import { FormControlCheckbox } from "../../../styleguides/Styleguide-FormControl";
import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint";
import Overlay from "../../../styleguides/Styleguide-Overlay";
import ModalPortal from "../../../utils/ModalPortal";

class QuestionContactAcceptTerms extends Component {
  state = {
    showModal: false,
    lastFocus: null,
  };

  openModal = (event) => {
    event.preventDefault();
    this.setState({ showModal: true, lastFocus: document.activeElement });
  };

  onModalClose = (event) => {
    this.setState({ showModal: false });
    this.state.lastFocus.focus();
  };

  render() {
    const { viewStore, userStore, t } = this.props;
    const invalid =
      viewStore.shouldCustomerContactFormValidate &&
      !userStore.customerAcceptedTerms;

    if (viewStore.shouldCustomerContactFormValidate && invalid === false) {
      viewStore.validateCustomerContactForm();
    }

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__pattern">
            <div className="a-checkbox">
              <FormControlCheckbox
                id="acceptTerms"
                name="oos"
                onChange={(e) =>
                  userStore.setCustomerAcceptedTerms(e.currentTarget.checked)
                }
                defaultChecked={userStore.customerAcceptedTerms}
                invalid={invalid}
                labelContent={
                  <span style={{ width: "80%" }}>
                    <Trans
                      i18nKey={
                        "step-10:components.QuestionContactAcceptTerms.description"
                      }
                    >
                      {/* prettier-ignore */}#{/* prettier-ignore */}
                      <a href="#ignore" onClick={this.openModal}>#</a>
                      {/* prettier-ignore */}#
                    </Trans>
                  </span>
                }
              />
              {invalid && (
                <FormValidationHint>
                  {t("step-10:components.QuestionContactAcceptTerms.hint")}
                </FormValidationHint>
              )}
            </div>
            {this.state.showModal && (
              <ModalPortal>
                <Overlay
                  isOpen={this.state.showModal}
                  id="acceptTerms"
                  onClose={this.onModalClose}
                  title={t(
                    "step-10:components.QuestionContactAcceptTerms.disclaimerTitle"
                  )}
                >
                  {/*TODO (26.03.2019 mariusz_) sanitize */}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "step-10:components.QuestionContactAcceptTerms.disclaimer"
                      ),
                    }}
                  />
                </Overlay>
              </ModalPortal>
            )}
          </div>
        </div>
      </div>
    );
  }
}

QuestionContactAcceptTerms.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionContactAcceptTerms))
);
