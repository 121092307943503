import PropTypes from "prop-types";
import React from "react";

import SVGIcon from "../Styleguide-SVGIcon/index";
import { PulldownItem } from "./PulldownItem";

const Pulldown = (props) => {
  return (
    <div className="m-pulldown state-m-pulldown--open">
      <ul className="m-pulldown__links">
        {props.data.map((item, i) => {
          return (
            <li
              key={i}
              className={`m-pulldown__link-item ${
                item.id === props.selectedItemId &&
                "state-m-pulldown__link-item--selected"
              }`}
            >
              <a
                href={item.target ? item.target : "/#"}
                className="a-link"
                onClick={(e) => props.onItemSelect(e, item.id)}
              >
                {item.label}
              </a>
            </li>
          );
        })}
      </ul>
      <div className="m-pulldown__actions">
        <span className="a-nexus" onClick={props.onCloseClick}>
          <span className="a-nexus__icon-left">
            <SVGIcon icon="clear" />
          </span>
        </span>
      </div>
    </div>
  );
};

Pulldown.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(PulldownItem)).isRequired,
  onItemSelect: PropTypes.func.isRequired,
  selectedItemId: PropTypes.string,
  onCloseClick: PropTypes.func,
};

export default Pulldown;
