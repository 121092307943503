import PropTypes from "prop-types";
import React from "react";

import FormControl from "../Styleguide-FormControl/index";

class CountrySelector extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    if (this.props.handleChange) {
      this.props.handleChange(value);
    }
  }

  render() {
    const {
      options,
      placeholder,
      defaultValue,
      wrapperClassNames,
      invalid,
      parentBlockRef,
    } = this.props;
    let value;
    if (defaultValue && defaultValue !== "__other") {
      value = options.find((item) => item.value === defaultValue);
    }
    return (
      <FormControl
        componentClass="select"
        options={options}
        onChange={({ value }) => this.handleChange(value)}
        value={value}
        placeholder={placeholder}
        wrapperClassNames={wrapperClassNames}
        invalid={invalid}
        parentBlockRef={parentBlockRef}
      />
    );
  }
}

CountrySelector.propTypes = {
  parentBlockRef: PropTypes.object,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  defaultValue: PropTypes.string,
  wrapperClassNames: PropTypes.string,
};

export default CountrySelector;
