/**
 * @param {string} value
 * @returns {boolean} Whether value is valid email.
 */
export const isEmail = (value) => {
  return /^\w+([.-]?\w)*@\w+([.-]?\w)*(\.[a-zA-Z]{2,3})$/.test(value);
};

/**
 * @param {string} value
 * @returns {boolean} Whether value only contains Latin-1 chars. Empty,
 *   undefined or null values are considered valid.
 */
export const isLatin1 = (value) => {
  return !/[^\u0020-\u00ff]/g.test(value);
};
