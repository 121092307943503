import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { ExpensesHousingCosts as expensesLimits } from "../../../../lib/Constants";
import FormControl from "../../../styleguides/Styleguide-FormControl";
import Tooltip from "../../../styleguides/Styleguide-Tooltip";

class QuestionHousingCosts extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);

    this.state = {
      validate: false,
    };
  }

  handleChange(value) {
    const { userStore, viewStore } = this.props;
    value = viewStore.validate_number(value);
    if (value !== "err") userStore.setExpenses_costs_housing(value);

    this.validate = true;
  }

  handleOnBlur() {
    this.setState({ validate: true });
  }

  render() {
    const { t, viewStore, userStore } = this.props;
    let inputState, inputHint;

    let value = userStore.expenses_costs_housing;
    if (viewStore.shouldCustomerExpensesFormValidate || this.state.validate) {
      if (value < expensesLimits.min || value > expensesLimits.max) {
        inputState = "error";
        inputHint = t(
          "step-6:components.QuestionHousingCosts.hint_min_max_value"
        );
      } else if (value > expensesLimits.plausibility) {
        inputState = "warn";
        inputHint = t(
          "step-6:components.QuestionHousingCosts.plausibility_error"
        );
      }

      if (
        inputState !== "error" &&
        viewStore.shouldCustomerExpensesFormValidate
      ) {
        viewStore.validateCustomerExpensesForm();
      }
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": inputState === "error",
    });

    return (
      <div className="l-step-item">
        <div className="m-info-block">
          <div className="m-info-block__head">
            <span className={titleClasses}>
              {t("step-6:components:QuestionHousingCosts:question")}
            </span>
            {!this.props.isInModal && (
              <Tooltip
                nameID={"expenses_house"}
                tooltip={t("step-6:components:QuestionHousingCosts:tooltip")}
              />
            )}
          </div>
          {t("step-6:components:QuestionHousingCosts:description") &&
            t("step-6:components:QuestionHousingCosts:description") !== "" && (
              <span className="m-info-block__lead">
                {t("step-6:components:QuestionHousingCosts:description")}
              </span>
            )}

          <div className="m-info-block__pattern">
            <FormControl
              type="tel"
              label={t("global:labels.CHF_month")}
              id="housing_costs"
              onChange={this.handleChange}
              onBlur={this.handleOnBlur}
              inputState={inputState}
              hint={inputHint}
              value={viewStore.prettifyNumber(userStore.expenses_costs_housing)}
              isLast={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

QuestionHousingCosts.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionHousingCosts))
);
