import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import FormControl from "../Styleguide-FormControl";

class TimeDropdowns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chosenDate: {
        month: props.defaultDate
          ? moment(props.defaultDate, "YYYY-MM-DD").month() + 1
          : null,
        year: props.defaultDate
          ? moment(props.defaultDate, "YYYY-MM-DD").year()
          : null,
      },
    };

    this.setTimeValue = this.setTimeValue.bind(this);
  }

  getMonthLabel(month) {
    const labels = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    return labels[month];
  }

  setTimeValue(type, value) {
    const chosenDate = this.state.chosenDate;
    chosenDate[type] = this.leadingZero(value);
    this.setState({ chosenDate }, () => {
      if (
        this.props.onDateChange &&
        this.state.chosenDate.month !== null &&
        this.state.chosenDate.year !== null
      ) {
        this.props.onDateChange(
          moment(
            `${this.state.chosenDate.year}-${this.state.chosenDate.month}-01`
          ).format("YYYY-MM-DD")
        );
      }
    });
  }

  leadingZero(number) {
    if (String(number).length === 1) {
      return `0${number}`;
    }
    return number;
  }

  render() {
    const { defaultDate, t, minDate, maxDate, invalid, parentBlockRef } =
      this.props;
    let defaultMonth;
    let defaultYear;
    if (defaultDate && moment(defaultDate).isValid()) {
      const defDate = moment(defaultDate);
      defaultMonth = defDate.month() + 1;
      defaultYear = defDate.year();
    }

    // Month dropdown
    const monthOptions = [];
    let monthDefaultValue;
    for (let i = 1; i <= 12; i++) {
      const label = this.getMonthLabel(i - 1);
      const obj = { value: i, label };
      monthOptions.push({ value: i, label });
      // Set default if give
      if (defaultMonth === i) {
        monthDefaultValue = obj;
      }
    }
    // Year dropdown
    const yearOptions = [];
    const minYear = moment(minDate).year();
    const maxYear = moment(maxDate).year();
    let yearDefaultValue;
    for (let i = maxYear; i >= minYear; i--) {
      const obj = { value: i, label: i };
      yearOptions.push(obj);
      // Set default if given
      if (defaultYear === i) {
        yearDefaultValue = obj;
      }
    }
    return (
      <React.Fragment>
        <FormControl
          componentClass="select"
          type={"tel"}
          options={monthOptions}
          onChange={({ value }) => this.setTimeValue("month", value)}
          placeholder={t("global:labels:month")}
          invalid={invalid}
          value={monthDefaultValue}
          parentBlockRef={parentBlockRef}
        />

        <FormControl
          componentClass="select"
          type={"tel"}
          options={yearOptions}
          onChange={({ value }) => this.setTimeValue("year", value)}
          placeholder={t("global:labels:year")}
          invalid={invalid}
          value={yearDefaultValue}
          parentBlockRef={parentBlockRef}
        />
      </React.Fragment>
    );
  }
}

TimeDropdowns.propTypes = {
  defaultDate: PropTypes.string,
  maxDate: PropTypes.string,
  maxYears: PropTypes.string,
  minDate: PropTypes.string,
  parentBlockRef: PropTypes.object,
  t: PropTypes.func,
  onDateChange: PropTypes.func,
};

TimeDropdowns.defaultProps = {
  minDate: moment().subtract(65, "years").format("YYYY-MM-DD"),
  maxDate: moment().format("YYYY-MM-DD"),
};

export default withTranslation()(TimeDropdowns);
