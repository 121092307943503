import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { ButtonWrapper } from "../../../styleguides/Styleguide-Button/index";
import ExtraIncome from "../../step-5/ExtraIncome";
import QuestionNetIncome from "../../step-5/NetIncome/QuestionNetIncome";
import QuestionNetIncomeFrequency from "../../step-5/NetIncome/QuestionNetIncomeFrequency";
import QuestionAdditionalExpensesCosts from "../../step-6/QuestionAdditionalExpensesCosts";
import QuestionAlimonyCosts from "../../step-6/QuestionAlimonyCosts";
import QuestionExternalFoodCosts from "../../step-6/QuestionFoodCosts";
import QuestionHousingCosts from "../../step-6/QuestionHousingCosts";
import QuestionMonthlyChildCareCosts from "../../step-6/QuestionMonthlyChildCareCosts";
import QuestionOtherDebtsCosts from "../../step-6/QuestionOtherDebtsCosts";
import QuestionWorkExpensesCosts from "../../step-6/QuestionWorkExpensesCosts";
import QuestionInsuranceCosts from "../../step-7/QuestionInsuranceCosts";

class ModalEditQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // _userStore: JSON.parse(JSON.stringify(props.userStore))
    };
    this.type = null;
  }

  closeModal(value) {
    if (!value) {
      this.props.closeEditQuestion(null);
    } else {
      this.props.closeEditQuestion(value);
    }
  }

  validateQuestion(e) {
    const { viewStore } = this.props;
    e.preventDefault();

    if (this.type === "i") {
      if (viewStore.validateCustomerIncomeForm()) {
        return this.closeModal(true);
      } else {
        viewStore.customerIncomeFormShouldValidate(true);
      }
    } else {
      if (viewStore.validateCustomerExpensesForm()) {
        return this.closeModal(true);
      } else {
        viewStore.customerExpensesFormShouldValidate(true);
      }
    }
  }

  /*cancelChanges() {
		this.props.rootStore.resetUserStore(this.state._userStore)
	}*/

  render() {
    const { t, editQuestion } = this.props;
    let question = "";

    switch (editQuestion) {
      case "applicant_income":
        this.type = "i";
        question = (
          <React.Fragment>
            <QuestionNetIncome isPartner={false} isInModal={true} />
            <QuestionNetIncomeFrequency isPartner={false} />
          </React.Fragment>
        );
        break;
      case "applicant_income_extra_null":
        this.type = "i";
        question = <ExtraIncome isPartner={false} isInModal={true} />;
        break;

      case "partner_income":
        this.type = "i";
        question = (
          <React.Fragment>
            <QuestionNetIncome isPartner={true} isInModal={true} />
            <QuestionNetIncomeFrequency isPartner={true} />
          </React.Fragment>
        );
        break;
      case "partner_income_extra_null":
        this.type = "i";
        question = <ExtraIncome isPartner={true} isInModal={true} />;
        break;

      case "expenses_costs_housing":
        question = <QuestionHousingCosts isInModal={true} />;
        break;
      case "expenses_costs_work":
        question = <QuestionWorkExpensesCosts isInModal={true} />;
        break;
      case "expenses_costs_food":
        question = <QuestionExternalFoodCosts isInModal={true} />;
        break;
      case "expenses_costs_alimony":
        question = <QuestionAlimonyCosts isPartner={false} isInModal={true} />;
        break;
      case "expenses_costs_childcare":
        question = <QuestionMonthlyChildCareCosts isInModal={true} />;
        break;
      case "expenses_costs_otherDebts":
        question = <QuestionOtherDebtsCosts />;
        break;
      case "expenses_costs_additional":
        question = (
          <QuestionAdditionalExpensesCosts isPartner={false} isInModal={true} />
        );
        break;
      case "expenses_pays_alimonyPartner":
        question = <QuestionAlimonyCosts isPartner={true} isInModal={true} />;
        break;
      case "expenses_costs_additionalPartner":
        question = (
          <QuestionAdditionalExpensesCosts isPartner={true} isInModal={true} />
        );
        break;
      case "expenses_costs_insurance":
        question = <QuestionInsuranceCosts />;
        break;
      default:
      // code block
    }

    const wrapperClasses = cn({
      "a-nexus": true,
      "a-nexus--primary": true,
      "state-a-nexus--clicked": false,
    });

    return (
      <span>
        {question}

        <ButtonWrapper>
          <button
            className={wrapperClasses}
            onClick={(e) => this.validateQuestion(e)}
          >
            <span className="a-nexus__text">
              {t("step-7:button_save_edition")}
            </span>
          </button>
        </ButtonWrapper>
      </span>
    );
  }
}

ModalEditQuestions.propTypes = {
  t: PropTypes.func.isRequired,
  userStore: PropTypes.object,
  loanStore: PropTypes.object,
  viewStore: PropTypes.object.isRequired,
};

export default withTranslation("step-7")(
  inject(({ stores }) => ({
    rootStore: stores,
    userStore: stores.user,
    viewStore: stores.view,
    loanStore: stores.loan,
  }))(observer(ModalEditQuestions))
);
