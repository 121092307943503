import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

const DocumentUploadWrapper = ({ children, headlineField, labelField }) => {
  return (
    <div className="l-step-item l-step-item--s">
      <h3 className="a-text">{headlineField}</h3>
      {labelField && <p>{labelField}</p>}
      <div className="m-upload-list">
        <ul className="m-upload-list__wrap">{children}</ul>
      </div>
    </div>
  );
};

DocumentUploadWrapper.propTypes = {
  children: PropTypes.node,
  headlineField: PropTypes.string,
  labelField: PropTypes.string,
};

export default withTranslation("step-13")(DocumentUploadWrapper);
