import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { trackStep } from "../../../helpers/trackingHelper";
import { ROUTENAME_STEP_10, ROUTENAME_STEP_12 } from "../../../lib/Constants";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWrapper,
} from "../../styleguides/Styleguide-Button";
import FormValidationHint from "../../styleguides/Styleguide-FormValidationHint/index";
import Layout from "../../styleguides/Styleguide-Layout";
import SVGIcon from "../../styleguides/Styleguide-SVGIcon/index";
import QuestionApplicantsFullAddress from "./QuestionApplicantsFullAddress";
import QuestionCurrentAddressDuration from "./QuestionCurrentAddressDuration";
import QuestionFirstName from "./QuestionFirstName";
import QuestionLastName from "./QuestionLastName";

class PersonalData extends React.Component {
  componentDidMount() {
    const { viewStore, history } = this.props;
    if (
      !(
        viewStore.get_step_validated() < 12 &&
        viewStore.get_step_validated() >= 10
      )
    ) {
      history.push(viewStore.getLocalizedPath(ROUTENAME_STEP_10));
    }
    i18next.loadNamespaces("step-12");
  }

  validateAndSendData = (event) => {
    const { viewStore } = this.props;

    if (!viewStore.validatePersonalDataForm()) {
      event.preventDefault();
      viewStore.personalDataFormShouldValidate(true);
    } else {
      viewStore.save_session(11);
      trackStep(9);
    }
  };

  render() {
    const { t, viewStore, userStore } = this.props;

    const layout_props = {
      pageTitle: "step-11:pageTitle", // passing only the key, it's translated within Layout Trans component
      headlineKey: "step-11:headline", // passing only the key, it's translated within Layout Trans component
      inverted_background: true,
      progressBar: true,
    };

    return (
      <Layout data={layout_props}>
        <div className="l-step-group">
          <QuestionFirstName isPartner={false} />
          <QuestionLastName isPartner={false} />
          {userStore.showPartnerInfo && (
            <React.Fragment>
              <QuestionFirstName isPartner={true} />
              <QuestionLastName isPartner={true} />
            </React.Fragment>
          )}
          <QuestionApplicantsFullAddress t={t} />
          <QuestionCurrentAddressDuration />
          <QuestionApplicantsFullAddress isFormer t={t} />
          {viewStore.shouldPersonalDataFormValidate &&
            !viewStore.validatePersonalDataForm() && (
              <FormValidationHint>
                {t("global:labels.hint_step")}
              </FormValidationHint>
            )}
          <ButtonWrapper>
            <ButtonPrimary
              to={viewStore.getLocalizedPath(ROUTENAME_STEP_12)}
              onClick={this.validateAndSendData}
            >
              <span className="a-nexus__text">{t("step-11:next_button")}</span>
            </ButtonPrimary>
            <ButtonSecondary to={viewStore.getLocalizedPath(ROUTENAME_STEP_10)}>
              <span className="a-nexus__icon-left">
                <SVGIcon icon="back-arrow" />
              </span>
              <span className="a-nexus__text">
                {t("global:labels.back_button")}
              </span>
            </ButtonSecondary>
          </ButtonWrapper>
        </div>
      </Layout>
    );
  }
}

PersonalData.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withRouter(
  withTranslation("step-11")(
    inject(({ stores }) => ({
      userStore: stores.user,
      viewStore: stores.view,
    }))(observer(PersonalData))
  )
);
