import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

const RadioButton = ({
  handleChange,
  radioId,
  radioName,
  value,
  children,
  selected,
  invalid,
}) => {
  // Add special css class on clicked event. After 200ms remove it
  let stateClicked = false;

  if (selected) {
    stateClicked = true;
    setTimeout(() => {
      stateClicked = false;
    }, 200);
  }

  const radioClassnames = cn({
    "a-radio a-radio--button": true,
    "state-a-radio--selected": selected,
    "state-a-radio--clicked": stateClicked,
    "a-radio--button-error": invalid,
  });

  return (
    <div className={radioClassnames}>
      <input
        type="radio"
        id={radioId}
        name={radioName}
        defaultValue={value}
        onChange={handleChange}
        checked={selected}
      />
      {children}
    </div>
  );
};

RadioButton.propTypes = {
  handleChange: PropTypes.func.isRequired,
  radioId: PropTypes.string.isRequired,
  radioName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  children: PropTypes.node,
  selected: PropTypes.bool,
};

export default RadioButton;
