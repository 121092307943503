import PropTypes from "prop-types";
import React from "react";

import SVGIcon from "./Styleguide-SVGIcon";

class InsuranceCard extends React.Component {
  render() {
    const { details, imgAlt, imgHref, imgSrc, imgTitle, title } = this.props;

    return (
      <div className="card">
        <span className="card-title">{title}</span>

        <div className="card-content">
          <ul>
            {details.map((d, i) => (
              <li
                key={`${d.substring(0, 10)}${i}`}
                style={details.length === i + 1 ? { marginBottom: 0 } : null}
              >
                {d}
              </li>
            ))}
          </ul>

          <div className="card-image">
            <a href={imgHref} style={{ border: "none" }}>
              <img src={imgSrc} alt={imgAlt} />
              <span
                style={{
                  color: "#004d8c",
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  marginTop: "0.6125rem",
                }}
              >
                <SVGIcon icon="circle-down" />
                <span style={{ marginLeft: "0.25rem" }}>{imgTitle}</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

InsuranceCard.propTypes = {
  details: PropTypes.array.isRequired,
  imgAlt: PropTypes.string.isRequired,
  imgHref: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default InsuranceCard;
