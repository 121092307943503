import axios from "axios";

import { trackApiError } from "../helpers/trackingHelper";
import { countries_url } from "../lib/Constants";

// TODO migrate to fetch api
export function getNationalityData(lang = "en") {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(`${countries_url}/${lang}.json`)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }
          reject({ message: "no response.data set" });
        })
        .catch((e) => {
          trackApiError("country", "get", e.response && e.response.status);
          reject(e);
        });
    } catch (e) {
      trackApiError("country", "get", e.response && e.response.status);
      reject({ message: "fetching error" });
    }
  });
}
