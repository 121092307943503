import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import {
  trackLanguage,
  trackNavButton,
  trackNavLink,
} from "../../../helpers/trackingHelper";
import { LOAN_STEP_CATEGORY } from "../../../lib/Constants";
import OffCanvasMenu from "../Styleguide-OffCanvasMenu";
import Progressbar from "../Styleguide-Progressbar";
import HeaderMain from "./HeaderMain";
import HeaderProgress from "./HeaderProgress";
import HeaderSummaryBox from "./HeaderSummaryBox";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offCanvasMenuVisible: false,
    };
  }

  handleLangSelection = (e, langId) => {
    e.preventDefault();
    this.setState({ offCanvasMenuVisible: false });

    const { viewStore } = this.props;
    const stepId = viewStore.getStepId();
    this.props.i18n.changeLanguage(langId, () => {
      if (stepId)
        this.props.history.push(
          viewStore.getLocalizedPath(stepId) +
            (window.location.search || "") +
            (window.location.hash || "")
        );
    });
    trackLanguage(langId);
  };

  handleBurgerClick = () => {
    trackNavButton(
      `burger${this.state.offCanvasMenuVisible ? "_close" : "_open"}`
    );
    this.setState({ offCanvasMenuVisible: !this.state.offCanvasMenuVisible });
  };

  render() {
    const { progressBar, t, viewStore } = this.props;
    const stepId = viewStore.getStepId();
    const headerClasses = cn({
      "l-head": true,
      [`state-l-head--step-${LOAN_STEP_CATEGORY[stepId]}`]: true,
    });

    const links = [
      {
        name: "cembra.ch",
        target: "_blank",
        url: t("global:cembra_home_url"),
        onClick: () => trackNavLink("cembra.ch"),
      },
      {
        name: t("global:header.options.eservice_login"),
        target: "_blank",
        url: "https://eservice.cembra.ch",
        onClick: () => trackNavLink("eservice_login"),
      },
    ];

    const langList = [
      {
        displayname: t("global:header.options.german"),
        name: "de",
      },
      {
        displayname: t("global:header.options.english"),
        name: "en",
      },
      {
        displayname: t("global:header.options.french"),
        name: "fr",
      },
      {
        displayname: t("global:header.options.italian"),
        name: "it",
      },
    ];

    return (
      <React.Fragment>
        <header className={headerClasses}>
          <HeaderMain
            links={links}
            langList={langList}
            onBurgerClick={this.handleBurgerClick}
            onLangSelect={this.handleLangSelection}
            offCanvasMenuVisible={this.state.offCanvasMenuVisible}
          />
          {progressBar && (
            <React.Fragment>
              <HeaderProgress activeCategory={LOAN_STEP_CATEGORY[stepId]} />
              <Progressbar />
              <HeaderSummaryBox />
            </React.Fragment>
          )}
          <hr className="l-head__separator" />
        </header>
        <OffCanvasMenu
          links={links}
          langList={langList}
          visible={this.state.offCanvasMenuVisible}
          onLangSelect={this.handleLangSelection}
        />
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.object,
  progressBar: PropTypes.bool,
  t: PropTypes.func,
  viewStore: PropTypes.object,
};

Header.defaultProps = {
  progressBar: true,
};

export default withRouter(
  withTranslation()(
    inject(({ stores }) => ({
      viewStore: stores.view,
    }))(observer(Header))
  )
);
