import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { ExpensesOtherDebtsCosts } from "../../../../lib/Constants";
import FormControl from "../../../styleguides/Styleguide-FormControl";
import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint";
import RadioButtonGroup from "../../../styleguides/Styleguide-RadioButtonGroup";
import Tooltip from "../../../styleguides/Styleguide-Tooltip";
import QuestionLoanCompensationCosts from "./QuestionLoanCompensationCosts";

class QuestionOtherDebtsCosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasOtherDebts: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleChangeSelection = this.handleChangeSelection.bind(this);

    this.validate = false;
  }

  handleChange(value) {
    const { userStore, viewStore } = this.props;
    value = viewStore.validate_number(value);
    if (value !== "err") userStore.setExpenses_costs_otherDebts(value);

    this.validate = true;
  }

  handleOnBlur() {}

  handleChangeSelection(event) {
    const value = JSON.parse(event.currentTarget.value);
    this.props.userStore.setExpenses_pays_otherDebt(value);

    if (!value) {
      this.props.userStore.setExpenses_costs_otherDebts(0);
      this.props.userStore.setExpenses_wantCompensate_otherDebts(false);
    } else {
      this.props.userStore.setExpenses_costs_otherDebts(null);
      this.props.userStore.setExpenses_wantCompensate_otherDebts(null);
    }
  }

  render() {
    const { t, viewStore, userStore } = this.props;
    let inputState, inputHint;

    const invalidYN =
      viewStore.shouldCustomerExpensesFormValidate &&
      userStore.expenses_pays_otherDebt === null;

    const yesno = {
      radioGroupName: "has_monthly_loan_expenses",
      list: [
        {
          value: true,
          label: t("global:labels.yes"),
        },
        {
          value: false,
          label: t("global:labels.no"),
        },
      ],
    };

    let invalid = false;
    if (
      userStore.expenses_pays_otherDebt &&
      (viewStore.shouldCustomerExpensesFormValidate || this.validate)
    ) {
      const value = userStore.expenses_costs_otherDebts;

      if (
        !value ||
        value < ExpensesOtherDebtsCosts.min ||
        value > ExpensesOtherDebtsCosts.max
      ) {
        invalid = true;
        inputState = "error";
        inputHint = t("step-6:components:QuestionOtherDebtsCosts:max_error");
      } else if (value > ExpensesOtherDebtsCosts.plausibility) {
        inputState = "warn";
        inputHint = t(
          "step-6:components:QuestionOtherDebtsCosts:plausibility_error"
        );
      }
    }
    if (
      inputState !== "error" &&
      invalid === false &&
      viewStore.shouldCustomerExpensesFormValidate
    ) {
      viewStore.validateCustomerExpensesForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalidYN,
    });

    return (
      <React.Fragment>
        <div className="l-step-item">
          <div className="m-info-block">
            <div className="m-info-block__head">
              <span className={titleClasses}>
                {t("step-6:components:QuestionOtherDebtsCosts:question")}
              </span>
              {!this.props.isInModal && (
                <Tooltip
                  nameID={"expenses_house"}
                  tooltip={t(
                    "step-6:components:QuestionOtherDebtsCosts:tooltip"
                  )}
                />
              )}
            </div>
            <span className="m-info-block__lead">
              {t("step-6:components:QuestionOtherDebtsCosts:description")}
            </span>
            <div className="m-info-block__pattern">
              {yesno.list && yesno.list.length > 0 && (
                <RadioButtonGroup
                  fields={yesno}
                  onChange={this.handleChangeSelection}
                  selectedValue={userStore.expenses_pays_otherDebt}
                  invalid={invalidYN}
                />
              )}
              {invalidYN && (
                <FormValidationHint>
                  {t("step-6:error_yes_no")}
                </FormValidationHint>
              )}
            </div>
            {userStore.expenses_pays_otherDebt && (
              <div className="m-info-block__pattern">
                <FormControl
                  type="tel"
                  label={t("global:labels.CHF_month")}
                  id="other_debts_costs"
                  onChange={this.handleChange}
                  onBlur={this.handleOnBlur}
                  inputState={inputState}
                  hint={inputHint}
                  value={viewStore.prettifyNumber(
                    userStore.expenses_costs_otherDebts
                  )}
                  isLast={true}
                />
              </div>
            )}
          </div>
        </div>
        {userStore.expenses_pays_otherDebt && <QuestionLoanCompensationCosts />}
      </React.Fragment>
    );
  }
}

QuestionOtherDebtsCosts.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionOtherDebtsCosts))
);
