export const KEY_NONE = "no_insurance";
export const KEY_PPI = "ppi";
export const KEY_PPI_PLUS = "ppi_plus";
export const KEY_PPI_SE = "ppi_self_employed";

const insurances = [
  {
    id: 9,
    key: KEY_NONE,
    name: "No Insurance",
    rate: 0,
  },
  {
    id: 1,
    key: KEY_PPI,
    name: "Payment Protect",
    rate: 7.829,
  },
  {
    id: 2,
    key: KEY_PPI_PLUS,
    name: "Payment Protect Plus",
    rate: 6.6,
  },
  {
    id: 1,
    key: KEY_PPI_SE,
    name: "Payment Protect Selfemployed",
    rate: 7.829,
  },
];

/**
 * @returns {Array<Object>}
 */
export const getInsurances = () => insurances.map((i) => Object.assign({}, i));

/**
 * @param {string} key
 * @returns {Object|null}
 */
export const getInsuranceByKey = (key) => {
  const i = insurances.find((i) => i.key === key);
  return i ? Object.assign({}, i) : null;
};
