import i18next from "i18next";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { trackStep } from "../../../helpers/trackingHelper";
import { ROUTENAME_STEP_4, ROUTENAME_STEP_6 } from "../../../lib/Constants";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWrapper,
} from "../../styleguides/Styleguide-Button/index";
import FormValidationHint from "../../styleguides/Styleguide-FormValidationHint/index";
import Layout from "../../styleguides/Styleguide-Layout";
import SVGIcon from "../../styleguides/Styleguide-SVGIcon";
import ExtraIncome from "./ExtraIncome";
import NetIncome from "./NetIncome";

class CustomerIncome extends React.Component {
  constructor(props) {
    super(props);
    this.validateAndSendData = this.validateAndSendData.bind(this);
  }

  componentDidMount() {
    const { viewStore, history } = this.props;

    if (
      !(
        viewStore.get_step_validated() < 12 &&
        viewStore.get_step_validated() >= 4
      )
    ) {
      history.push(viewStore.getLocalizedPath(ROUTENAME_STEP_4));
    }
    i18next.loadNamespaces("step-6");
  }

  validateAndSendData(event) {
    const { viewStore } = this.props;

    if (!viewStore.validateCustomerIncomeForm()) {
      event.preventDefault();
      viewStore.customerIncomeFormShouldValidate(true);
    } else {
      viewStore.save_session(5);
      trackStep(4);
    }
  }

  render() {
    const { t, userStore, viewStore } = this.props;
    const layout_props = {
      pageTitle: "step-5:pageTitle",
      headlineKey: "step-5:headline", // passing only the key, it's translated within Layout Trans component
      inverted_background: true,
      progressBar: true,
    };

    return (
      <Layout data={layout_props}>
        <div className="l-step-group">
          <NetIncome isPartner={false} />
          <ExtraIncome isPartner={false} />
          {userStore.showPartnerInfo && <NetIncome isPartner={true} />}
          {userStore.showPartnerInfo &&
            !!userStore.incomeSourceIdPartner &&
            userStore.doesPartnerWorks() && <ExtraIncome isPartner={true} />}
          {viewStore.shouldCustomerIncomeFormValidate &&
            !viewStore.validateCustomerIncomeForm() && (
              <FormValidationHint>
                {t("global:labels.hint_step")}
              </FormValidationHint>
            )}
          <ButtonWrapper>
            <ButtonPrimary
              to={viewStore.getLocalizedPath(ROUTENAME_STEP_6)}
              onClick={this.validateAndSendData}
            >
              <span className="a-nexus__text">{t("step-5:next_button")}</span>
            </ButtonPrimary>
            <ButtonSecondary to={viewStore.getLocalizedPath(ROUTENAME_STEP_4)}>
              <span className="a-nexus__icon-left">
                <SVGIcon icon="back-arrow" />
              </span>
              <span className="a-nexus__text">
                {t("global:labels.back_button")}
              </span>
            </ButtonSecondary>
          </ButtonWrapper>
        </div>
      </Layout>
    );
  }
}

CustomerIncome.propTypes = {
  children: PropTypes.node,
  t: PropTypes.func,
  viewStore: PropTypes.object,
  userStore: PropTypes.object,
};

export default withRouter(
  withTranslation("step-5")(
    inject(({ stores }) => ({
      viewStore: stores.view,
      userStore: stores.user,
    }))(observer(CustomerIncome))
  )
);
