import cn from "classnames";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { getAddressByZIP } from "../../api_clients/address_service";
import {
  KEYCODE_DOWN_ARROW,
  KEYCODE_UP_ARROW,
  KEYCODE_ESCAPE,
  KEYCODE_DEL,
  INPUT_PULLDOWN_RESET_IDX,
  KEYCODE_ENTER,
  KEYCODE_TAB,
} from "../../lib/Constants";
import FormControl from "../styleguides/Styleguide-FormControl/index";

class ZipCity extends React.Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.content = "global:plzcity:";

    this.type_user_zip = props.data.type_user_zip;
    this.type_user_city = props.data.type_user_city;
    this.error_zip = props.data.error_zip
      ? props.data.error_zip
      : t(this.content + "error_zip");
    this.read_only = props.data.read_only;
    this.pulldownItemFocusedRef = null;

    if (document.getElementsByClassName("m-pulldown__links")) {
      this.pulldownItemFocusedRef = React.createRef();
    }

    this.state = {
      addressList: [],
      inputState_zip: "",
      inputState_city: "",
      inputError_zip: "",
      inputError_city: "",
      zip: props.data.zip || "",
      city: props.data.city || "",
      address: {
        zipAdditional: null,
        canton: null,
      },
      pulldownCursorIdx: INPUT_PULLDOWN_RESET_IDX,
      error_zip: this.error_zip,
      notFoundZip: "",
    };
  }

  handleZipChange = (zip) => {
    if (isNaN(zip))
      // not a number
      return null;

    if (this.props.data.withSuggestService && zip.length > 4) return null;
    else if (!this.props.data.withSuggestService && zip.length > 5) return null;

    if (zip.length < 1) {
      this.setState({
        zip: "",
        addressList: [],
        inputState_zip: "",
        inputError_zip: "",
        notFoundZip: "",
      });
    } else if (zip.length) {
      this.setState({
        zip: zip,
        addressList: [],
        inputState_zip: "",
        inputError_zip: "",
        notFoundZip: "",
      });

      if (zip.length >= 2 && this.props.data.withSuggestService)
        this._fetchCities(zip);
    }
  };

  _fetchCities = (zip) => {
    let state = {};
    getAddressByZIP(zip).then((res) => {
      if (!res.length) {
        // Reset existing addresses, if service does not find results for current ZIP
        state.notFoundZip = this.props.t(this.content + "error_zip_not_found");
        state.inputState_zip = "error";
        state.city = "";
      } else {
        state.addressList = res;
        state.city = "";
      }
      this.setState(state, (e) => {
        this.send_data("address");
      });
    });
  };

  handleZipSelection = (event, address) => {
    event.preventDefault();

    this.setState(
      {
        addressList: [],
        zip: address.zip,
        city: address.name,
        address: address,
        error_zip: "",
        notFoundZip: "",
        inputState_zip: "",
      },
      (e) => {
        this.send_data("address");
      }
    );
  };

  handleOnBlurZIP = () => {
    const { city, zip } = this.state;

    if (this.read_only) return null;

    if (zip.length < 2)
      this.setState(
        {
          city: "",
        },
        (e) => {
          this.send_data("address");
        }
      );

    // Do not validate if suggest flyout is open
    if (
      this.state.addressList.length > 0 &&
      this.props.data.withSuggestService
    ) {
      return null;
    }

    const { t } = this.props;
    // since state could not yet be set at this time, wait for 200 ms to validate input fields
    // validate
    let state = {};

    if (!zip) {
      state.notFoundZip = "";
      state.inputError_zip = this.state.error_zip
        ? this.state.error_zip
        : t(this.content + "error_must_be_filled");
      state.inputState_zip = "error";
    } else if (
      this.props.data.withSuggestService &&
      (zip.length !== 4 || !city)
    ) {
      state.notFoundZip = "";
      state.inputError_zip = this.state.notFoundZip;
      state.inputState_zip = "error";
    } else if (!this.props.data.withSuggestService && zip.length < 4) {
      state.notFoundZip = "";
      state.inputError_zip = t(this.content + "error_4_or_5_digits");
      state.inputState_zip = "error";
    }
    this.setState(state);
    this.send_data("zip");
  };

  handleOnBlurCityName = () => {
    const { t } = this.props;
    const { city } = this.state;

    // no validation, if cityName is set automatically
    if (this.props.data.withSuggestService || this.read_only) {
      return null;
    }

    if (city.length === 0) {
      this.setState({
        inputState_city: "error",
        inputError_city: t(this.content + "error_must_be_filled"),
      });
    } else if (city.length < 3) {
      this.setState({
        inputState_city: "error",
        inputError_city: t(this.content + "error_wrong_city"),
      });
    } else {
      this.setState({
        inputState_city: "",
        inputError_city: "",
      });
    }
    this.send_data("city");
  };

  handleOnChangeCityName = (value) => {
    if (value.length > 25) return null;

    if (value.length > 0 && value.match(/\d+/g) !== null) return null;

    if (!this.props.data.withSuggestService) {
      this.setState({
        city: value,
      });
    }
  };

  handleZipKeyDown = (e) => {
    if (this.pulldownItemFocusedRef.current !== null) {
      if (
        this.state.zip.length >= 4 &&
        this.props.data.withSuggestService &&
        e.keyCode !== KEYCODE_DEL &&
        e.keyCode !== KEYCODE_TAB
      ) {
        e.preventDefault();
      } else if (
        this.state.zip.length >= 5 &&
        !this.props.data.withSuggestService &&
        e.keyCode !== KEYCODE_DEL &&
        e.keyCode !== KEYCODE_TAB
      ) {
        e.preventDefault();
      } else {
        // not a number & not up/down arrows & not "del" key -> ignore input
        if (
          !isFinite(e.key) &&
          e.keyCode !== KEYCODE_UP_ARROW &&
          e.keyCode !== KEYCODE_DOWN_ARROW &&
          e.keyCode !== KEYCODE_ENTER &&
          e.keyCode !== KEYCODE_DEL &&
          e.keyCode !== KEYCODE_TAB
        ) {
          e.preventDefault();
        }

        // handle escape
        if (e.keyCode === KEYCODE_ESCAPE) {
          this._resetZipPulldown();
        }

        // handle tab
        if (e.keyCode === KEYCODE_TAB) {
          this.pulldownItemFocusedRef.current.nextSibling.focus();
        }

        // handle only up/down arrows
        if (
          e.keyCode !== KEYCODE_UP_ARROW &&
          e.keyCode !== KEYCODE_DOWN_ARROW
        ) {
          return;
        }

        let cursorIdx = this.state.pulldownCursorIdx;
        if (e.keyCode === KEYCODE_UP_ARROW && cursorIdx > 0) {
          cursorIdx--;
        } else if (
          e.keyCode === KEYCODE_DOWN_ARROW &&
          cursorIdx < this.state.addressList.length - 1
        ) {
          cursorIdx++;
        }

        e.preventDefault();
        this._focusOnZipInPulldown(cursorIdx);
      }
    }
  };

  _focusOnZipInPulldown = (cursorIdx) => {
    this.setState(
      {
        pulldownCursorIdx: cursorIdx,
      },
      () => {
        if (this.pulldownItemFocusedRef.current) {
          this.pulldownItemFocusedRef.current.focus();
        }
      }
    );
  };

  _resetZipPulldown = () => {
    this.setState({
      pulldownCursorIdx: INPUT_PULLDOWN_RESET_IDX,
      addressList: [],
    });
  };

  send_data(type) {
    let { address, zip, city } = this.state;
    zip = zip.length < 4 ? null : zip;
    city = city.length < 3 ? null : city;

    let data = {
      zip,
      city,
      zipAdditional: address.zipAdditional || null,
      canton: address.canton || null,
      type,
    };

    this.props.update_postal(data);
  }

  render() {
    const { t, data } = this.props;
    let {
      inputState_city,
      inputState_zip,
      inputError_zip,
      inputError_city,
      zip,
      city,
    } = this.state;

    if (
      !!data.should_step_validate &&
      !zip &&
      !inputState_zip &&
      !this.read_only
    ) {
      inputError_zip = data.error_zip
        ? data.error_zip
        : t(this.content + "error_must_be_filled");
      inputState_zip = "error";
    }

    if (
      !!data.should_step_validate &&
      !city &&
      !inputState_city &&
      !data.withSuggestService &&
      !this.read_only
    ) {
      inputError_city = t(this.content + "error_must_be_filled");
      inputState_city = "error";
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": inputState_zip,
    });

    const titleClasses_city = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": inputState_city,
    });

    return (
      <React.Fragment>
        <div className="l-step-item">
          <div className="m-info-block">
            {this.props.data.question_zip && (
              <div className="m-info-block__head">
                <label className={titleClasses}>
                  {this.props.data.question_zip}
                </label>
              </div>
            )}
            <div className="m-info-block__pattern">
              <div
                className="a-input-with-pulldown-wrapper"
                onKeyDown={this.handleZipKeyDown}
              >
                <FormControl
                  componentClass="input"
                  type="tel"
                  label={t(this.content + "placeholder_zip")}
                  id={this.type_user_zip}
                  onChange={this.handleZipChange}
                  onKeyDown={this.handleZipKeyDown}
                  value={zip}
                  onBlur={this.handleOnBlurZIP}
                  hint={
                    this.state.notFoundZip
                      ? this.state.notFoundZip
                      : inputError_zip
                  }
                  inputState={this.read_only ? "readOnly" : inputState_zip}
                />
                {this.state.addressList.length > 0 && (
                  <div className="m-pulldown m-pulldown--autosuggestion state-m-pulldown--open">
                    <ul className="m-pulldown__links">
                      {this.state.addressList.map((item, i) => {
                        return (
                          <li key={i} className="m-pulldown__link-item">
                            <a
                              ref={
                                i === this.state.pulldownCursorIdx &&
                                this.pulldownItemFocusedRef
                              }
                              href="#ignore"
                              className="a-link"
                              onClick={(event) =>
                                this.handleZipSelection(event, item)
                              }
                            >
                              {`${item.zip} ${item.name}`}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="m-info-block">
            {this.props.data.question_city && (
              <div className="m-info-block__head">
                <span className="m-info-block__title">
                  <label className={titleClasses_city}>
                    {this.props.data.question_city}
                  </label>
                </span>
              </div>
            )}
            <div className="m-info-block__pattern">
              <FormControl
                componentClass="input"
                label={t(this.content + "placeholder_city")}
                id={this.type_user_city}
                value={city}
                inputState={
                  this.read_only
                    ? "readOnly"
                    : this.props.data.withSuggestService
                    ? "readOnly"
                    : inputState_city
                }
                hint={inputError_city}
                onBlur={this.handleOnBlurCityName}
                onChange={this.handleOnChangeCityName}
                isLast={true}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ZipCity.propTypes = {
  t: PropTypes.func,
  withSuggestService: PropTypes.bool,
};

export default withTranslation()(observer(ZipCity));
