import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { truncate_text } from "../../../../lib/Functions";
import SVGIcon from "../../../styleguides/Styleguide-SVGIcon/index";

class FilePreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previewSrc: null,
    };
    this.content = "step-13:components.DocumentUpload";
  }

  handleOnClick = (event) => {
    event.preventDefault();
    const { removeFile } = this.props;
    if (removeFile) {
      removeFile();
    }
  };

  render() {
    const { file, t } = this.props;
    return (
      <div className="m-upload-selected">
        <div className="m-upload-selected__preview">
          {file.type === "application/pdf" ? (
            <div
              data-hj-suppress // supress hotjar
              className="m-upload-selected__preview-image"
              style={{ backgroundImage: `url('/files/pdf.png')` }}
            />
          ) : (
            <img
              file={file}
              data-hj-suppress // suppress hotjar
              className="m-upload-selected__preview-image"
              src={URL.createObjectURL(file)}
              alt="preview"
            />
          )}
          {file && file.name && (
            <span data-hj-suppress className="a-text a-text--dark-blue">
              {truncate_text(file.name)}
            </span>
          )}
          <a
            href="#ignore"
            className="a-nexus a-nexus--base"
            onClick={this.handleOnClick}
          >
            <span className="a-nexus__text">
              {t(this.content + ".file_upload_delete")} <SVGIcon icon="clear" />
            </span>
          </a>
        </div>
      </div>
    );
  }
}

FilePreview.propTypes = {
  file: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation("step-13")(FilePreview);
