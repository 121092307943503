import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import FormGroup from "../../../styleguides/Styleguide-FormGroup/index";
import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint/index";
import RadioButtonGroup from "../../../styleguides/Styleguide-RadioButtonGroup/index";
import SVGIcon from "../../../styleguides/Styleguide-SVGIcon/index";
import Tooltip from "../../../styleguides/Styleguide-Tooltip/index";
import BirthYearDropDown from "./BirthYearDropDown";

class QuestionChildren extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      childrensBirthYears: this.startArray(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.addChildSelect = this.addChildSelect.bind(this);
    this.removeChildSelect = this.removeChildSelect.bind(this);
    this.setBirthYear = this.setBirthYear.bind(this);

    this.blockRef = React.createRef();
    this.keyPressedAddChild = this.keyPressedAddChild.bind(this);
    this.keyPressedRemoveChild = this.keyPressedRemoveChild.bind(this);
  }

  handleChange(event) {
    const { userStore } = this.props;
    const has = JSON.parse(event.currentTarget.value);
    userStore.setHasChildrenUnder25(has);
    this.setState({ childrensBirthYears: this.startArray() });
  }

  startArray() {
    const { userStore, viewStore } = this.props;
    if (userStore.childrensBirthYears.length) {
      return viewStore.cloneStoreObject(userStore.childrensBirthYears);
    } else return ["none"];
  }

  addChildSelect(event) {
    event.preventDefault();
    const childrensBirthYears = this.state.childrensBirthYears;
    childrensBirthYears.push("none");
    this.setState({ childrensBirthYears });
  }

  removeChildSelect(event) {
    event.preventDefault();
    const { childrensBirthYears } = this.state;

    childrensBirthYears.splice(childrensBirthYears.length - 1, 1);
    this.setState({ childrensBirthYears });

    this.props.userStore.setChildrensBirthYears(childrensBirthYears);
  }

  setBirthYear(index, value) {
    if (value) {
      const childrensBirthYears = this.state.childrensBirthYears;
      childrensBirthYears[index] = value;
      this.setState({ childrensBirthYears }, () =>
        this.props.userStore.setChildrensBirthYears(childrensBirthYears)
      );
    }
  }

  keyPressedAddChild = (e) => {
    if (e.keyCode === 13) {
      this.addChildSelect(e);
    }
  };

  keyPressedRemoveChild = (e) => {
    if (e.keyCode === 13) {
      this.removeChildSelect(e);
    }
  };

  render() {
    const { userStore, t, viewStore } = this.props;
    const invalidYN =
      viewStore.should_step_3_validate && userStore.hasChildrenUnder25 === null;
    const invalid =
      viewStore.should_step_3_validate &&
      userStore.hasChildrenUnder25 &&
      !userStore.childrensBirthYears.length;

    if (
      viewStore.should_step_3_validate &&
      invalid === false &&
      invalidYN === false
    ) {
      viewStore.vaildateUserBasicDemographicsForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid || invalidYN,
    });

    const yesno = {
      radioGroupName: "has_children_under_25",
      list: [
        {
          value: true,
          label: t("global:labels.yes"),
        },
        {
          value: false,
          label: t("global:labels.no"),
        },
      ],
    };

    return (
      <div className="l-step-item">
        <div ref={this.blockRef} className="m-info-block">
          <div className="m-info-block__head">
            <label className={titleClasses}>
              {t("step-3:components.QuestionChildren.question")}
            </label>
            <Tooltip
              nameID={"has_kids"}
              tooltip={t("step-3:components.QuestionChildren.tooltip")}
            />
          </div>
          <div className="m-info-block__pattern">
            <RadioButtonGroup
              fields={yesno}
              onChange={this.handleChange}
              selectedValue={userStore.hasChildrenUnder25}
              invalid={invalidYN}
            />
            {invalidYN && viewStore.should_step_3_validate && (
              <FormValidationHint>
                {t("step-3:components.QuestionChildren.hint")}
              </FormValidationHint>
            )}
          </div>
          {userStore.hasChildrenUnder25 && (
            <div className="m-info-block__pattern">
              <FormGroup style={{ width: "100%", display: "block" }}>
                {Object.keys(this.state.childrensBirthYears).map((item, i) => {
                  return (
                    <BirthYearDropDown
                      key={i}
                      selected={this.state.childrensBirthYears[i]}
                      onChange={({ value }) => this.setBirthYear(i, value)}
                      placeholder={t("step-3:components.QuestionChildren.year")}
                      style={{
                        maxWidth: "284px",
                        marginTop: "40px",
                        marginBottom: "0px",
                      }}
                      invalid={invalid}
                      parentBlockRef={this.blockRef}
                    />
                  );
                })}
                {viewStore.should_step_3_validate && invalid && (
                  <FormValidationHint>
                    {t("step-3:components:QuestionChildren:hint_birthday")}
                  </FormValidationHint>
                )}
                {this.state.childrensBirthYears.length <= 9 &&
                  this.state.childrensBirthYears.length > 0 && (
                    <div
                      className="a-nexus a-nexus--load-more"
                      onClick={this.addChildSelect}
                      onKeyDown={this.keyPressedAddChild}
                      tabIndex="0"
                    >
                      <span className="a-nexus__icon-left">
                        <SVGIcon icon="plus" />
                      </span>
                      <span className="a-nexus__text">
                        {t("step-3:components.QuestionChildren.add_kid")}
                      </span>
                    </div>
                  )}

                {this.state.childrensBirthYears.length > 1 && (
                  <div
                    className="a-nexus a-nexus--load-more"
                    onClick={this.removeChildSelect}
                    onKeyDown={this.keyPressedRemoveChild}
                    tabIndex="0"
                  >
                    <span className="a-nexus__icon-left">
                      <SVGIcon icon="minus" />
                    </span>
                    <span className="a-nexus__text">
                      {t("step-3:components.QuestionChildren.remove_kid")}
                    </span>
                  </div>
                )}
              </FormGroup>
            </div>
          )}
        </div>
      </div>
    );
  }
}

QuestionChildren.propTypes = {
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation(["step-3"])(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionChildren))
);
