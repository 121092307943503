import axios from "axios";

import { trackApiError } from "../helpers/trackingHelper";
import { kremo_url } from "../lib/Constants";

const config = {};

// TODO migrate to fetch api
export function getKremoCalculation(data) {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(kremo_url, data, config)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject({ message: "no response.data set" });
          }
        })
        .catch((e) => {
          trackApiError(
            "budget_calculation",
            "post",
            e.response && e.response.status
          );
          reject(e);
        });
    } catch (e) {
      trackApiError(
        "budget_calculation",
        "post",
        e.response && e.response.status
      );
      reject({ message: "fetching error" });
    }
  });
}
