import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import ZipCity from "../../../shared/ZIP_city";

class QuestionResidence extends React.Component {
  constructor(props) {
    super(props);
    this.content = "step-4:components.QuestionResidence";
    this.state = {
      reset_address: false,
    };
  }

  update_postal(items, type) {
    const { userStore } = this.props;

    userStore.setAddress(
      {
        zip: items.zip,
        city: items.city,
        zipAdditional: items.zipAdditional,
        canton: items.canton,
      },
      type
    );
  }

  render() {
    const { userStore, viewStore, t } = this.props;
    const invalid =
      viewStore.should_step_4_validate &&
      (!userStore.address_zip || !userStore.address_city);

    if (viewStore.should_step_4_validate && !invalid) {
      viewStore.validateCustomerAccommodationForm();
    }

    const data = {
      zip: userStore.address_zip,
      city: userStore.address_city,
      withSuggestService: true,
      should_step_validate: viewStore.should_step_4_validate,
      read_only: false,
      question_zip: t(this.content + ".question_zip_address"),
      question_city: "",
      type_user_zip: "userZip",
      type_user_city: "userCity",
      error_zip: t(this.content + ".error_zip"),
    };

    return (
      <React.Fragment>
        <h2 className="a-text a-text--subtitle">
          {t("step-4:components:QuestionResidence:subtitle_residence")}
        </h2>
        <ZipCity
          data={data}
          update_postal={(items) => this.update_postal(items, "address")}
        />
      </React.Fragment>
    );
  }
}

QuestionResidence.propTypes = {
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation("step-4")(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionResidence))
);
