import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { AREA_CODE_LIST } from "../../../../lib/Constants";
import FormControl from "../../../styleguides/Styleguide-FormControl";
import Tooltip from "../../../styleguides/Styleguide-Tooltip";

class QuestionContactPhone extends React.Component {
  constructor(props) {
    super(props);

    let customerPhone = props.userStore.customerPhone
      ? props.userStore.customerPhone
      : "";

    if (
      props.userStore.customerPhone &&
      props.userStore.customerPhone.length === 9 &&
      props.userStore.customerPhoneAreaCode === "0041"
    ) {
      customerPhone = customerPhone.split(/(\d{2})(\d{3})(\d{2})/g).join(" ");
    }

    this.state = {
      inputHint: "",
      inputState: "",
      selectedArea: {},
      customerPhone: customerPhone || "",
    };

    this.handleAreaCodeChange = this.handleAreaCodeChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.validatePhone = this.validatePhone.bind(this);
    this.blockRef = React.createRef();
  }

  componentDidMount() {
    this.setAreaCodes();
  }

  handleAreaCodeChange(e) {
    const { userStore } = this.props;
    if (!e) {
      return;
    }

    this.setState({ selectedArea: e });
    userStore.setCustomerPhone("");
    userStore.setcustomerPhoneAreaCode(e.value);
    this.setState({ customerPhone: "" });
  }

  handlePhoneChange(customerPhone) {
    const { userStore } = this.props;

    let char_check = customerPhone.slice(-1);

    if (isNaN(Number(customerPhone.replace(/ /g, "")))) {
      return null;
    } else if (char_check === ".") return null;

    if (userStore.customerPhoneAreaCode === "0041") {
      if (customerPhone.length > this.state.customerPhone.length) {
        // only if is adding numbers
        let c = customerPhone.replace(/[^0-9]+/g, "");

        if (
          (customerPhone.charAt(0) === "0" && c.length > 10) ||
          (customerPhone.charAt(0) !== "0" && c.length > 9)
        ) {
          return null;
        } else {
          let ctl = document.getElementById("a-input-tel-mob");
          let pos = ctl.selectionStart;

          if (c.charAt(0) === "0") {
            c = c
              .split(/(\d{3})(\d{3})(\d{2})/g)
              .join(" ")
              .trim();
          } else {
            c = c
              .split(/(\d{2})(\d{3})(\d{2})/g)
              .join(" ")
              .trim();
          }

          // setTimeOut to catch event fired before the browser has moved the cursor
          window.setTimeout(function () {
            let spaces = customerPhone.split(" ").length - 1;
            ctl.focus();

            if (pos + spaces >= customerPhone.length) {
              ctl.setSelectionRange(pos + spaces, pos + spaces);
            } else {
              ctl.setSelectionRange(pos, pos);
            }
          }, 0);
        }

        customerPhone = c;
      }
    } else {
      // not swiss numbers
      let c = customerPhone.replace(/[^0-9]+/g, "").trim();

      if (
        (customerPhone.charAt(0) === "0" &&
          (userStore.customerPhoneAreaCode + c).length > 16) ||
        (customerPhone.charAt(0) !== "0" &&
          (userStore.customerPhoneAreaCode + c).length > 15)
      ) {
        return null;
      }

      customerPhone = c;
    }

    this.setState({ customerPhone: customerPhone });
  }

  setAreaCodes() {
    const { userStore } = this.props;

    let ac = null;
    if (userStore.customerPhoneAreaCode) {
      ac = AREA_CODE_LIST.filter(
        (area) => area.value === userStore.customerPhoneAreaCode
      );
    }
    if (ac) this.setState({ selectedArea: ac[0] });
    else {
      this.setState({ selectedArea: AREA_CODE_LIST[0] });
      userStore.setcustomerPhoneAreaCode(AREA_CODE_LIST[0].value);
    }
  }

  validatePhone() {
    const { t, viewStore, userStore } = this.props;
    let { customerPhone } = this.state;
    let status = true;

    // remove everything but numbers
    let _customerPhone = customerPhone.replace(/ /g, "");

    // if the first number is 0 remove it (not applicable to italy and Liechtenstein)
    if (customerPhone.startsWith("0")) {
      customerPhone = customerPhone.substring(1);
    }

    // reset error codes
    let state = {
      inputState: "",
      inputHint: "",
    };

    // +41 -> length = 9, starts with 75, 76, 77, 78, 79
    // not +41 -> max. length 15 digits
    if (userStore.customerPhoneAreaCode === "0041") {
      let validPrefixes = ["75", "76", "77", "78", "79"];

      customerPhone = customerPhone.replace(/ /g, "");

      if (
        !viewStore.validatePhoneByRegex(customerPhone) ||
        customerPhone.length !== 9
      ) {
        state = {
          inputState: "error",
          inputHint: t("step-10:components.QuestionContactPhone.error"),
        };

        status = false;
      }
      if (
        validPrefixes.length !== 0 &&
        validPrefixes.findIndex((code) => {
          return code.startsWith(customerPhone.substring(0, 2));
        }) < 0
      ) {
        state = {
          inputState: "error",
          inputHint: t("step-10:components.QuestionContactPhone.error"),
        };

        status = false;
      }
    } else {
      if (
        !viewStore.validatePhoneByRegex(_customerPhone) ||
        (_customerPhone.charAt(0) === "0" && _customerPhone.length < 8) ||
        (customerPhone.charAt(0) !== "0" && _customerPhone.length < 7)
      ) {
        state = {
          inputState: "error",
          inputHint: t("step-10:components.QuestionContactPhone.error"),
        };
        status = false;
      }
    }

    if (status) {
      userStore.setCustomerPhone(customerPhone);
    } else {
      userStore.setCustomerPhone(null);
    }
    this.setState(state);
  }

  render() {
    const { t, viewStore, userStore } = this.props;
    let { inputState, inputHint, customerPhone } = this.state;

    if (
      viewStore.shouldCustomerContactFormValidate &&
      !userStore.customerPhone &&
      !inputState
    ) {
      inputState = "error";
      inputHint = t("step-10:components.QuestionContactPhone.error");
    }

    if (viewStore.shouldCustomerContactFormValidate && !inputState)
      viewStore.validateCustomerContactForm();

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": inputState === "error",
    });

    return (
      <React.Fragment>
        <div className="l-step-group">
          <div className="l-step-item">
            <div ref={this.blockRef} className="m-info-block">
              <div className="m-info-block__head">
                <span className={titleClasses}>
                  {t("step-10:components:QuestionContactPhone:question")}
                </span>
                <Tooltip
                  nameID={"phone"}
                  tooltip={t("step-10:components.QuestionContactPhone.tooltip")}
                />
              </div>
              <span className="m-info-block__lead">
                {t("step-10:components.QuestionContactPhone.description")}
              </span>
              <div className="m-info-block__pattern">
                <FormControl
                  componentClass="select"
                  type={"tel"}
                  options={AREA_CODE_LIST}
                  value={this.state.selectedArea}
                  onChange={this.handleAreaCodeChange.bind(this)}
                  onBlur={this.validatePhone}
                  parentBlockRef={this.blockRef}
                />

                <FormControl
                  type="tel"
                  id={"a-input-tel-mob"}
                  label={t(
                    "step-10:components.QuestionContactPhone.placeholder"
                  )}
                  onChange={(value) => this.handlePhoneChange(value)}
                  value={customerPhone}
                  onBlur={this.validatePhone}
                  inputState={inputState}
                  hint={inputHint}
                  autoComplete={"mobilephone mobile-phone	cellphone cell-phone"}
                  isLast={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

QuestionContactPhone.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionContactPhone))
);
