import cn from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import { withTranslation, Trans } from "react-i18next";

import Footer from "../Styleguide-Footer";
import Header from "../Styleguide-Header";

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: undefined,
      footerHeight: undefined,
    };
  }

  componentDidMount() {
    if (document !== undefined) {
      this.setState({
        headerHeight: document.getElementsByTagName("header")[0].offsetHeight,
      });
      this.setState({
        footerHeight: document.getElementsByTagName("footer")[0].offsetHeight,
      });
    }
  }

  render() {
    const { t, i18n } = this.props;
    const {
      inverted_background = false,
      progressBar = false,
      headlineKey,
      pageTitle,
      metaDescriptionKey,
    } = this.props.data;

    const pageContainerCss = cn({
      "l-page-container": true,
      "l-page-container--invert": inverted_background,
    });

    const containerProps = {
      style: {
        minHeight: `calc(100vh - ${this.state.headerHeight}px - ${this.state.footerHeight}px)`,
      },
    };

    return (
      <React.Fragment>
        <Helmet
          title={t(pageTitle) || "Page"}
          meta={[
            {
              name: "description",
              content: metaDescriptionKey
                ? t(metaDescriptionKey)
                : t(pageTitle) || "Page",
            },
          ]}
          htmlAttributes={{
            lang: i18n.resolvedLanguage || "en",
            "xml:lang": i18n.resolvedLanguage || "en",
          }}
        />

        <Header progressBar={progressBar} />
        <section {...containerProps} className={pageContainerCss}>
          {headlineKey && (
            <div style={{ backgroundColor: "#edf4f6" }}>
              <div class="l-page-inner">
                <div class="g-container g-layout--s12-l8">
                  <div class="g-cell">
                    <h1 className="a-page-title">
                      <Trans i18nKey={headlineKey} />
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="l-page-inner">
            <div className="g-container g-layout--s12-l8">
              <div className="g-cell">{this.props.children}</div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
  i18n: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(Layout);
