import React from "react";

//@todo add propper UI classes!
const tmpWrapperStyle = {
  display: "block",
  marginTop: "16px",
  color: "#c10538",
  fontSize: "0.875rem",
  lineHeight: "1.28571",
  width: "100%",
};

const FormValidationHint = ({ children }) => (
  <div style={tmpWrapperStyle}>{children}</div>
);

export default FormValidationHint;
