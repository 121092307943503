import cn from "classnames";
import { inject, observer } from "mobx-react";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import FormControl from "../../../styleguides/Styleguide-FormControl/index";
import FormValidationHint from "../../../styleguides/Styleguide-FormValidationHint/index";
import Tooltip from "../../../styleguides/Styleguide-Tooltip/index";

class QuestionBirthday extends React.Component {
  constructor(props) {
    super(props);

    this.content = props.isPartner
      ? "step-3:components.QuestionBirthday.partner"
      : "step-3:components.QuestionBirthday";

    this.setBirthdateValue = this.setBirthdateValue.bind(this);
    this.blockRef = React.createRef();
    this.type_user = props.isPartner
      ? "birthdayValuesPartner"
      : "birthdayValues";
    this.errorMessage = null;

    const { userStore } = props;

    this.state = {
      birthDay: userStore[this.type_user].day,
      birthMonth: userStore[this.type_user].month,
      birthYear: userStore[this.type_user].year,
    };

    // Day dropdown
    this.dayOptions = [];
    for (let i = 1; i <= 31; i++) {
      const int = this.leadingZero(i);
      const obj = { value: i, label: int };
      this.dayOptions.push(obj);
    }
    // Month dropdown
    this.monthOptions = [];
    for (let i = 1; i <= 12; i++) {
      const int = this.leadingZero(i);
      this.monthOptions.push({ value: i, label: int });
    }
    // Year dropdown
    this.yearOptions = [];
    const currentYear = new Date().getFullYear();
    const maxYears = props.isPartner ? 90 : 65;
    const minYear = currentYear - maxYears;
    const maxYear = currentYear - 18;
    for (let i = maxYear; i >= minYear; i--) {
      const obj = { value: i, label: i };
      this.yearOptions.push(obj);
    }
  }

  leadingZero(number) {
    if (String(number).length === 1) {
      return `0${number}`;
    }
    return number;
  }

  validateBirthdate(values) {
    const { userStore, t, isPartner } = this.props;
    let error = null;
    const selectedDate = moment(
      `${values.year}-${values.month}-${values.day}`,
      "YYYY-MM-DD"
    );

    if (!selectedDate.isValid()) {
      error = t("step-3:components:QuestionBirthday:invalid_date");
    }

    const givenBirthday = new Date(values.year, values.month - 1, values.day);
    let today = new Date();
    let atLeast18 = new Date();
    atLeast18.setFullYear(today.getFullYear() - 18);
    let year = Number(values.year) + 65;
    let notOlderthan65 = new Date(year, values.month - 1, values.day + 1);
    year = Number(values.year) + 90;
    let notOlderthan90 = new Date(year, values.month - 1, values.day + 1);

    if (Date.parse(givenBirthday) > Date.parse(atLeast18)) {
      error = t(this.content + ":error_age");
    } else if (
      !this.props.isPartner &&
      Date.parse(today) > Date.parse(notOlderthan65)
    ) {
      error = t(this.content + ":error_age");
    } else if (
      this.props.isPartner &&
      Date.parse(today) > Date.parse(notOlderthan90)
    ) {
      error = t(this.content + ":error_age");
    }

    if (!error) {
      userStore.setBirthdateValue(values, isPartner);
    } else {
      let date = {
        day: null,
        month: null,
        year: null,
      };
      userStore.setBirthdateValue(date, isPartner);
    }

    return error;
  }

  setBirthdateValue(type, value) {
    if (typeof value !== "number") {
      return;
    }

    const currentState = this.state;
    switch (type) {
      case "day":
        currentState.birthDay = value;
        break;
      case "month":
        currentState.birthMonth = value;
        break;
      case "year":
        currentState.birthYear = value;
        break;
      default:
        break;
    }

    const birthDateValid = !!(
      currentState.birthDay &&
      currentState.birthMonth &&
      currentState.birthYear
    );

    if (birthDateValid) {
      let date = {
        day: this.state.birthDay,
        month: this.state.birthMonth,
        year: this.state.birthYear,
      };

      this.errorMessage = this.validateBirthdate(date);
    } else if (this.props.viewStore.should_step_3_validate) {
      this.errorMessage = this.props.t(
        "step-3:components:QuestionBirthday:invalid_date"
      );
    }

    this.setState(currentState);
  }

  render() {
    const { t, userStore, viewStore } = this.props;

    if (viewStore.should_step_3_validate) {
      if (
        this.state.birthDay &&
        this.state.birthMonth &&
        this.state.birthYear
      ) {
        let date = {
          day: this.state.birthDay,
          month: this.state.birthMonth,
          year: this.state.birthYear,
        };

        this.errorMessage = this.validateBirthdate(date);
      } else {
        this.errorMessage = this.props.t(this.content + ":hint");
      }
    }

    let invalid =
      this.errorMessage ||
      (viewStore.should_step_3_validate &&
        (userStore[this.type_user].day == null ||
          userStore[this.type_user].month == null ||
          userStore[this.type_user].year == null));

    if (viewStore.should_step_3_validate && invalid === false) {
      viewStore.vaildateUserBasicDemographicsForm();
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": invalid,
    });

    let day = this.state.birthDay
      ? {
          value: this.state.birthDay,
          label: this.leadingZero(this.state.birthDay),
        }
      : null;
    let month = this.state.birthMonth
      ? {
          value: this.state.birthMonth,
          label: this.leadingZero(this.state.birthMonth),
        }
      : null;
    let year = this.state.birthYear
      ? { value: this.state.birthYear, label: this.state.birthYear }
      : null;

    return (
      <div className="l-step-item">
        <div ref={this.blockRef} className="m-info-block">
          <div className="m-info-block__head">
            <label className={titleClasses}>
              {t(this.content + ".question")}
            </label>
            <Tooltip
              nameID={this.type_user}
              tooltip={t(this.content + ".tooltip")}
            />
          </div>
          <div className="m-info-block__pattern">
            <FormControl
              componentClass="select"
              type={"tel"}
              options={this.dayOptions}
              value={day}
              onChange={({ value }) => this.setBirthdateValue("day", value)}
              placeholder={t("step-3:components.QuestionBirthday.day")}
              invalid={invalid}
              parentBlockRef={this.blockRef}
            />
            <FormControl
              componentClass="select"
              type={"tel"}
              options={this.monthOptions}
              value={month}
              onChange={({ value }) => this.setBirthdateValue("month", value)}
              placeholder={t("step-3:components.QuestionBirthday.month")}
              invalid={invalid}
              parentBlockRef={this.blockRef}
            />
            <FormControl
              componentClass="select"
              type={"tel"}
              options={this.yearOptions}
              value={year}
              onChange={({ value }) => this.setBirthdateValue("year", value)}
              placeholder={t("step-3:components.QuestionBirthday.year")}
              invalid={invalid}
              parentBlockRef={this.blockRef}
            />
          </div>
          <div>
            {invalid && (
              <FormValidationHint>{this.errorMessage}</FormValidationHint>
            )}
          </div>
        </div>
      </div>
    );
  }
}

QuestionBirthday.propTypes = {
  t: PropTypes.func,
  userStore: PropTypes.object,
  viewStore: PropTypes.object,
};

export default withTranslation(["step-3"])(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionBirthday))
);
