import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import {
  KEY_NONE,
  KEY_PPI,
  KEY_PPI_PLUS,
  KEY_PPI_SE,
  getInsurances,
  getInsuranceByKey,
} from "../../../../data/insuranceData";
import { trackInsurance } from "../../../../helpers/trackingHelper";
import { insurance_limits } from "../../../../lib/Constants";
import InsuranceCard from "../../../styleguides/InsuranceCard";

const I18N_PREFIX = "step-9:components:QuestionInsurance:";

class QuestionInsurance extends React.Component {
  constructor(props) {
    super(props);

    const { loanStore } = props;
    this.state = {
      insuranceTypeId: loanStore.insuranceTypeId,
    };
  }

  onClickItemHandler = (id) => {
    const { loanStore } = this.props;

    this.setState({
      insuranceTypeId: id,
    });

    loanStore.setInsuranceData(id, Number(this.calculateInsuranceCosts(id)));
  };

  // TODO move to store
  calculateInsuranceCosts(insuranceID) {
    const { loanStore } = this.props;
    const rate = getInsurances().filter((p) => p.id === insuranceID)[0].rate;
    let ppiBase = Number(loanStore.calculateFinancingRate(null, "MAX"));

    if (ppiBase > insurance_limits.ppi_max_rate_for_calculation)
      ppiBase = insurance_limits.ppi_max_rate_for_calculation;

    if (insuranceID === getInsuranceByKey(KEY_PPI).id) {
      return ((ppiBase / 100) * rate).toFixed(2);
    } else if (insuranceID === getInsuranceByKey(KEY_PPI_PLUS).id) {
      return (
        ((ppiBase + insurance_limits.ppiplus_additional_rate_value) / 100) *
        rate
      ).toFixed(2);
    } else if (insuranceID === getInsuranceByKey(KEY_NONE).id) {
      return 0;
    } else {
      return "Error";
    }
  }

  render() {
    const { viewStore, t, userIsEmployed, userIsIndipendet } = this.props;

    let i18nBase = I18N_PREFIX;
    let i18nBrochureSuffix, insurance;
    if (this.props.userIsEmployed) {
      i18nBase += "ppi_plus_";
      i18nBrochureSuffix = "_plus";
      insurance = getInsuranceByKey(KEY_PPI_PLUS);
    } else if (this.props.userIsIndipendet) {
      i18nBase += "self_employed_ppi_";
      i18nBrochureSuffix = "_self_employed";
      insurance = getInsuranceByKey(KEY_PPI_SE);
    } else {
      // don't throw here as this component is rendered when user
      // just enters step-9 directly without previously interaction
      // before redirect to step-2 happens
    }

    const radioYesId = `radio${insurance?.id}`;
    const radioNoId = `radio${getInsuranceByKey(KEY_NONE).id}`;

    return (
      <React.Fragment>
        {(userIsEmployed || userIsIndipendet) && (
          <>
            <InsuranceCard
              details={[
                t(`${i18nBase}details_1`),
                t(`${i18nBase}details_2`),
                t(`${i18nBase}details_3`),
              ]}
              imgAlt={t(`${I18N_PREFIX}brochure_alt`)}
              imgHref={t(`${I18N_PREFIX}brochure_href${i18nBrochureSuffix}`)}
              imgSrc={t(`${I18N_PREFIX}brochure_cover${i18nBrochureSuffix}`)}
              imgTitle={t(`${I18N_PREFIX}brochure_title`)}
              title={t(`${i18nBase}headline`)}
            />
            <div
              className="a-radio a-radio--cross-selling"
              style={{ marginTop: "2rem" }}
            >
              <div className="a-radio__wrap">
                <input
                  defaultChecked={this.state.insuranceTypeId === insurance.id}
                  id={radioYesId}
                  name="radio-name-upselling"
                  type="radio"
                  value="cross-selling-product"
                  onClick={(e) => {
                    this.onClickItemHandler(insurance.id);
                    trackInsurance(insurance.key);
                  }}
                />

                <label className="a-radio__extended-label" htmlFor={radioYesId}>
                  <span className="a-radio__extended-full">
                    <span className="a-radio__extended-centered">
                      <span>
                        {`+ ${t(
                          "global:labels:currency_CHF"
                        )} ${viewStore.rouding5cents(
                          this.calculateInsuranceCosts(insurance.id)
                        )}`}
                      </span>
                    </span>
                  </span>
                </label>
                <span className="a-radio__text">
                  {t("global:labels:monthly")}
                </span>
                <p
                  className="insurance-label-description"
                  style={{ margin: "10px 0 0 32px" }}
                >
                  {t(`${i18nBase}description`)}
                </p>
              </div>
            </div>
            <div className="a-radio a-radio--cross-selling">
              <div className="a-radio__wrap">
                <input
                  defaultChecked={
                    this.state.insuranceTypeId ===
                    getInsuranceByKey(KEY_NONE).id
                  }
                  id={radioNoId}
                  name="radio-name-upselling"
                  type="radio"
                  value="cross-selling-product"
                  onClick={(e) => {
                    this.onClickItemHandler(getInsuranceByKey(KEY_NONE).id);
                    trackInsurance(KEY_NONE);
                  }}
                />

                <label htmlFor={radioNoId} style={{ height: "auto" }}>
                  <p className="insurance-label-description">
                    {t(`${I18N_PREFIX}no_insurance_description`)}
                  </p>
                </label>
              </div>
            </div>
          </>
        )}
      </React.Fragment>
    );
  }
}

QuestionInsurance.propTypes = {
  loanStore: PropTypes.object.isRequired,
  viewStore: PropTypes.object.isRequired,
  t: PropTypes.func,
};

export default withTranslation("step-9")(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
    loanStore: stores.loan,
  }))(observer(QuestionInsurance))
);
