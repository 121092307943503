import cn from "classnames";
import PropTypes from "prop-types";
import React, { Children } from "react";
import { Link } from "react-router-dom";

import { trackNavButton } from "../../../helpers/trackingHelper";
import ButtonPrimary from "./ButtonPrimary";
import ButtonSecondary from "./ButtonSecondary";
import ButtonWrapper from "./ButtonWrapper";

class Button extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);

    this.state = {
      clicked: false,
    };

    this.timeout = null;
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    delete this.timeout;
  }

  handleOnClick(event) {
    // not optimal at all but sufficient to track clicked
    // non-primary buttons' title; MAYBE optimize
    if (this.props.secondary) {
      const children = Children.toArray(this.props.children).reverse();
      if (children[0] && children[0].type === "span") {
        trackNavButton(children[0].props.children);
      }
    }

    if (this.props.onClick) {
      this.props.onClick(event);
    }

    // Set transition effect class
    this.setState({ clicked: true });
    this.timeout = setTimeout(() => {
      // remove transition effect class after 500ms
      this.setState({ clicked: false });
    }, 400);
  }

  render() {
    const { secondary, to, children, type, state } = this.props;

    const wrapperClasses = cn({
      "a-nexus": true,
      "a-nexus--alone": type === "alone",
      "state-a-nexus--disabled": state === "disabled",
      "a-nexus--secondary": secondary,
      "a-nexus--primary": !secondary && type !== "alone",
      "state-a-nexus--clicked": this.state.clicked,
    });
    return (
      <Link className={wrapperClasses} to={to} onClick={this.handleOnClick}>
        <div>{children}</div>
      </Link>
    );
  }
}

Button.propTypes = {
  secondary: PropTypes.bool,
  to: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  type: PropTypes.string,
  state: PropTypes.string,
};

export default Button;

export { ButtonPrimary, ButtonSecondary, ButtonWrapper };
