import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Overlay from "../../styleguides/Styleguide-Overlay";
import SVGIcon from "../../styleguides/Styleguide-SVGIcon";
import ModalPortal from "../../utils/ModalPortal";

class FailedActionModal extends Component {
  handleOnClose = () => {
    this.props.onClose();
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <ModalPortal>
          <Overlay
            id={"FailedActionModal"}
            isOpen
            withoutPageContainer
            onClose={this.handleOnClose}
          >
            <div style={{ textAlign: "center", fontSize: "48px" }}>
              <SVGIcon icon="error-outline" />
              <h2 className="m-overlay-content__title">
                {t("global:labels.action_failed")}
              </h2>
            </div>
          </Overlay>
        </ModalPortal>
      </React.Fragment>
    );
  }
}

FailedActionModal.propTypes = {
  t: PropTypes.func,
  onClose: PropTypes.func,
};

export default withTranslation()(FailedActionModal);
