import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const HeaderProgress = ({ activeCategory }) => {
  const { t } = useTranslation();

  return (
    <div className="l-page-container l-page-container--invert l-page-container--nav">
      <div className="l-page-inner">
        <div className="g-container g-layout--header">
          <div className="g-cell">
            <nav className="m-nav-main">
              <ul className="m-nav-main__list">
                <li
                  className={
                    activeCategory === 0
                      ? "m-nav-main__item state-m-nav-main__item--active"
                      : "m-nav-main__item"
                  }
                >
                  <Link className="a-nexus" to="#">
                    <span className="a-nexus__text">{`1. ${t(
                      "global:header.navigation.item_1"
                    )}`}</span>
                  </Link>
                </li>
                <li
                  className={
                    activeCategory === 1
                      ? "m-nav-main__item state-m-nav-main__item--active"
                      : "m-nav-main__item"
                  }
                >
                  <Link className="a-nexus" to="#">
                    <span className="a-nexus__text">{`2. ${t(
                      "global:header.navigation.item_2"
                    )}`}</span>
                  </Link>
                </li>
                <li
                  className={
                    activeCategory === 2
                      ? "m-nav-main__item state-m-nav-main__item--active"
                      : "m-nav-main__item"
                  }
                >
                  <Link className="a-nexus" to="#">
                    <span className="a-nexus__text">{`3. ${t(
                      "global:header.navigation.item_3"
                    )}`}</span>
                  </Link>
                </li>
                <li
                  className={
                    activeCategory === 3
                      ? "m-nav-main__item state-m-nav-main__item--active"
                      : "m-nav-main__item"
                  }
                >
                  <Link className="a-nexus" to="#">
                    <span className="a-nexus__text">{`4. ${t(
                      "global:header.navigation.item_4"
                    )}`}</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderProgress.propTypes = {
  activeCategory: PropTypes.number,
};

export default HeaderProgress;
