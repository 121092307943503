import * as accommodation from "../data/accommodationData";
import * as employmentStatus from "../data/employmentStatusData";
import * as c from "../lib/Constants";

const { version } = require("../../package.json");

const CUSTOM_EVENT = "custom_event";
const LOG_PREFIX = "[TCK]";

/**
 * @param {string|null} stepId
 * @param {number|null} value
 * @returns {string|undefined}
 */
const getAmountOrTerm = (stepId, value) => {
  if (
    typeof value === "number" &&
    // don't use default value, e.g.
    // on standalone file upload
    [
      c.ROUTENAME_STEP_1,
      c.ROUTENAME_STEP_3,
      c.ROUTENAME_STEP_4,
      c.ROUTENAME_STEP_5,
      c.ROUTENAME_STEP_6,
      c.ROUTENAME_STEP_7,
      c.ROUTENAME_STEP_9,
      c.ROUTENAME_STEP_10,
      c.ROUTENAME_STEP_11,
      c.ROUTENAME_STEP_12,
    ].includes(stepId)
  ) {
    return String(value);
  }
  return undefined;
};

/**
 * @param {number|null} incomeSourceId
 * @returns {string|undefined}
 */
const getEmploymentStatus = (incomeSourceId) =>
  employmentStatus.getKeyByValue(incomeSourceId) || undefined;

/**
 * @param {string|null} stepId
 * @returns {string|undefined}
 */
const getFunnelStepNr = (stepId) => {
  switch (stepId) {
    case c.ROUTENAME_STEP_1:
      return "1_loan";
    case c.ROUTENAME_STEP_3:
      return "2_basic";
    case c.ROUTENAME_STEP_4:
      return "3_residence";
    case c.ROUTENAME_STEP_5:
      return "4_income";
    case c.ROUTENAME_STEP_6:
      return "5_expenses";
    case c.ROUTENAME_STEP_7:
      return "6_budget";
    case c.ROUTENAME_STEP_9:
      return "7_insurance";
    case c.ROUTENAME_STEP_10:
      return "8_contact";
    case c.ROUTENAME_STEP_11:
      return "9_personal";
    case c.ROUTENAME_STEP_12:
      return "10_employer";
    case c.ROUTENAME_STEP_13:
      return "11_upload";
    case c.ROUTENAME_STEP_13_2:
      return "12_upload2";
    case c.ROUTENAME_STEP_14:
      return "13_confirmation";
    case c.ROUTENAME_DOC_U_S:
      return "B1_upload";
    case c.ROUTENAME_DOC_U_S_CONFIRMATION:
      return "B2_confirmation";
    case c.ROUTENAME_EMAIL_CONFIRMATION:
      return "C1_confirmation";
    default:
      return undefined;
  }
};

/**
 * @param {string|null} gender
 * @returns {string|undefined} "M", "F" or undefined.
 */
const getGender = (gender) =>
  ["M", "F"].includes((gender || "").toUpperCase())
    ? gender.toUpperCase()
    : undefined;

/**
 * @param {number|null} livingStatus
 * @returns {string|undefined}
 */
const getHousingType = (livingStatus) =>
  accommodation.getKeyByValue(livingStatus) || undefined;

/**
 * @param {string|null} permitId
 * @returns {string|undefined}
 */
const getIdCardType = (permitId) =>
  ["B", "C"].includes((permitId || "").toUpperCase())
    ? permitId.toUpperCase()
    : undefined;

/**
 * @param {number|null} income
 * @returns {string|undefined}
 */
const getIncomeClass = (income) => {
  if (typeof income === "number") {
    if (income < 3000) return "A";
    if (income < 4500) return "B";
    if (income < 5500) return "C";
    return "D";
  }
  return undefined;
};

/**
 * @param {number|null} insuranceTypeId
 * @returns {string|undefined}
 */
const getInsuranceActive = (insuranceTypeId) => {
  if (typeof insuranceTypeId === "number") {
    if (insuranceTypeId === 9) return "n";
    return "y";
  }
  return undefined;
};

/**
 * @param {boolean|null} hasLoanOrLeasing
 * @returns {string|undefined}
 */
const getLoanOrLeasing = (hasLoanOrLeasing) => {
  if (typeof hasLoanOrLeasing === "boolean") {
    return hasLoanOrLeasing ? "y" : "n";
  }
  return undefined;
};

/**
 * @param {string|null} nationality
 * @returns {string|undefined}
 */
const getNationality = (nationality) =>
  typeof nationality === "string" ? nationality.toUpperCase() : undefined;

/**
 * @param {number|null} zip
 * @returns {string|undefined}
 */
const getZipCode = (zip) => (!!zip ? String(zip) : undefined);

/**
 * Pushes a custom page view event to GTM data layer.
 * @param {string} canonicalUrl
 * @param {string} canonicalInvariantUrl
 * @param {string} path Page's path
 * @param {string} title Page's title
 * @param {string|null} lng
 * @param {string|null} stepId
 * @param {number|null} amount
 * @param {number|null} term
 * @param {string|null} gender
 * @param {string|null} nationality
 * @param {string|null} permitId
 * @param {number|null} zip
 * @param {number|null} incomeSourceId
 * @param {number|null} livingStatus
 * @param {number|null} income
 * @param {number|null} insuranceTypeId
 * @returns {void}
 */
export const trackPageView = (
  canonicalUrl,
  canonicalInvariantUrl,
  path,
  title,
  lng,
  stepId,
  amount,
  term,
  gender,
  nationality,
  permitId,
  zip,
  incomeSourceId,
  livingStatus,
  income,
  insuranceTypeId,
  hasLoanOrLeasing
) => {
  const event = {
    event: "virt_path",
    canonical_url: canonicalUrl,
    canonical_invariant_url: canonicalInvariantUrl,
    page_path: path,
    page_title: title,
    language_code: (lng || "").toUpperCase(),
    page_type: "loan_funnel",
    funnel_type: "cembra_loan",
    funnel_version: version,
    funnel_step_nr: getFunnelStepNr(stepId),
    loan_amount: getAmountOrTerm(stepId, amount),
    loan_term: getAmountOrTerm(stepId, term),
    gender: getGender(gender),
    nationality: getNationality(nationality),
    id_card_type: getIdCardType(permitId),
    zip_code: getZipCode(zip),
    employment_status: getEmploymentStatus(incomeSourceId),
    housing_type: getHousingType(livingStatus),
    income_class: getIncomeClass(income),
    existing_loan: getLoanOrLeasing(hasLoanOrLeasing),
    existing_leasing: getLoanOrLeasing(hasLoanOrLeasing),
    insurance_active: getInsuranceActive(insuranceTypeId),
  };
  console.debug(`${LOG_PREFIX} Track page view`, event);
  window.dataLayer.push(event);
};

/**
 * Pushes an amount changed event to GTM data layer. The event
 * creation is debounced by 1s to not flood GTM with events when
 * e.g. slider is used.
 * @param {number} amount
 * @returns {void}
 */
export const trackAmount = (() => {
  const debounce = {
    amount: null,
  };
  return (amount) => {
    if (debounce.amount === null) {
      setTimeout(() => {
        const event = {
          event: CUSTOM_EVENT,
          event_name: "loan_application_step_1_update_amount",
          loan_application_step_1_update_amount: {
            loan_amount: String(debounce.amount),
          },
        };
        console.debug(`${LOG_PREFIX} Track amount`, event);
        window.dataLayer.push(event);
        // reset amount
        debounce.amount = null;
      }, 1000);
    }
    debounce.amount = amount;
  };
})();

/**
 * Pushes an API request error to GTM data layer.
 * @param {string} apiName
 * @param {string} method get, post, etc.
 * @param {number|string=} statusCode
 * @returns {void}
 */
export const trackApiError = (apiName, method, statusCode) => {
  const event = {
    event: CUSTOM_EVENT,
    event_name: "api_error",
    api_error: {
      api_name: apiName,
      method,
      status_code: statusCode ? `${statusCode}` : undefined,
    },
  };
  console.debug(`${LOG_PREFIX} Track api error`, event);
  window.dataLayer.push(event);
};

/**
 * Pushes a confirmation (application submission or document
 * upload done) event to GTM data layer.
 * @param {boolean=} isUpload Whether it's confirmation for
 *    completed document upload.
 * @returns {void}
 */
export const trackConfirmation = (isUpload) => {
  const infix = isUpload ? "_upload" : "";
  const event = {
    event: CUSTOM_EVENT,
    event_name: `loan_application${infix}_confirmation`,
  };
  console.debug(`${LOG_PREFIX} Track confirmation`, event);
  window.dataLayer.push(event);
};

/**
 * Pushes a field error to GTM data layer.
 * @param {string} inputName
 * @param {boolean|string=} errorMsg
 * @returns {void}
 */
export const trackError = (inputName, errorMsg) => {
  const event = {
    event: CUSTOM_EVENT,
    event_name: "error_message",
    error_message: {
      field_name: inputName,
      error_message: typeof errorMsg === "string" ? errorMsg : undefined,
    },
  };
  console.debug(`${LOG_PREFIX} Track error`, event);
  window.dataLayer.push(event);
};

/**
 * Pushes an insurance clicked event to GTM data layer.
 * @param {string} key
 * @returns {void}
 */
export const trackInsurance = (key) => {
  const event = {
    event: CUSTOM_EVENT,
    event_name: "loan_application_step_7_insurance_click",
    loan_application_step_7_insurance_click: {
      item_name: key,
    },
  };
  console.debug(`${LOG_PREFIX} Track insurance`, event);
  window.dataLayer.push(event);
};

/**
 * Pushes a language switched event to GTM data layer.
 * @param {string} lng
 * @returns {void}
 */
export const trackLanguage = (lng) => {
  const event = {
    event: CUSTOM_EVENT,
    event_name: "language_switch",
    language_switch: {
      item_name: (lng || "").toUpperCase(),
    },
  };
  console.debug(`${LOG_PREFIX} Track language`, event);
  window.dataLayer.push(event);
};

/**
 * Pushes a navigation link clicked event to GTM data layer.
 * @param {string} title Links title.
 * @returns {void}
 */
export const trackNavLink = (title) => {
  const event = {
    event: CUSTOM_EVENT,
    event_name: "navigation_link",
    navigation_link: {
      item_name: title,
    },
  };
  console.debug(`${LOG_PREFIX} Track nav link`, event);
  window.dataLayer.push(event);
};

/**
 * Pushes a navigation button clicked event to GTM data layer.
 * @param {string} title Button's title.
 * @returns {void}
 */
export const trackNavButton = (title) => {
  const event = {
    event: CUSTOM_EVENT,
    event_name: "navigation_button",
    navigation_button: {
      item_name: title,
    },
  };
  console.debug(`${LOG_PREFIX} Track nav button`, event);
  window.dataLayer.push(event);
};

/**
 * Pushes a current funnel step event to GTM data layer.
 * @param {number|string} step
 * @returns {void}
 */
export const trackStep = (step) => {
  const event = {
    event: CUSTOM_EVENT,
    event_name: `loan_application_step_${step}`,
  };
  console.debug(`${LOG_PREFIX} Track step`, event);
  window.dataLayer.push(event);
};

/**
 * Pushes an term changed event to GTM data layer. The event
 * creation is debounced by 1s to not flood GTM with events
 * when e.g. slider is used.
 * @param {number} term
 * @returns {void}
 */
export const trackTerm = (() => {
  const debounce = {
    term: null,
  };
  return (term) => {
    if (debounce.term === null) {
      setTimeout(() => {
        const event = {
          event: CUSTOM_EVENT,
          event_name: "loan_application_step_1_update_term",
          loan_application_step_1_update_term: {
            loan_term: String(debounce.term),
          },
        };
        console.debug(`${LOG_PREFIX} Track term`, event);
        window.dataLayer.push(event);
        // reset term
        debounce.term = null;
      }, 1000);
    }
    debounce.term = term;
  };
})();

export const TYPE_ID_CARD = "id_card";
export const TYPE_PAY_SLIP = "pay_slip";

/**
 * @param {string} type TYPE_ID_CARD or TYPE_PAY_SLIP
 * @param {number|string} field
 * @returns {void}
 */
export const trackUpload = (type, field) => {
  const event = {
    event: CUSTOM_EVENT,
    event_name: "loan_application_document_upload",
    loan_application_document_upload: {
      document_type: `${type}_${field}`,
    },
  };
  console.debug(`${LOG_PREFIX} Track upload`, event);
  window.dataLayer.push(event);
};
