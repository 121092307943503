// TODO fix typo in accommodation
const i18nBaseKey = "step-4:components.QuestionAccomodation";

const accommodations = [
  {
    value: 1,
    i18nKey: `${i18nBaseKey}.rent`,
    key: "rent",
    order: 1,
  },
  {
    value: 2,
    i18nKey: `${i18nBaseKey}.employer`,
    key: "employer_flat",
    order: 2,
  },
  {
    value: 3,
    i18nKey: `${i18nBaseKey}.own_apartment`,
    key: "own_flat",
    order: 3,
  },
  {
    value: 4,
    i18nKey: `${i18nBaseKey}.own_house`,
    key: "own_house",
    order: 4,
  },
  {
    value: 8,
    i18nKey: `${i18nBaseKey}.with_parents`,
    key: "parents",
    order: 6,
  },
  {
    value: 9,
    i18nKey: `${i18nBaseKey}.shared_living`,
    key: "shared_rent",
    order: 5,
  },
];

export const getAccommodations = (t) =>
  accommodations
    .map((a) => {
      const copy = Object.assign({}, a);
      if (t) copy.label = t(copy.i18nKey);
      return copy;
    })
    .sort((a, b) => a.order - b.order);

/**
 * @param {number} value
 * @returns {string|null}
 */
export const getKeyByValue = (value) => {
  const a = accommodations.find((a) => a.value === value);
  return a ? a.key : null;
};
