import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import FormControl from "../../../styleguides/Styleguide-FormControl/index";

class QuestionAccountNumber extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputState: "",
      iban: "",
    };
    this.content = "step-12:components.QuestionAccountNumber";
  }

  handleOnChangeIbanNumber = (value) => {
    let state = {
      inputState: "",
    };

    let ctl = document.getElementById("applicant_iban");
    let pos = ctl.selectionStart;
    const { userStore } = this.props;
    const MAX_LENGTH = 21;
    let iban = value.replace(/ /g, "");
    const ibanLength = value.length;
    const storedIbanLength = userStore.applicantIbanNumber
      ? userStore.applicantIbanNumber.length
      : 0;

    if (iban.length > MAX_LENGTH) {
      return;
    } else {
      const ibanIsValid =
        iban.toUpperCase().substring(0, 2) === "CH" ||
        iban.toUpperCase().substring(0, 2) === "LI";

      if (iban.length > 1 && !ibanIsValid) {
        state.inputState = "error";
      } else {
        userStore.setApplicantIbanNumber(iban);

        if (iban.length >= 4 && iban.length % 4 !== 0) {
          iban = iban.replace(/[a-z0-9]{4}/gi, "$& ");
        }
        // setTimeOut to catch event fired before the browser has moved the cursor
        window.setTimeout(function () {
          ctl.focus();

          if (pos < ibanLength && iban.length < storedIbanLength) {
            ctl.setSelectionRange(pos, pos);
          } else if (pos < ibanLength && iban.length >= storedIbanLength) {
            ctl.setSelectionRange(iban.length, iban.length);
          } else if (ibanLength >= iban.length) {
            iban = iban.trim();
            ctl.setSelectionRange(iban.length, iban.length);
          }
        }, 0);

        this.setState({
          iban: iban,
        });
      }
    }
    this.setState(state);
  };

  handleOnBlurIbanNumber = () => {
    const { viewStore, userStore } = this.props;

    const state = {};
    state.inputState = "";

    if (
      userStore.applicantIbanNumber &&
      !viewStore.validate_iban(userStore.applicantIbanNumber)
    ) {
      state.inputState = "error";
    }
    this.setState(state);
  };

  render() {
    const { t, viewStore, userStore } = this.props;
    const { inputState } = this.state;
    let inputHint = null;
    const invalid =
      viewStore.should_step_12_validate &&
      userStore.applicantIbanNumber &&
      !viewStore.validate_iban(userStore.applicantIbanNumber);

    if (inputState === "error" || invalid) {
      inputHint = t(this.content + ".error_invalid");
    }

    const titleClasses = cn({
      "m-info-block__title": true,
      "m-info-block__title-error": inputState === "error" || invalid,
    });

    if (viewStore.should_step_12_validate && inputState !== "error") {
      viewStore.validateProfessionalDataForm();
    }

    return (
      <React.Fragment>
        <h2 className="a-text a-text--subtitle">
          {t(`${this.content}.subtitle`)}
        </h2>
        <div className="l-step-item">
          <div className="m-info-block">
            <div className="m-info-block__head">
              <label className={titleClasses}>
                {t(this.content + ".question")}
              </label>
            </div>
            <label className="m-info-block__lead">
              {t(`${this.content}.description`)}
            </label>
          </div>
          <div className="m-info-block__pattern">
            <FormControl
              type="text"
              id={"applicant_iban"}
              label={t(this.content + ".placeholder")}
              value={this.state.iban}
              onChange={(value) => this.handleOnChangeIbanNumber(value)}
              onBlur={this.handleOnBlurIbanNumber}
              inputState={inputState}
              hint={inputHint}
              isLast={true}
              invalid={invalid}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

QuestionAccountNumber.propTypes = {
  t: PropTypes.func.isRequired,
  userStore: PropTypes.object.isRequired,
  viewStore: PropTypes.object.isRequired,
};

export default withTranslation("step-12")(
  inject(({ stores }) => ({
    userStore: stores.user,
    viewStore: stores.view,
  }))(observer(QuestionAccountNumber))
);
