import cn from "classnames";
import i18next from "i18next";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";

const BODY_OFF_CANVAS_VISIBLE_CLASS = "state-m-off-canvas--visible";

class OffCanvasMenu extends Component {
  componentDidMount() {
    this.updateBodyClasses();
  }

  componentDidUpdate() {
    this.updateBodyClasses();
  }

  updateBodyClasses() {
    // check due to ssr
    if (typeof window !== "undefined") {
      this.props.visible
        ? document.body.classList.add(BODY_OFF_CANVAS_VISIBLE_CLASS)
        : document.body.classList.remove(BODY_OFF_CANVAS_VISIBLE_CLASS);
    }
  }

  render() {
    const { onLangSelect, visible } = this.props;

    const navClasses = cn({
      "m-off-canvas": true,
      "state-m-off-canvas--open": visible,
    });

    const scaffoldClasses = cn({
      "m-off-canvas__scaffold": true,
      "state-m-off-canvas__scaffold--down": visible,
    });

    return (
      <nav className={navClasses} data-t-name="OffCanvas">
        <div className="m-off-canvas__content">
          <div className={scaffoldClasses} />
          <ul className="m-off-canvas__nav-list">
            {this.props.links.map((linkData, i) => {
              return (
                <li
                  key={i}
                  className="m-off-canvas__nav-item m-off-canvas__nav-item--meta"
                >
                  <a
                    className="a-link"
                    href={linkData.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={linkData.onClick}
                  >
                    {linkData.name}
                  </a>
                </li>
              );
            })}
            {this.props.langList.map((lang, i) => {
              const langItemClasses = cn({
                "m-off-canvas__nav-item": true,
                "m-off-canvas__nav-item--language": true,
                "state-m-off-canvas__nav-item--selected":
                  lang.name === i18next.resolvedLanguage,
              });
              return (
                <li key={i} className={langItemClasses}>
                  <a
                    className="a-link"
                    href="/#"
                    onClick={(e) => onLangSelect(e, lang.name)}
                  >
                    {lang.displayname}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    );
  }
}

OffCanvasMenu.propTypes = {
  visible: PropTypes.bool,
  onLangSelect: PropTypes.func,
};

export default observer(OffCanvasMenu);
